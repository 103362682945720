import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getConfigurationsData, isFetchingConfigurations } from '../../../selectors/Configuration';
import { storeConfiguration, fetchConfigurationsData, activateConfiguration } from '../../../actions/Configuration';
import { Configuration } from '../../../types/Configuration';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const ConfigurationDataTable = () => {

  const configurations = useSelector(getConfigurationsData);
  const isLoading = useSelector(isFetchingConfigurations);
  const [toggle, setToggle] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);
  const [configuration, setActiveConfiguration] = useState<Configuration | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.CONFIGURATION_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.CONFIGURATION_UPDATE, row.id));
  };

  const onArchive = (row: Configuration) => {
    setActiveConfiguration(row);
    setToggle(true);
  };

  const onUnArchive = (row: Configuration) => {
    setActiveConfiguration(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (configuration?.id) {
      if (unArchiveToggle){
        await activateConfiguration(configuration?.id);
      } else {
        await storeConfiguration(configuration?.id);
      }
      setRefetch(!refetch);
    }
    setActiveConfiguration(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'defaultRole', label: 'Default Role', mobileFriendly: true },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: 'Archive', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive }],
    hideButton: true,
  };
  return (
      <View title="Configurations" onAddNew={onCreate}>
            <DataTable fetchFunction={fetchConfigurationsData} data={configurations?.data || []}
                       isLoading={isLoading} paging={configurations?.paging} config={config} dependencies={refetch} />
          <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Entry' : 'Archive Entry'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
            {unArchiveToggle ? 'Are you sure you want to unarchive this entry?' : 'Are you sure you want to archive this entry?'}
          </Modal>
        </View>
  );
};

export default ConfigurationDataTable;