import React from 'react';

interface MarkdownProps {
  text: string;
}

// Process inline markdown (bold, italic, inline code)
function renderInline(text:any) {
  // Regex to capture inline code, bold, and italic markdown tokens
  const regex = /(`[^`]+`)|(\*\*[^*]+\*\*)|(\*[^*]+\*)/g;
  let lastIndex = 0;
  const result = [];
  let key = 0;
  let match;

  while ((match = regex.exec(text)) !== null) {
    // Append text before the markdown token as plain text
    if (match.index > lastIndex) {
      result.push(text.substring(lastIndex, match.index));
    }
    // Check which group matched:
    if (match[1]) {
      // Inline code: remove the backticks
      result.push(<code key={key++}>{match[1].slice(1, -1)}</code>);
    } else if (match[2]) {
      // Bold text: remove the ** markers
      result.push(<strong key={key++}>{match[2].slice(2, -2)}</strong>);
    } else if (match[3]) {
      // Italic text: remove the * markers
      result.push(<em key={key++}>{match[3].slice(1, -1)}</em>);
    }
    lastIndex = regex.lastIndex;
  }
  // Append any remaining text after the last token
  if (lastIndex < text.length) {
    result.push(text.substring(lastIndex));
  }

  return result;
}

function renderMarkdown(text: string): React.ReactNode {
  const lines: string[] = text.split('\n');
  // Use a single regex instance to match heading lines
  const headingRegex = /^(#{1,6})\s(.*)$/;

  return lines.map((line, index) => {
    const match = headingRegex.exec(line);
    if (match) {
      const level: number = match[1].length; // Number of '#' determines heading level
      return React.createElement(
        'h' + level,
        { key: index },
        renderInline(match[2]),
      );
    }
    return <p key={index}>{renderInline(line)}</p>;
  });
}



const Markdown:React.FC<MarkdownProps> = ({ text }) => {
  if (!text) {
    return null;
  }
  return <div>{renderMarkdown(text)}</div>;
};

// Parse block-level markdown elements (headings and paragraphs)


export default Markdown;
