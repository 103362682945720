import React, { useState } from 'react';
import c from '../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getDynamicAssets, isFetchingAssets } from '../../selectors/Asset';
import './FilePicker.scss';
import CheckBox from '../CheckBox/CheckBox';
import { ArrowClockwise, Trash } from 'react-bootstrap-icons';
import Modal from '../Modal';
import noImage from '../../assets/images/no-preview.png';
import DataTable from '../DataTable';
import { fetchDynamicAssets, storeAsset, updateAsset } from '../../actions/Asset';
import { Spinner } from 'react-bootstrap';
import Button from '../Button';


type Props = {
  picked?: string;
  dynamicId?: string;
  clientId? : string;
  onPick: (assetId: string) => void;
  onUnPick: (assetId: string) => void;
  type: 'images' | 'files' | 'custom'
  fileTypes?: string[];
};

const FilePicker = (props: Props) => {
  const dynamicAssets = useSelector(getDynamicAssets);
  const isLoading = useSelector(isFetchingAssets);
  const { onPick, onUnPick, picked = '', type = '', fileTypes = [], dynamicId = 'primaryId', clientId } = props;
  const innerPicked = picked ? picked.split(',') : '';
  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState<string>();
  const [deletingItems, setDeletingItems] = useState<{ [key: string]: boolean }>({});
  const [isSaving, setIsSaving] = useState(false);
  const [isRotating, setIsRotating] = useState<{ [key: string]: boolean }>({});
  const [rotation, setRotation] = useState<{ [key: string]: number }>({});
  const dispatch = useDispatch();
  const [refetch, setRefetch] = useState<boolean>(false);
  // const [sortBy, setSortBy] = useState('title');
  // const [sortOrder, setSortOrder] = useState('asc');

  // const toggleSort = () => {
  //   setSortBy(sortBy === 'title' ? 'created' : 'title');
  // };

  // const customSort = (a: any, b: any) => {
  //   const multiplier = sortOrder === 'asc' ? 1 : -1;
  //   if (sortBy === 'title') {
  //     return multiplier * a.title.localeCompare(b.title);
  //   } else if (sortBy === 'created') {
  //     // Assuming that assets have a "created" property representing the date created
  //     return multiplier * (new Date(b.created).getTime() - new Date(a.created).getTime());
  //   }
  //   return 0;
  // };

  const getMimeTypeFromExtension = (ext: string) => {
    const mimeTypes: { [key: string]: string } = {
      png: 'image/png',
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      gif: 'image/gif',
      bmp: 'image/bmp',
      webp: 'image/webp',
    };

    return mimeTypes[ext.toLowerCase()] || 'image/jpeg'; // Default to JPG
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const handleRotateSave = (id: string, extension: string, title: string) => {
    const newRotation = (rotation[id] || 0 ) + 90;
    setRotation((prevRotation) => ({
      ...prevRotation,
      [id]: newRotation % 360, // Rotate only the clicked image
    }));


    if (!id) return;
    setIsSaving(true);
    setIsRotating((prev) => ({ ...prev, [id]: true }));
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = `${c.API_ENDPOINTS.ASSET_FILE}/${id}`;

    img.onload = async () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) return;

      canvas.width = img.height;
      canvas.height = img.width;

      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((newRotation % 360) * (Math.PI / 180));
      ctx.drawImage(img, -img.width / 2, -img.height / 2);

      const mimeType = getMimeTypeFromExtension(extension);

      canvas.toBlob(async (blob) => {
        if (!blob) return;
        const formData = new FormData();
        formData.append('assets', blob, `${title}.${extension}`);
        formData.append('id', id);
        try {
          dispatch(updateAsset(formData, { baseSearch: { clientId: clientId } }));
        } finally {
          setRotation((prevRotation) => ({
            ...prevRotation,
            [id]: 0, // Reset only the saved image rotation
          }));
          // setIsSaving(false);
          setIsRotating((prev) => ({ ...prev, [id]: false }));
          setIsSaving(false);
        }
      }, mimeType);

    };
  };

  const assetsFiltered = (dynamicAssets as any)?.filter((a:any) => {
    return (a.id === dynamicId);
  });
  const assets = assetsFiltered[0];
  // Filter assets based on the condition

  const isImg = (mime: string) =>{
    return !(mime.includes('application/pdf') || mime.includes('text/plain'));
  };
    // const toggleSortOrder = () => {
    //   setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    // };

  const config = {
    columns: [ { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'mime', label: 'Mime', mobileFriendly: true },
      { key: 'created', label: 'Time Created', mobileFriendly: true },
      { key: 'updated', label: 'Last Updated', mobileFriendly: true },
    ],
    actions: [],
    hideButton: true,
    pageSizes : [18, 20, 30, 40],
  };

  const archiveItem =   (assetId: string) => {
    setItem(assetId);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    if (item && item != '') {

      setDeletingItems(prev => ({ ...prev, [item]: true }));
      try {
        await storeAsset(item);
        onUnPick(item);
        setShowModal(false);
        setItem('');
        setRefetch(!refetch);
      } finally {
        setDeletingItems(prev => ({ ...prev, [item]: false })); // Reset loading for the specific item
      }

    }
  };

  const clearSelection = () => {
    setShowModal(false);
    setItem('');
  };

  return (
        <div className="file-picker">
            <div className="row">
                <DataTable paging={assets?.paging || []} data={assets?.data || []} config={config}
                           fetchFunction={fetchDynamicAssets}
                           fetchParams={{ 'id': dynamicId }}
                           isLoading={isLoading}
                           dependencies={refetch}
                           baseSearch={
                               type === 'images'
                                 ? { 'mime': 'image', 'clientId': clientId }
                                 : type === 'files'
                                   ? { 'mime': ['application', 'text'], 'clientId': clientId }
                                   : type === 'custom'
                                     ? fileTypes.reduce((searchObj, fileType) => {
                                       return { ...searchObj, 'mime': fileType, 'clientId': clientId };
                                     }, {})
                                     : {}
                           }                       isTable={false}
                           lineItemTemplate={(row: any) =>
                               <div
                                   key={row.id}
                                   className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-6"
                                   onClick={() => {
                                     if (innerPicked.includes(row.id)) {
                                       onUnPick(row.id);
                                     } else {
                                       onPick(row.id);
                                     }
                                   }}
                               >
                                   <div className="file-picker__img-delete dt-delete">
                                       <CheckBox
                                           type='checkbox'
                                           checked={innerPicked?.includes(row.id)}
                                           disabled={deletingItems[row.id]}
                                       />
                                   </div>
                                   <div className="file-picker__img-archive"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          archiveItem(row.id);
                                        }}
                                        style={{ pointerEvents: deletingItems[row.id] ? 'none' : 'auto' }}>
                                       { deletingItems[row.id] ?
                                           <Spinner animation="border" role="status" size="sm" style={{ marginLeft: '6px' }}>
                                               <span className="visually-hidden">Loading...</span>
                                           </Spinner> : <Trash />
                                       }
                                   </div>

                                   <>
                                       {(type === 'images' || type === 'custom') && (
                                           <>
                                               {isRotating[row.id] ? (<div className="file-picker__img-preview loading_img">Please wait..</div>) : (
                                                   <>
                                                       <img
                                                           src={`${c.API_ENDPOINTS.ASSET_FILE}/${row.id}`}
                                                           alt={row.title}
                                                           className="file-picker__img-preview"
                                                           onError={(event) => {
                                                             event.currentTarget.src = noImage;
                                                           }}
                                                           loading="lazy"
                                                           style={{ backgroundImage: `url(${noImage})`, transform: `rotate(${rotation[row.id] || 0}deg)` }}
                                                           onLoad={(event) => {
                                                             event.currentTarget.style.backgroundImage = 'none';
                                                           }}
                                                       />
                                                       <div className="file-picker__img-title text-start">
                                                           {row.title}.{row.extension}
                                                       </div>
                                                   </>
                                               )}

                                           </>
                                       )}
                                       {type === 'files' &&
                                           isImg(row.mime) && (
                                               <>
                                                   <img
                                                       src={`${c.API_ENDPOINTS.ASSET_FILE}/${row.id}`}
                                                       alt={row.id}
                                                       className="file-picker__img-preview img-thumbnail"
                                                       onError={(event) => {
                                                         event.currentTarget.src = noImage;
                                                       }}
                                                       loading="lazy"
                                                       style={{ backgroundImage: `url(${noImage})` }}
                                                       onLoad={(event) => {
                                                         event.currentTarget.style.backgroundImage = 'none';
                                                       }}
                                                   />
                                                   <div className="file-picker__img-title">
                                                       {row.title}.{row.extension}
                                                   </div>
                                               </>

                                       )}
                                       {type === 'files' &&
                                           !isImg(row.mime) && (
                                               <>
                                                   <div style={{ display: 'inline-block' }}>
                                                       <iframe
                                                           src={`${c.API_ENDPOINTS.ASSET_FILE}/${row.id}`}
                                                           className="file-picker__img-preview img-thumbnail"
                                                           onError={(event) => {
                                                             event.currentTarget.src = noImage;
                                                           }}
                                                           loading="lazy"
                                                           style={{ backgroundImage: `url(${noImage})` }}
                                                           onLoad={(event) => {
                                                             event.currentTarget.style.backgroundImage = 'none';
                                                           }}
                                                       />
                                                   </div>
                                                   <div className="file-picker__img-title">
                                                       {row.title}.{row.extension}
                                                   </div>
                                               </>
                                       )}
                                       {(type === 'images') && (
                                           <div
                                               className="file-picker__img-rotate"
                                               onClick={(event) => {
                                                 event.stopPropagation();
                                                 handleRotateSave(row.id, row.extension, row.title);
                                               }}
                                               style={{ pointerEvents: isSaving ? 'none' : 'auto', opacity: isSaving ? 0.5 : 1 }}
                                           >
                                               {isRotating[row.id] ? (
                                                   <Spinner animation="border" role="status" size="sm" style={{ marginLeft: '4px' }}>
                                                       <span className="visually-hidden">Rotating...</span>
                                                   </Spinner>
                                               ) : (
                                                   <ArrowClockwise />
                                               )}
                                           </div>
                                       )}
                                   </>
                               </div>
                           }
                />
            </div>
            <Modal title={'Are you sure want to delete this item'} onHide={hideModal} show={showModal}>
                <Button title={'Yes'} onClick={confirmDelete}></Button> {' '}
                <Button title={'No'} onClick={clearSelection}></Button>
            </Modal>
        </div>
  );
};

export default FilePicker;
