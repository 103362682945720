import { useState, useEffect } from 'react';
import c from '../helpers/constants';

const useCheckAsset = (asset: string | undefined) => {
  const [isArchived, setIsArchived] = useState(false);

  useEffect(() => {
    if (asset) {
      const assetUrl = `${c.API_ENDPOINTS.ASSET_FILE}/${asset}`;
      const checkAsset = async () => {
        try {
          const response = await fetch(assetUrl, { method: 'GET' });

          if (!response.ok) {
            setIsArchived(true);
          }
        } catch (error) {
          setIsArchived(true);
        }
      };

      checkAsset();
    }
  }, [asset]);

  return isArchived;
};

export default useCheckAsset;
