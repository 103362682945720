import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createPlan, fetchActivePlan, updatePlan } from '../../../actions/Plan';

import {
  getActivePlan,
  isPostingPlan,
  postingPlanError,
  postingPlanFailed,
  postingPlanValidationErrors,
  isFetchingPlans,
} from '../../../selectors/Plan';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import TextArea from '../../../components/TextArea/TextArea';
import { fetchClients } from '../../../actions/Client';
import { getClients } from '../../../selectors/Client';
import DropDown from '../../../components/DropDown/DropDown';


const PlanForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activePlan = useSelector(getActivePlan);
  const clients = useSelector(getClients);
  const isPosting = useSelector(isPostingPlan);
  const isFetching = useSelector(isFetchingPlans);
  const postingFailed = useSelector(postingPlanFailed);
  const postingError = useSelector(postingPlanError);
  const postingValidationErrors = useSelector(postingPlanValidationErrors);


  const [editMode, activeId] = useEditMode(c.APP_ROUTES.PLAN_UPDATE);
  

  const formConfig = [
    { field: 'description', validators: [] },
    { field: 'details', validators: [] },
    { field: 'title', validators: [isRequired] },
    { field: 'clientId', validators: [] },
    { field: 'monthlyFee', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [details, setDetails] = useState('');
  const [monthlyFee, setMonthlyFee] = useState(0);
  const [clientId, setClientId] = useState('');

  useEffect(() => {
    dispatch(fetchClients());
    if (editMode) {
      dispatch(fetchActivePlan(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activePlan && editMode) {
      setTitle(activePlan?.title);
      setDescription(activePlan?.description);
      setDetails(activePlan?.details);
      setClientId(activePlan?.planClientId);
      setMonthlyFee(activePlan?.monthlyFee);
    }
  }, [activePlan]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.PLAN);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createPlan(title, description, details, clientId, monthlyFee));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updatePlan(activeId, title, description, details, clientId, monthlyFee));
    }
  };

  const itemsClients = clients?.data.map(r => ({ label: r.title, value: r.id })) || [];

  const onClientSelect = (obj: any) => {
    setClientId(obj.value);
  };

  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} Plan</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>

                <Input
                    v={v}
                    err={err}
                    name="title"
                    id="title"
                    label="Title"
                    required
                    value={title}
                    onChange={setTitle}
                    disabled={isPosting || isFetching}
                    onBlur={setTitle}
                    placeholder="Insert a title"
                />
                <TextArea
                    v={v}
                    err={err}
                    name="description"
                    id="description"
                    label="Description"
                    required
                    value={description}
                    onChange={setDescription}
                    disabled={isPosting || isFetching}
                    onBlur={setDescription}
                    placeholder="Insert a description"
                />

                <TextArea
                    v={v}
                    err={err}
                    name="details"
                    id="details"
                    label="Details"
                    required
                    value={details}
                    onChange={setDetails}
                    disabled={isPosting || isFetching}
                    onBlur={setDetails}
                    placeholder="Insert details in markdown format"
                />

                <DropDown v={v} err={err}
                          id="clientId"
                          placeholder="Please select a Client"
                          required label="Client"
                          items={itemsClients}
                          value={clientId}
                          disabled={isPosting || isFetching}
                          onSelect={onClientSelect}
                          type="default"
                />
                <Input
                    v={v}
                    err={err}
                    name="monthlyFee"
                    id="monthlyFee"
                    label="Monthly Fee"
                    type="number"
                    value={monthlyFee}
                    onChange={setMonthlyFee}
                    disabled={isPosting || isFetching}
                    onBlur={setMonthlyFee}
                    placeholder="Insert the Monthly Fee"
                />

                <div className="row">
                    <div className="col-md-4">
                        <Button
                            disabled={!isFormValid()}
                            isLoading={isPosting || isFetching}
                            onClick={() => (editMode ? onSave() : onSubmit())}
                            title={editMode ? 'SAVE' : 'CREATE'}
                        />
                    </div>
                </div>
            </form>
        </View>
  );
};

export default PlanForm;
