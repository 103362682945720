import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c  from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getGroups, isFetchingGroups } from '../../../selectors/Group';
import { activateGroup, fetchGroups, storeGroup } from '../../../actions/Group';
import { Group } from '../../../types/Group';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';


const GroupDataTable = () => {

  const groups = useSelector(getGroups);
  const isLoading = useSelector(isFetchingGroups);
  const [toggle, setToggle] = useState(false);
  const [group, setActiveGroup] = useState<Group | null>();
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.GROUP_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.GROUP_UPDATE, row.id));
  };

  const onArchive = (row: Group) => {
    setActiveGroup(row);
    setToggle(true);
  };

  const onUnArchive = (row: Group) => {
    setActiveGroup(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (group?.id) {
      if (unArchiveToggle){
        await activateGroup(group?.id);
      } else {
        await storeGroup(group?.id);
      }
      setRefetch(!refetch);
    }
    setActiveGroup(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'shortcode', label: 'Shortcode', mobileFriendly: true },
      { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: false },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: 'Archive', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive }],
    hideButton: true,
  };


  return  <View title="Groups" onAddNew={onCreate}>
    <DataTable fetchFunction={fetchGroups} data={groups?.data || []} isLoading={isLoading}
               paging={groups?.paging} config={config} dependencies={refetch} />
    <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Entry' : 'Archive Entry'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
      {unArchiveToggle ? 'Are you sure you want to unarchive this entry?' : 'Are you sure you want to archive this entry?'}
    </Modal>
    </View>;
};

export default GroupDataTable;
