import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createTag, fetchActiveTag, fetchTags, updateTag } from '../../../actions/Tag';
import CheckBox from '../../../components/CheckBox';
import {
  getActiveTag,
  isPostingTag,
  postingTagError,
  postingTagFailed,
  postingTagValidationErrors,
  isFetchingTags,
} from '../../../selectors/Tag';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import DropDown from '../../../components/DropDown';


const TagForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeTag = useSelector(getActiveTag);
  const isPosting = useSelector(isPostingTag);
  const isFetching = useSelector(isFetchingTags);
  const postingFailed = useSelector(postingTagFailed);
  const postingError = useSelector(postingTagError);
  const postingValidationErrors = useSelector(postingTagValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.TAG_UPDATE);

  const formConfig = [
    { field: 'value', validators: [isRequired] },
    { field: 'type', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [value, setValue] = useState('');
  const [type, setType] = useState('');
  const [active, setActive] = useState(true);

  useEffect(() => {
    dispatch(fetchTags({ paging: '10000' }));
    if (editMode) {
      dispatch(fetchActiveTag(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeTag && editMode) {
      setValue(activeTag?.value);
      // @ts-ignore
      setActive(activeTag?.active === 1 || activeTag?.active === true);
    }
  }, [activeTag]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.TAG);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);

      dispatch(createTag(value, type, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateTag(activeId, value, type, active));
    }
  };

  const onTypeSelect = (obj: any) => {
    setType(obj.value);
  };

  const itemsTypes = [{ label: 'Usage', value: 'usage' },
    { label: 'Functionality', value: 'functionality' },
    { label: 'Material', value: 'material' },
    { label: 'Component', value: 'component' },
    { label: 'Generic', value: 'generic' }];

  return (
      <View>
        <h4>{editMode ? 'Edit' : 'Create'} Tag</h4>
        {postingError && (
            <Alert className="mt-3" variant="danger">
              {postingError}
            </Alert>
        )}
        <form>
          <Input v={v} err={err}
                 name="value"
                 id="value"
                 label="Value"
                 required
                 value={value}
                 onChange={setValue}
                 disabled={isPosting || isFetching}
                 onBlur={setValue}
                 placeholder="Insert a value"
          />
          <DropDown v={v} err={err}
                    id="type"
                    placeholder="Please select a Type "
                    required
                    label="Type"
                    items={itemsTypes}
                    disabled={isPosting || isFetching}
                    value={type}
                    onSelect={onTypeSelect}
                    type="default"
          />
          <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
          <div className="row">
            <div className="col-md-4">
              <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                      onClick={() => editMode ? onSave() : onSubmit()}
                      title={editMode ? 'SAVE' : 'CREATE'}/>
            </div>
          </div>
        </form>
      </View>
  );

};

export default TagForm;
