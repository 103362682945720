import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import {  useSelector } from 'react-redux';
import { getSubscriptions, isFetchingSubscriptions } from '../../../selectors/Subscription';
import { activateSubscription, fetchSubscriptions, storeSubscription } from '../../../actions/Subscription';
import { Subscription } from '../../../types/Subscription';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';
import { formatUrl } from '../../../helpers/functions';


const SubscriptionDataTable = () => {
  const subscriptions = useSelector(getSubscriptions);
  const isLoading = useSelector(isFetchingSubscriptions);
  const [toggle, setToggle] = useState(false);
  const [subscription, setActiveSubscription] = useState<Subscription | null>(null);
  const [refetch, setRefetch] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.SUBSCRIPTION_ADMIN_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl((c.APP_ROUTES.SUBSCRIPTION_ADMIN_UPDATE), row.id));
  };

  const onArchive = (row: Subscription) => {
    setActiveSubscription(row);
    setToggle(true);
  };

  const onUnArchive = (row: Subscription) => {
    setActiveSubscription(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (subscription?.id) {
      if (unArchiveToggle){
        await activateSubscription(subscription?.id);
      } else {
        await storeSubscription(subscription?.id);
      }
      setRefetch(!refetch);
    }
    setActiveSubscription(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };
  
  const config = {
    columns: [
      { key: 'planTitle', label: 'Plan', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'oppTransactionId', label: 'OPP Transaction ID', mobileFriendly: true },
      { key: 'transactionStatus', label: 'Transaction Status', mobileFriendly: true },
      { key: 'startDate', label: 'Start Date', mobileFriendly: true },
      { key: 'endDate', label: 'End Date', mobileFriendly: true },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive },
    ],
    hideButton: true,
  };

  return (
        <View title="Subscriptions" onAddNew={onCreate}>
            <DataTable
                fetchFunction={fetchSubscriptions}
                data={subscriptions?.data || []}
                isLoading={isLoading}
                paging={subscriptions?.paging}
                config={config}
                dependencies={refetch}
            />
          <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Subscription' : 'Archive Subscription'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
            {unArchiveToggle ? 'Are you sure you want to unarchive this Subscription?' : 'Are you sure you want to archive this Subscription?'}
          </Modal>
        </View>
  );
};

export default SubscriptionDataTable;
