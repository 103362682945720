import React from 'react';
import { fetchAuctions } from '../../../actions/Auction';
import Card from '../../../components/Card/Card';
import c, { CardType } from '../../../helpers/constants';
import { formatUrl } from '../../../helpers/functions';
import DataTable from '../../../components/DataTable';
import { useSelector } from 'react-redux';
import { getAuctions, isFetchingAuctions } from '../../../selectors/Auction';
import { useHistory } from 'react-router-dom';
import { getActiveUser, isFetchingUsers } from '../../../selectors/User';

const MyAuctions = () => {
  const fetchingAuctions = useSelector(isFetchingAuctions);
  const fetchingUsers = useSelector(isFetchingUsers);
  const auctions = useSelector(getAuctions);
  const isLoading = fetchingAuctions || fetchingUsers;
  const history = useHistory();
  const activeUser = useSelector(getActiveUser);
  const onView = (auctionId: string) => {
    history.push(formatUrl(c.APP_ROUTES.VIEW_AUCTION, auctionId));
  };
  const config = {
    columns: [ { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: true },
      { key: 'created', label: 'Time Created', mobileFriendly: true },
      { key: 'updated', label: 'Last Updated', mobileFriendly: true },
    ],
    actions: [],
    hideButton: true,
  };
  return (<>
    {activeUser && <div>
      <DataTable paging={auctions?.paging} data={auctions?.data || []} config={config}
                 isLoading={isLoading}
                 fetchFunction={fetchAuctions} baseSearch={{ 'createdBy': activeUser?.id }}
                 isTable={false}
                 lineItemTemplate={(row: any) =>
                     <div key={row.id} className="col-md-6 col-sm-6 mb-4">
                       <Card
                           cardId={row.index}
                           title={row.title}
                           description={row.description}
                           imageUrl={`${c.API_ENDPOINTS.ASSET_FILE}/${row.assetId}`}
                           cardType={CardType.AUCTION}
                           status={row.status}
                           onClick={() => onView(row.id)}
                       />
                     </div>
                 }
      />
    </div>}
  </>);
};

export default MyAuctions;



