import React from 'react';
import './EditCard.scss';
import noImage from '../../assets/images/no-preview.png';


type Props = {
  children: any,
  imageUrl:string,
  title:string,
};

const EditCard = (props: Props) => {

  return (
        <div className="edit-card">
            <div className="edit-card__img-container">
                <img loading="lazy" src={props.imageUrl} alt="" className='edit-card__img' onError={(event) => event.currentTarget.src = noImage}/>
            </div>
            <div className="edit-card__details">
                <div className="edit-card__title">
                    {props.title}
                </div>
                <div className="edit-card__content">
                    {props.children}
                </div>

            </div>
        </div>

  );
};

export default EditCard;

