import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getOfferProjectManagers, isFetchingOfferProjectManagers } from '../../../selectors/OfferProjectManager';
import { destroyOfferProjectManager, fetchOfferProjectManagers } from '../../../actions/OfferProjectManager';
import { OfferProjectManager } from '../../../types/OfferProjectManager';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';


const OfferProjectManagerDataTable = () => {

  const offerProjectManagers = useSelector(getOfferProjectManagers);
  const isLoading = useSelector(isFetchingOfferProjectManagers);
  const [toggle, setToggle] = useState(false);
  const [offerProjectManager, setActiveOfferProjectManager] = useState<OfferProjectManager | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.OFFER_PROJECT_MANAGER_CREATE);
  };



  const onDelete = (row: OfferProjectManager) => {
    setActiveOfferProjectManager(row);
    setToggle(true);
  };

  const onConfirmDelete = async () => {
    setToggle(false);
    if (offerProjectManager?.id) {
      await destroyOfferProjectManager(offerProjectManager?.id);
      setRefetch(!refetch);
    }
    setActiveOfferProjectManager(null);
  };


  const config = {
    columns: [
      { key: 'offerNumber', label: 'Offer', mobileFriendly: true },
      { key: 'projectManagerEmail', label: 'Project Manager', mobileFriendly: true },
      { key: 'projectManagerType', label: 'Type', mobileFriendly: true },
      { key: 'firstName', label: 'Name', mobileFriendly: true },
      { key: 'lastName', label: 'Surname', mobileFriendly: true },

    ],
    actions: [
      { icon: 'bin', label: '', onClick: onDelete },
    ],
    hideButton: true,
  };

  return (
        <View title="Offer/ProjectManager" onAddNew={onCreate}>
            <DataTable fetchFunction={fetchOfferProjectManagers} paging={offerProjectManagers?.paging} isLoading={isLoading}
                       data={offerProjectManagers?.data || []} config={config} dependencies={refetch}/>
            <Modal show={toggle} title="Delete Offer/ProjectManager" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
                Are you sure you want to delete this Offer/ProjectManager?
            </Modal>

        </View>
  );
};

export default OfferProjectManagerDataTable;