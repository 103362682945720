import React, { useEffect, useState } from 'react';
import View from '../../../components/View/View';
import Card from '../../../components/Card/Card';
import c from '../../../helpers/constants';
import { fetchEntries } from '../../../actions/Entry';
import { useSelector } from 'react-redux';
import { getEntries, isFetchingEntries } from '../../../selectors/Entry';
import { formatUrl, getActiveClient } from '../../../helpers/functions';
import { useHistory } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import Stepper from '../../../components/Stepper';
import {
  isPostingAuctionEntry,
  postingAuctionEntryError,
  postingAuctionEntryFailed,
} from '../../../selectors/AuctionEntry';
import { isFetchingAuctions } from '../../../selectors/Auction';
import Button from '../../../components/Button';
import './AuctionWizard.scss';
import Modal from '../../../components/Modal/Modal';
import DataTable from '../../../components/DataTable';
import { useContextProvider } from '../../../providers/ContextProvider';
import { Entry } from '../../../types/Entry';

const StepTwo = () => {

  const entries = useSelector(getEntries);
  const history = useHistory();
  const isPosting = useSelector(isPostingAuctionEntry);
  const isLoadingAuctions = useSelector(isFetchingAuctions);
  const isLoadingEntries = useSelector(isFetchingEntries);
  const postingFailed = useSelector(postingAuctionEntryFailed);
  const postingError = useSelector(postingAuctionEntryError);
  const isFetching = isLoadingAuctions || isLoadingEntries;
  const clientId = getActiveClient().id;
  const { auctionData, updateAuctionData } = useContextProvider();
  const [showStatusConfirmation, setShowStatusConfirmation] = useState(false);

  const steps = [
    'Provide Auction Details',
    'Select Items For Auction',
    'Overview',
    'Success',
  ];

  const [selectedEntryIds, setSelectedEntryIds] = useState<Entry[]>([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const config = {
    columns: [ { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: true },
      { key: 'viewTitle', label: 'View', mobileFriendly: true },
      { key: 'created', label: 'Time Created', mobileFriendly: true },
      { key: 'updated', label: 'Last Updated', mobileFriendly: true },
    ],
    actions: [],
    hideButton: true,
    pageSizes : [8, 10, 20, 30],
  };

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.AUCTION_CREATE_STEP_THREE);
    }
  }, [hasSubmitted]);

  useEffect(() => {
    if (!auctionData.auctionDetails || Object.keys(auctionData.auctionDetails).length === 0) {
      history.push(c.APP_ROUTES.AUCTION_CREATE_STEP_ONE);
    }
  }, [auctionData.auctionDetails]);

  const onNavigate = (entryId: string) => {
    const url = formatUrl(c.APP_ROUTES.ENTRY_RENDERER, entryId);
    window.open(url, '_blank'); // Opens the URL in a new tab
  };

  const handlePopstate = () => {
    setShowConfirmationModal(true);
  };

  useEffect(() => {
    window.addEventListener('popstate', handlePopstate);
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, '', window.location.href);
    };
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  const handleModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleBrowserBack = async () => {
    history.push(c.APP_ROUTES.AUCTION_CREATE_STEP_ONE);
  };

  const handleCardSelect = (entry: Entry) => {
    const isSelected = selectedEntryIds.some(selectedEntry => selectedEntry.id === entry.id);

    if (isSelected) {
      // Remove the selected entry by filtering it out based on its id
      setSelectedEntryIds(selectedEntryIds.filter(selectedEntry => selectedEntry.id !== entry.id));
    } else {
      // Add the full Entry object to selectedEntryIds
      setSelectedEntryIds([...selectedEntryIds, entry]);
    }
  };

  const handleSelectAll = () => {
    if (entries?.data) {
      setSelectedEntryIds(prevSelectedEntries => {
        // Merge current selected entries with new ones and remove duplicates by `id`
        const allEntries = [...prevSelectedEntries, ...entries.data];
        const uniqueEntries = allEntries.filter(
          (entry, index, self) => self.findIndex(e => e.id === entry.id) === index,
        );
        return uniqueEntries;
      });
    }
  };

  const handleClearAll = () => {
    setSelectedEntryIds([]);
  };

  const proceedWithSubmission = () => {
    setHasSubmitted(true);

    const updatedSelectedItems = selectedEntryIds.map((entry) => ({
      id: entry.id,
      title: entry.title,
      description: entry.description,
      assetId: entry.assetId,
      bidIncrement: auctionData?.auctionDetails.bidIncrement,
      startingBid: auctionData?.auctionDetails.startingBid,
      endDateTime: auctionData?.auctionDetails.endDateTime,
    }));

    updateAuctionData({
      selectedItems: updatedSelectedItems,
    });
  };

  const handleConfirmStatusChange = () => {
    setShowStatusConfirmation(false);
    proceedWithSubmission();
  };

  const onSubmit = () => {
    if (auctionData.auctionDetails.visibility === 'Public') {
      const hasNonPublicItems = selectedEntryIds.some(entry => entry.status !== 'Public');

      if (hasNonPublicItems) {
        setShowStatusConfirmation(true);
        return;
      }
    }

    proceedWithSubmission();
  };


  return (
      <View title='Select Items For Auction'>
            <Modal show={showConfirmationModal}
                   title={'Are you sure you want to leave? All your progress will be lost'}
                   onHide={handleModalClose}>
              <Button title="Yes, I am sure" onClick={handleBrowserBack}/>
              {' '}
              <Button title="No"  onClick={handleModalClose}/>
            </Modal>
        <Modal show={showStatusConfirmation}
               title={'Some selected items are not public'}
               onHide={() => setShowStatusConfirmation(false)}>
          <p>Some items you selected are not public. They will be switched to public if added to a public auction.</p>
          <div className="row">
            <div className="col-md-12 d-flex justify-content-between">
              <Button title="Cancel" onClick={() => setShowStatusConfirmation(false)} />
              <Button title="Proceed" onClick={handleConfirmStatusChange} />
            </div>
          </div>
        </Modal>
        <div className="container-fluid">
          {postingError && (
              <Alert className="mt-3" variant="danger">
                {postingError}
              </Alert>
          )}
          <div className="row justify-content-center mb-4">
            <div className="col-8">
              <Stepper steps={steps} activeStep={1} hideMobileLabels={true}/>
            </div>
          </div>

          <div className="mx-4">
            <div className='row'>
              <div className="col-md-12 auction-wizard__box--grey">
                <div className='row'>
                  <div className='row my-4 justify-content-end'>
                    <div className="col-10  align-items-center justify-content-end gap-3 d-flex flex-row">
                      <Button  disabled={isPosting || isFetching}
                               onClick={() =>  handleSelectAll()}
                               title={ 'Add this page'}/>
                      <Button  disabled={isPosting || isFetching}
                               onClick={() =>  handleClearAll()}
                               title={ 'Clear all'}/>
                    </div>
                  </div>
                  <div className='row'>
                    <DataTable
                        paging={entries?.paging}
                        isLoading={isFetching}
                        data={entries?.data}
                        baseSearch={{ 'clientId': clientId }}
                        config={config}
                        fetchFunction={fetchEntries}
                        isTable={false}
                        lineItemTemplate={(row: any) => (
                            <div className={'col-lg-3 col-md-6 col-sm-12 mb-4'}>

                              <Card
                                  itemId={row.id}
                                  cardId={row.index}
                                  title={row.title}
                                  status={row.status}
                                  description={row.description}
                                  imageUrl={`${c.API_ENDPOINTS.ASSET_FILE}/${row.assetId}`}
                                  onClick={() => onNavigate(row.id)}
                                  selectable={true}
                                  onSelect={() => handleCardSelect(row)}
                                  isSelected={selectedEntryIds.some(selectedEntry => selectedEntry.id === row.id)}
                              />
                            </div>

                        )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-auto">
              <Button disabled={selectedEntryIds.length < 1} isLoading={isPosting || isFetching}
                      onClick={() =>  onSubmit()}
                      title={ 'Next'}/>
            </div>
          </div>
        </div>
      </View>
  );
};

export default StepTwo;

