import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  getActiveMarketplace, isFetchingMarketplaces } from '../../selectors/Marketplace';
import View from '../../components/View';
import '../../components/View/View.scss';
import { belongsToMyClient, formatUrl, getToken, getUserToken, latLngCalculation } from '../../helpers/functions';
import { getEntries, isFetchingEntries } from '../../selectors/Entry';
import { getAllEntryFields } from '../../selectors/EntryField';
import c, { EntryStatus, SearchType, shareableStatuses } from '../../helpers/constants';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { fetchEntriesByMarketplace } from '../../actions/Entry';
import { fetchAllEntryFields } from '../../actions/EntryField';
import { getLoggedInRole, getLoggedInUser } from '../../selectors/Auth';
import { fetchActiveMarketplace, fetchMarketplacesPlusPublic } from '../../actions/Marketplace';
import MaterialMap from '../../components/MaterialMap';
import Card from '../../components/Card/Card';
import DataTable from '../../components/DataTable';
import ButtonToggler from '../../components/ButtonToggler';
import assetGallery from '../../components/AssetGallery';
import useShortcodes from '../../hooks/ShortcodeHook';
import { Entry } from '../../types/Entry';
import { Alert } from 'react-bootstrap';

function Marketplace() {
  const {
    isUsingShortcodes,
    clientShortcode,
    groupShortcode,
  } = useShortcodes(c.APP_ROUTES.VIEW_MARKETPLACE_SHORTCODE, c.APP_ROUTES.VIEW_MARKETPLACE);
  const dispatch = useDispatch();
  const isLoading = useSelector(isFetchingMarketplaces);
  const isLoadingDatatable = useSelector(isFetchingEntries);
  const entries = useSelector(getEntries);
  const entryFields = useSelector(getAllEntryFields);
  const [gridView, setGridView] = useState(true);
  const history = useHistory();
  const userRole = useSelector(getLoggedInRole);
  const activeMarketplace = useSelector(getActiveMarketplace);
  const user = useSelector(getLoggedInUser);
  const [privateZoom, setPrivateZoom] = useState(5);
  const [publicZoom, setPublicZoom] = useState(5);
  const [privateLat, setPrivateLat] = useState(52.1312863);
  const [publicLat, setPublicLat] = useState(52.1312863);
  const [privateLng, setPrivateLng] = useState(-0.0544277);
  const [publicLng, setPublicLng] = useState(-0.0544277);
  const userId = useSelector(getLoggedInUser);
  const [showCopied, setShowCopied] = useState(false);
  let matchUpdate = isUsingShortcodes ? useRouteMatch(c.APP_ROUTES.VIEW_MARKETPLACE_SHORTCODE) : useRouteMatch(c.APP_ROUTES.VIEW_MARKETPLACE);
  // @ts-ignore
  const [marketplaceId, setMarketplaceId] = useState(matchUpdate?.params?.id);
  //Map related functionalities
  const publicStatuses = [
    EntryStatus.PUBLIC,
    EntryStatus.REUSED,
    EntryStatus.EXPIRED,
    EntryStatus.SOLD,
  ];

  const mapPublicItems = entryFields?.data
    .filter(x => (
      x.fieldType === 'map' &&
          (publicStatuses.includes(x.entryStatus as EntryStatus) || x.createdBy === userId?.email) &&
          x.archived === null &&
          entries?.data.some(entry => entry.id === x.entryId)
    ))
    .map(e => ({
      'geolocation': e.value,
      'id': e.entryId,
      'title': e.entryTitle,
      'description': e.entryDescription,
      'assetId': e.entryAssetId,
    }));


  const mapPrivateItems = entryFields?.data
    .filter(x => (
      x.fieldType === 'map' &&
          x.archived === null &&
          entries?.data.some(entry => entry.id === x.entryId)
    ))
    .map(e => ({
      'geolocation': e.value,
      'id': e.entryId,
      'title': e.entryTitle,
      'description': e.entryDescription,
      'assetId': e.entryAssetId,
    }));

  useEffect(() => {
    // @ts-ignore
    if (matchUpdate?.params?.id !== marketplaceId) {
      // @ts-ignore
      setMarketplaceId(matchUpdate?.params?.id);
    }
    // @ts-ignore
  }, [matchUpdate?.params?.id]);


  useEffect(() => {
    if (getUserToken() && !isUsingShortcodes){
      // @ts-ignore
      setMarketplaceId(matchUpdate?.params?.id);
      dispatch(fetchActiveMarketplace(marketplaceId));
      dispatch(fetchMarketplacesPlusPublic());
    }
    if (groupShortcode && clientShortcode && isUsingShortcodes){
      // @ts-ignore
      setMarketplaceId(matchUpdate?.params?.id);
      dispatch(fetchActiveMarketplace(marketplaceId, groupShortcode, clientShortcode));
      dispatch(fetchMarketplacesPlusPublic({ fetchParams:
            { 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode } }));
      dispatch(fetchEntriesByMarketplace({ fetchParams:{
        'id': marketplaceId,
        'groupShortcode': groupShortcode,
        'clientShortcode': clientShortcode,
      } }));
    }
  }, [dispatch, marketplaceId]);

  useEffect(() => {
    if (mapPublicItems && mapPrivateItems){

      const mapCalc = latLngCalculation(mapPrivateItems, mapPublicItems);
      setPublicLat(mapCalc.publicLat);
      setPrivateLat(mapCalc.privateLat);
      setPublicLng(mapCalc.publicLng);
      setPrivateLng(mapCalc.privateLng);
    }
    setPublicZoom(5);
    setPrivateZoom(5);
  }, [gridView, activeMarketplace]);

  useEffect(() => {

    if (getUserToken() && !isUsingShortcodes) {
      dispatch(fetchAllEntryFields());
    }
    if (!getUserToken() && groupShortcode && clientShortcode && isUsingShortcodes) {
      dispatch(fetchAllEntryFields({ fetchParams: { 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode } }));
    }
  }, []);


  const onNavigate = (entry: Entry) => {
    if ((groupShortcode && clientShortcode && isUsingShortcodes)) {
      history.push(formatUrl(c.APP_ROUTES.ENTRY_RENDERER_SHORTCODE, groupShortcode, clientShortcode, entry.id));
    } else if (user && !belongsToMyClient(user, entry.clientId)) {
      history.push(formatUrl(c.APP_ROUTES.ENTRY_RENDERER_SHORTCODE, entry.groupShortcode, entry.clientShortcode, entry.id));
    } else {
      history.push(formatUrl(c.APP_ROUTES.ENTRY_RENDERER, entry.id));
    }
  };

  const onEditCard = (entryId: string) => {
    history.push(formatUrl(c.APP_ROUTES.FORM_RENDERER_EDIT, entryId));
  };

  const handleEditMarketplace = () => {
    if (activeMarketplace && user && belongsToMyClient(user, activeMarketplace.clientId) && (getToken() && userRole && userRole.accessLevel < 2000)){
      return history.push(formatUrl(c.APP_ROUTES.MARKETPLACE_EDIT_STEP_TWO, marketplaceId));
    } else {
      return null;
    }
  };

  const config = {
    columns: [ { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: true },
      { key: 'created', label: 'Time Created', mobileFriendly: true },
      { key: 'updated', label: 'Last Updated', mobileFriendly: true },
    ],
    actions: [],
    hideButton: true,
    pageSizes : [8, 10, 20, 50, 100],
  };

  const onShareMarketplace = (row: any) => {
    const rootUrl = window.location.origin;
    const relativeUrl = formatUrl(c.APP_ROUTES.VIEW_MARKETPLACE_SHORTCODE, row.groupShortcode, row.clientShortcode, row.id);
    const fullUrl = `${rootUrl}${relativeUrl}`;

    navigator.clipboard.writeText(fullUrl);
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 4000);
  };

  const onShareEntry = (row: Entry) => {
    const rootUrl = window.location.origin;
    const relativeUrl = formatUrl(c.APP_ROUTES.ENTRY_RENDERER_SHORTCODE, row.groupShortcode, row.clientShortcode, row.id);
    const fullUrl = `${rootUrl}${relativeUrl}`;

    navigator.clipboard.writeText(fullUrl);
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 4000);
  };



  const isShareable = (status: string) =>{
    return status ? shareableStatuses.includes(status) : false;
  };


  return (
        <View imgBanner={activeMarketplace?.asset} isLoading={isLoading}
              bannerType={SearchType.MARKETPLACE} bannerTitle={activeMarketplace?.title}
              onEdit={user && activeMarketplace && belongsToMyClient(user, activeMarketplace?.clientId)
              && userRole && userRole?.accessLevel <= 1000 ? handleEditMarketplace : undefined}
              onShare={(activeMarketplace && isShareable(activeMarketplace?.visibility)) ? () => onShareMarketplace(activeMarketplace) : undefined}>
          <div className="row my-3">
            {showCopied && <Alert className="mt-3" variant="success">
              Guest link copied to clipboard successfully!
            </Alert>}
            {gridView &&
            <div className='col-12'>
              <DataTable
                  paging={entries?.paging} data={entries?.data || []} config={config}
                  fetchFunction={fetchEntriesByMarketplace}
                  fetchParams={{ 'id': marketplaceId, 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode, 'deepSearch': true }}
                  isTable={false}
                  exportButton={false}
                  isLoading={isLoadingDatatable}
                  gridView={gridView}
                  togglerFunc={() => setGridView((prev) => !prev)} lineItemTemplate={(row: any) =>
                  <div className={'col-lg-3 col-md-6 col-sm-12 mb-4'}>
                        <Card
                            onClick={() => onNavigate(row)}
                            cardId={row.index}
                            title={row.title}
                            status={row.status}
                            description={row.description}
                            onEdit={user && userRole && userRole?.accessLevel < 3000 && belongsToMyClient(user, row.clientId) ? () => onEditCard(row.id) : undefined}
                            onShare={() => onShareEntry(row)}
                            imageUrl={`${c.API_ENDPOINTS.ASSET_FILE}/${row.assetId}`}
                        />
                  </div>
              }
              />
            </div>}
            {!gridView &&
                      <div className="col-12">
                        <div className="view_map">
                          <div className="d-flex flex-column align-items-end mb-3">
                            <div className='view__map-grid--size'>
                              <ButtonToggler grid={gridView} title1={'Grid View'} title2={'Map View'}
                                             img1={gridView ? assetGallery.gridViewActive : assetGallery.gridView}
                                             img2={gridView ? assetGallery.mapView : assetGallery.mapViewActive} onClick={()=>setGridView(!gridView)}/>
                            </div>
                          </div>
                          <MaterialMap
                              lat={(userRole && userRole?.accessLevel >= 2000 || (groupShortcode && clientShortcode)) ? publicLat : privateLat}
                              lng={(userRole && userRole?.accessLevel >= 2000 || (groupShortcode && clientShortcode)) ? publicLng : privateLng}
                              view={'viewRenderer'}
                              enableInfoWindow={true}
                              zoom={(userRole && userRole?.accessLevel >= 2000 || (groupShortcode && clientShortcode)) ? publicZoom : privateZoom}
                              mapList={(userRole && userRole?.accessLevel >= 2000 || (groupShortcode && clientShortcode)) ? mapPublicItems : mapPrivateItems}
                          />
                        </div>
                      </div>
                  }
            </div>
        </View>
  );


}

export default Marketplace;
