import { ApiDataModel, FormError } from './Common';

export const FETCH_AUCTION_BIDS_REQUEST = 'FETCH_AUCTION_BIDS_REQUEST';
export const FETCH_AUCTION_BIDS_RESPONSE = 'FETCH_AUCTION_BIDS_RESPONSE';
export const FETCH_AUCTION_BIDS_FAILURE = 'FETCH_AUCTION_BIDS_FAILURE';

export const FETCH_AUCTION_BID_REQUEST = 'FETCH_AUCTION_BID_REQUEST';
export const FETCH_AUCTION_BID_RESPONSE = 'FETCH_AUCTION_BID_RESPONSE';
export const FETCH_AUCTION_BID_FAILURE = 'FETCH_AUCTION_BID_FAILURE';

export const POST_AUCTION_BID_REQUEST = 'POST_AUCTION_BID_REQUEST';
export const POST_AUCTION_BID_RESPONSE = 'POST_AUCTION_BID_RESPONSE';
export const POST_AUCTION_BID_FAILURE = 'POST_AUCTION_BID_FAILURE';

export interface AuctionBid {
  id:string,
  auctionId: string,
  entryId: string,
  userId: string,
  userEmail: string,
  userUsername: string,
  amount:string,
  auctionTitle: string,
  entryTitle: string,
  auctionEntryId: string,
  auctionEntryStatus: string,
  assetId: string
  created: Date;
  createdAt: Date;
  createdBy: Date;
  updatedAt: Date;
  updatedBy: Date;
}

export interface AuctionBidDataModel extends Omit<ApiDataModel, 'data'> {
  data: AuctionBid[];
}

export interface AuctionBidState {
  auctionBids: AuctionBidDataModel | null,
  activeAuctionBid: AuctionBid | null,
  isFetchingAuctionBids: boolean;
  fetchingAuctionBidsFailed: boolean;
  postingAuctionBidFailed?: boolean;
  isPostingAuctionBid: boolean;
  postingAuctionBidValidationErrors: FormError[] | null;
  postingAuctionBidError: string | null;
}

interface FetchAuctionBidsRequestAction {
  type: typeof FETCH_AUCTION_BIDS_REQUEST;
  payload: null;
}

interface FetchAuctionBidsResponseAction {
  type: typeof FETCH_AUCTION_BIDS_RESPONSE;
  payload: AuctionBidDataModel;
}

interface FetchAuctionBidsFailureAction {
  type: typeof FETCH_AUCTION_BIDS_FAILURE;
  payload: null;
}

interface FetchAuctionBidRequestAction {
  type: typeof FETCH_AUCTION_BID_REQUEST;
  payload: null;
}

interface FetchAuctionBidResponseAction {
  type: typeof FETCH_AUCTION_BID_RESPONSE;
  payload: AuctionBid;
}

interface FetchAuctionBidFailureAction {
  type: typeof FETCH_AUCTION_BID_FAILURE;
  payload: null;
}

interface PostAuctionBidRequestAction {
  type: typeof POST_AUCTION_BID_REQUEST;
  payload: null;
}

interface PostAuctionBidResponseAction {
  type: typeof POST_AUCTION_BID_RESPONSE;
  payload: null;
}

interface PostAuctionBidFailureAction {
  type: typeof POST_AUCTION_BID_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type AuctionBidActionTypes =
    | FetchAuctionBidsRequestAction
    | FetchAuctionBidsResponseAction
    | FetchAuctionBidsFailureAction
    | PostAuctionBidRequestAction
    | PostAuctionBidResponseAction
    | PostAuctionBidFailureAction
    | FetchAuctionBidRequestAction
    | FetchAuctionBidResponseAction
    | FetchAuctionBidFailureAction;
