import axios from 'axios';

import c from '../helpers/constants';
import { objectToQueryString } from '../helpers/functions';
import { DataTableParamsModel } from '../types/Common';
import { PublicTypes } from '../types/PublicTypes';

export function getEntryField(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_FIELD}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getEntryFields(
  dataTableParams?: DataTableParamsModel,
  visibilityPublic?: boolean,
) {
  const params: any = {};
  let url = '';
  if (visibilityPublic) {
    url = `${c.API_ENDPOINTS.ENTRY_FIELD_PUBLIC}`;
  } else {
    const baseUrl = `${c.API_ENDPOINTS.ENTRY_FIELD}`;
    const fetchParams = dataTableParams?.fetchParams;

    url = fetchParams?.groupShortcode && fetchParams?.clientShortcode
      ? `${baseUrl}-shortcode`
      : baseUrl;
  }


  if (dataTableParams?.searchField === 'entryId' && dataTableParams?.searchWord) {
    params.providedUuid = dataTableParams.searchWord;
    params.publicType = PublicTypes.ENTRY_FIELD;
  }

  // Add other dataTableParams to the params object
  if (dataTableParams) {
    params.dataTableParams = dataTableParams;
  }


  return (
    axios({
      url,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params,
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getEntryFieldsByShortcodes(groupShortcode:string, clientShortcode: string, entryId: string | null = null) {
  let payload = '';
  if (entryId) {
    payload = objectToQueryString({ searching: { key: 'entryId', value: entryId } });
  }
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_FIELD}/${groupShortcode}/${clientShortcode}?${payload}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postEntryField(groupId: string, clientId: string, entryId: string, fieldId: string, order:number, value: string, active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.ENTRY_FIELD,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        entryId,
        fieldId,
        order,
        value,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putEntryField(
  id: string,
  groupId: string,
  clientId: string,
  entryId: string,
  fieldId: string,
  order: number,
  value: string,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_FIELD}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        entryId,
        fieldId,
        order,
        value,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveEntryField(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_FIELD}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function unarchiveEntryField(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_FIELD}/${id}/unarchive`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}
