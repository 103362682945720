import React, { useState, useEffect } from 'react';
import FileUpload from '../FileUpload/FileUpload';
import Tabs from '../Tabs';
import FilePicker from '../FilePicker/FilePicker';
import { Tab } from '../Tabs/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAssets,
  getUploadedAssets,
  getUploadedAssetsIdentifier,
  isPostingAsset,
} from '../../selectors/Asset';
import assetGallery from '../AssetGallery';
import c from '../../helpers/constants';
import noImage from '../../assets/images/no-preview.png';
import Button from '../Button';
import { Asset } from '../../types/Asset';
import { useMobile } from '../../helpers/functions';
import { clearUpload, updateAsset } from '../../actions/Asset';
import { Spinner } from 'react-bootstrap';
import { ArrowClockwise } from 'react-bootstrap-icons';

type FileSelectorProps = {
  type: 'images' | 'files' | 'custom';
  groupId?: string;
  clientId?: string;
  onPick: (id: string) => void;
  onUnPick: (id: string) => void;
  picked?: string;
  fileTypes?: string[];
  dynamicId?: string;
};

const FileSelector: React.FC<FileSelectorProps> = ({
  type,
  groupId,
  clientId,
  onPick,
  onUnPick,
  picked,
  fileTypes,
  dynamicId,
}) => {
  const dispatch = useDispatch();
  const [selectedAssets, setSelectedAssets] = useState<Asset[]>([]);
  const [assets, setAssets] = useState<string[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedImages, setSelectedImages] = useState<{ id: string; url: string }[]>([]);
  const [rotation, setRotation] = useState<{ [key: string]: number }>({});
  const allAssets = useSelector(getAssets);
  const uploadedAssets = useSelector(getUploadedAssets);
  const identifier = useSelector(getUploadedAssetsIdentifier);
  const isPosting = useSelector(isPostingAsset);
  const isMobile = useMobile();
  const [isSaving, setIsSaving] = useState(false);
  const [isRotating, setIsRotating] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (picked) {
      const pickedArray = picked.split(',').filter(Boolean);
      setAssets(pickedArray);
    } else {
      setAssets([]);
    }
  }, [picked]);

  useEffect(() => {
    if (uploadedAssets && dynamicId) {
      const ids = uploadedAssets.data.map(obj => obj.id);
      if (dynamicId === identifier){
        onPick(ids[0]);
        const pickedId = ids[0];
        const imageUrl = `${c.API_ENDPOINTS.ASSET_FILE}/${pickedId}`;
        const newImage = { id: pickedId, url: imageUrl };
        setSelectedImages((prevImages) => [...prevImages, newImage]);
        //setSelectedImage({ id: pickedId.toString(), url: `${c.API_ENDPOINTS.ASSET_FILE}/${pickedId}` });
        dispatch(clearUpload());
        if (isMobile) {
          setIsExpanded(!isExpanded);
        }
      }
    }
  }, [uploadedAssets, dynamicId]);

  useEffect(() => {
    if (assets && allAssets) {
      setSelectedAssets(allAssets?.data.filter((asset: any) => assets.includes(asset.id)));
    }
  }, [assets, allAssets]);

  const handleFilePick = (id: string) => {
    if (!assets.includes(id)) {
      setRotation((prevRotation) => ({
        ...prevRotation,
        [id]: 0, // Reset only the saved image rotation
      }));
      onPick(id);

      if (!selectedImages.some(img => img.id === id)) {
        const newImage = { id, url: `${c.API_ENDPOINTS.ASSET_FILE}/${id}` };
        setSelectedImages((prevImages) => [...prevImages, newImage]);
      }
    }
  };

  const handleFileUnPick = (id: string) => {
    onUnPick(id);
    setSelectedImages((prevImages) => prevImages.filter(img => img.id !== id));
  };

  const getMimeTypeFromExtension = (ext: string) => {
    const mimeTypes: { [key: string]: string } = {
      png: 'image/png',
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      gif: 'image/gif',
      bmp: 'image/bmp',
      webp: 'image/webp',
    };

    return mimeTypes[ext.toLowerCase()] || 'image/jpeg'; // Default to JPG
  };

  const handleRotateSave = (id: string, extension: string, title: string) => {
    const newRotation = (rotation[id] || 0 ) + 90;
    setRotation((prevRotation) => ({
      ...prevRotation,
      [id]: newRotation % 360, // Rotate only the clicked image
    }));


    if (!id) return;
    setIsSaving(true);
    setIsRotating((prev) => ({ ...prev, [id]: true }));
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = `${c.API_ENDPOINTS.ASSET_FILE}/${id}`;

    img.onload = async () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) return;

      canvas.width = img.height;
      canvas.height = img.width;

      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((newRotation % 360) * (Math.PI / 180));
      ctx.drawImage(img, -img.width / 2, -img.height / 2);

      const mimeType = getMimeTypeFromExtension(extension);

      canvas.toBlob(async (blob) => {
        if (!blob) return;
        const formData = new FormData();
        formData.append('assets', blob, `${title}.${extension}`);
        formData.append('id', id);

        dispatch(updateAsset(formData, { baseSearch: { clientId: clientId } }));
        setIsRotating((prev) => ({ ...prev, [id]: false }));
        setIsSaving(false);
        setRotation((prevRotation) => ({
          ...prevRotation,
          [id]: 0, // Reset only the saved image rotation
        }));
      }, mimeType);
    };
  };





  const tabs: Tab[] = [
    {
      label: type === 'files' ? 'Upload a new File' : 'Upload a new Image',
      content: (
          <FileUpload
              type={type}
              groupId={groupId || ''}
              clientId={clientId || ''}
              fileTypes={fileTypes}
              dynamicId={dynamicId ?? ''}
          />
      ),
    },
    {
      label: 'Select from Gallery',
      content: (
          <FilePicker
              type={type}
              picked={picked}
              clientId={clientId}
              dynamicId={dynamicId ?? ''}
              onPick={handleFilePick}
              onUnPick={handleFileUnPick}
              fileTypes={fileTypes}
          />
      ),
    },
  ];

  const isImg = (mime: string) => !(mime.includes('application/pdf') || mime.includes('text/plain'));

  return (
      <div>
        {selectedAssets && selectedAssets.length > 0 && (
            <div className="file-picker__img-selected">
              <div className="row mx-2 mt-4 file-picker__img-selected">
                <>
                  {selectedAssets?.map((p: any, i: number) => (
                      <>
                        {!(isMobile && p.title === 'placeholder') && (
                            <div key={i} className="col-xl-2 col-lg-3 col-6 mb-4"
                                 style={{
                                   pointerEvents: isPosting ? 'none' : 'auto', // Disable interactions when isPosting
                                   opacity: isPosting ? 0.7 : 1,
                                 }}>
                              <div className="file-picker__img-delete" onClick={() => onUnPick(p.id)}
                                   style={{ pointerEvents: isRotating[p.id] ? 'none' : 'auto', opacity: isRotating[p.id] ? 0.5 : 1 }}
                              >
                                <img
                                    alt="deselect"
                                    src={assetGallery.xImgDark}
                                    style={!isImg(p.mime) ? { transform: 'translateY(15px)' } : {}}
                                />
                              </div>
                              {type === 'images' && (
                                (!isPosting ?
                                      <img
                                          src={`${c.API_ENDPOINTS.ASSET_FILE}/${p.id}` }
                                          className="file-picker__img-preview"
                                          style={{ transform: `rotate(${rotation[p.id] || 0}deg)` }} // Apply the rotation
                                          onError={(event) => {
                                            event.currentTarget.src = noImage;
                                          }}
                                          alt="selected"
                                      /> : <div className="loading_img">Please wait..</div>)

                              )}
                              {!isImg(p.mime) && (
                                  <div style={{ display: 'inline-block' }}>
                                    <iframe
                                        src={`${c.API_ENDPOINTS.ASSET_FILE}/${p.id}`}
                                        className="img-preview img-thumbnail"
                                        onError={(event) => {
                                          event.currentTarget.src = noImage;
                                        }}
                                    />
                                  </div>
                              )}
                              <div className="file-picker__img-title">
                                {p.title}.{p.extension}
                              </div>

                              {/* Rotate Button */}
                              {(type === 'images') && (
                                  <>
                                    <div
                                        className="file-picker__img-rotate"
                                        onClick={() =>
                                          handleRotateSave(p.id, p.extension, p.title)
                                        }
                                        style={{ pointerEvents: isSaving || isPosting ? 'none' : 'auto', opacity: isSaving || isPosting ? 0.5 : 1 }}
                                    >
                                      {isRotating[p.id] ? (
                                          <Spinner animation="border" role="status" size="sm" style={{ marginLeft: '4px' }}>
                                            <span className="visually-hidden">Rotating...</span>
                                          </Spinner>
                                      ) : (
                                          <ArrowClockwise />
                                      )}
                                    </div>
                                  </>
                              )}
                            </div>
                        )}
                      </>
                  ))}
                </>
              </div>
            </div>
        )}

        <Button
            disabled={isPosting || isSaving}
            title={
              picked && !isExpanded
                ? 'Change Selected Files'
                : !picked && !isExpanded
                  ? 'Select Files'
                  : 'Close Gallery'
            }
            onClick={() => setIsExpanded(!isExpanded)}
        />
        {isExpanded && <Tabs tabs={tabs} activeTabIndex={0} />}
      </div>
  );
};

export default FileSelector;
