import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { formatISO } from 'date-fns';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c, { auctionStatuses, currencyItems } from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createAuction, fetchActiveAuction, fetchAuctions, updateAuction } from '../../../actions/Auction';

import {
  getActiveAuction,
  isPostingAuction,
  postingAuctionError,
  postingAuctionFailed,
  postingAuctionValidationErrors,
  isFetchingAuctions,
} from '../../../selectors/Auction';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { fetchGroups } from '../../../actions/Group';
import { fetchClients } from '../../../actions/Client';
import { getGroups } from '../../../selectors/Group';
import { getClients } from '../../../selectors/Client';
import DropDown from '../../../components/DropDown';
import DateSelect from '../../../components/DateSelect';
import { convertToLocal } from '../../../helpers/functions';
import FileSelector from '../../../components/FileSelector';

const AuctionForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeAuction = useSelector(getActiveAuction);
  const groups = useSelector(getGroups);
  const clients = useSelector(getClients);
  const isPosting = useSelector(isPostingAuction);
  const isFetching = useSelector(isFetchingAuctions);
  const postingFailed = useSelector(postingAuctionFailed);
  const postingError = useSelector(postingAuctionError);
  const postingValidationErrors = useSelector(postingAuctionValidationErrors);
  const [editMode, activeId] = useEditMode(c.APP_ROUTES.AUCTION_UPDATE);
  const formConfig = [
    { field: 'groupId', validators: [isRequired] },
    { field: 'clientId', validators: [isRequired] },
    { field: 'title', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [groupId, setGroupId] = useState('');
  const [clientId, setClientId] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [assetId, setAsset] = useState('');
  const [startingBid, setStartingBid] = useState('');
  const [startDateTime, setStartDateTime] = useState(new Date());
  const [endDateTime, setEndDateTime] = useState(new Date());
  const [bidIncrement, setBidIncrement] = useState('');
  const [visibility, setVisibility] = useState('');
  const [currency, setCurrency] = useState('');
  const [itemsClients, setItemsClients] = useState(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [disabledClient, setDisabledClients] = useState(true);

  useEffect(() => {
    dispatch(fetchAuctions());
    dispatch(fetchGroups());
    dispatch(fetchClients());
    if (editMode) {
      dispatch(fetchActiveAuction(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeAuction && editMode) {
      setGroupId(activeAuction?.groupId);
      setClientId(activeAuction?.clientId);
      setTitle(activeAuction?.title);
      setAsset(activeAuction?.assetId);
      setDescription(activeAuction?.description);
      setStartingBid(activeAuction?.startingBid);
      setStartDateTime(new Date(convertToLocal(activeAuction?.startDateTime)));
      setEndDateTime(new Date(convertToLocal(activeAuction?.endDateTime)));
      setBidIncrement(activeAuction?.bidIncrement);
      setVisibility(activeAuction?.visibility);
      setCurrency(activeAuction?.currency);

    }
  }, [activeAuction]);

  useEffect(() => {
    setItemsClients(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  }, [clients]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.AUCTION);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createAuction(groupId, clientId, title, description, assetId, startingBid, formatISO(startDateTime), formatISO(endDateTime), bidIncrement, visibility, currency ));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateAuction(activeId, groupId, clientId, title, description, assetId, startingBid, formatISO(startDateTime), formatISO(endDateTime), bidIncrement, visibility, currency));
    }
  };

  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsStatuses = auctionStatuses?.map(r => ({ label: r, value: r })) || [];

  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
    setItemsClients(clients?.data.filter(data => data.groupId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledClients(false);
  };

  const onClientSelect = (obj: any) => {
    setClientId(obj.value);
  };

  const onVisibilitySelect = (obj: any) => {
    setVisibility(obj.value);
  };

  const onCurrencySelect = (obj: any) => {
    setCurrency(obj.value);
  };

  const handleFilePick = (id: any) => {
    setAsset(id);
  };
  const handleFileUnPick = () => {
    setAsset('');
  };

  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} Auction</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>

              <DropDown v={v} err={err}
                        id="groupId"
                        placeholder="Please select a Group"
                        required label="Group"
                        items={itemsGroups}
                        value={groupId}
                        disabled={isPosting || isFetching}
                        onSelect={onGroupSelect}
                        type="default"
              />
              <DropDown v={v} err={err}
                        id="clientId"
                        placeholder="Please select a Client"
                        required
                        label="Client"
                        items={itemsClients}
                        disabled={isPosting || isFetching || disabledClient}
                        value={clientId}
                        onSelect={onClientSelect}
                        type="default"
              />
                <Input v={v} err={err}
                       name="title"
                       id="title"
                       label="Title"
                       required
                       value={title}
                       onChange={setTitle}
                       disabled={isPosting || isFetching}
                       onBlur={setTitle}
                       placeholder="Insert a title"
                />
                <Input v={v} err={err}
                       name="description"
                       id="description"
                       label="Description"
                       required
                       value={description}
                       onChange={setDescription}
                       disabled={isPosting || isFetching}
                       onBlur={setDescription}
                       placeholder="Insert a description"
                />
              <FileSelector
                  type="images"
                  dynamicId = 'primary'
                  groupId={groupId ?? activeAuction?.groupId}
                  clientId={clientId ?? activeAuction?.clientId}
                  picked={assetId}
                  onPick={handleFilePick}
                  onUnPick={handleFileUnPick}
              />
                <Input v={v} err={err}
                       name="startingBid"
                       id="startingBid"
                       label="Starting Bid"
                       value={startingBid}
                       onChange={setStartingBid}
                       disabled={isPosting || isFetching}
                       onBlur={setStartingBid}
                       placeholder="Insert a starting Bid"
                />
              <DateSelect
                  onChange={setStartDateTime}
                  value={startDateTime}
              />

              <DateSelect
                  onChange={setEndDateTime}
                  value={endDateTime}
              />

              <Input v={v} err={err}
                     name="bidIncrement"
                     id="bidIncrement"
                     label="Bid Increment"
                     required
                     value={bidIncrement}
                     onChange={setBidIncrement}
                     disabled={isPosting || isFetching}
                     onBlur={setBidIncrement}
                     placeholder="Insert a Bid Increment"
              />
              <DropDown v={v} err={err}
                        id="currency"
                        placeholder="Please select an auction currency"
                        required
                        label="Currency"
                        items={currencyItems}
                        disabled={isPosting || isFetching}
                        value={visibility}
                        onSelect={onCurrencySelect}
                        type="default"
              />

              <DropDown v={v} err={err}
                        id="visibility"
                        placeholder="Please select an auction visibility"
                        required
                        label="Visibility"
                        items={itemsStatuses}
                        disabled={isPosting || isFetching}
                        value={visibility}
                        onSelect={onVisibilitySelect}
                        type="default"
              />
                


                <div className="row">
                    <div className="col-md-4">
                        <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                                onClick={() => editMode ? onSave() : onSubmit()}
                                title={editMode ? 'SAVE' : 'CREATE'}/>
                    </div>
                </div>
            </form>
        </View>
  );

};

export default AuctionForm;
