import React from 'react';
import { Transaction } from '../../types/Transaction';
import { InvoiceType } from '../../helpers/constants';
import Button from '../../components/Button';
import Markdown from './MarkdownRenderer';

interface ReceiptProps {
  clientData: any; // Type according to your client data structure
  activeTransaction?: Transaction | null;
  invoiceType?: InvoiceType;
  itemId?: string;
  itemName?: string;
  itemDescription?: string;
  itemDetails?: string;
  itemPrice?: number;
  index?: string | null;
  termsAndConditions?: string;
}

const Receipt: React.FC<ReceiptProps> = ({ clientData, activeTransaction, itemId, itemName, itemDescription, invoiceType, itemPrice, index, itemDetails, termsAndConditions }) => {
  const printReceipt = (receiptHtml: string) => {
    const printWindow = window.open('', '_blank');

    if (printWindow) {
      printWindow.document.write('<html><head><title>Invoice</title></head><body>');
      printWindow.document.write(receiptHtml);
      printWindow.document.write('</body></html>');

      printWindow.document.close();
      setTimeout(() => {
        printWindow.print();
        printWindow.close();
      }, 500);
    }
  };

  const handlePrint = () => {
    const receiptHtml = document.getElementById('receipt-content' + index)?.innerHTML || '';
    if (receiptHtml) {
      printReceipt(receiptHtml);
    }
  };

  const getHeaderTitle = () => {
    switch (invoiceType) {
      case InvoiceType.PLAN:
        return 'DRAFT INVOICE';
      case InvoiceType.SUBSCRIPTION:
      case InvoiceType.AUCTION:
        return 'INVOICE';
      default:
        return 'RECEIPT';
    }
  };

  return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px' }}>
          <div>
              <Button onClick={handlePrint} title={'Print ' + getHeaderTitle().toLowerCase()}/>
          </div>
          <div id={'receipt-content' + index} style={{
            border: '1px solid #ddd',
            padding: '30px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            backgroundColor: 'white',
            overflowX: 'auto',
          }}>
              <h1 style={{ textAlign: 'center' }}>{getHeaderTitle()}</h1>

              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                  {/* Issued To */}
                  <div>
                      <div style={{ fontWeight: 'bold', marginBottom: '10px', fontSize: '14px' }}>ISSUED TO:<span
                          style={{ fontWeight: 'normal', fontSize: '14px' }}> {clientData?.title}</span></div>
                      <div style={{ fontWeight: 'bold', marginBottom: '10px', fontSize: '14px' }}>VAT:<span
                          style={{ fontWeight: 'normal', fontSize: '14px' }}> N/A</span></div>
                      <div style={{ fontWeight: 'bold', marginBottom: '10px', fontSize: '14px' }}>
                          DATE: <span
                          style={{ fontWeight: 'normal', fontSize: '14px' }}>{activeTransaction?.created
                            ? new Date(activeTransaction.created).toLocaleString()
                            : 'N/A'}</span>
                      </div>
                      {invoiceType !== InvoiceType.PLAN && activeTransaction ? (
                          <>
                              <div>{activeTransaction?.created_by_user.email}</div>
                              <div>
                                  {activeTransaction?.created_by_user.firstName} {activeTransaction?.created_by_user.lastName}
                              </div>
                          </>
                      ) : (
                          <>
                              <div>{clientData?.website}</div>
                              <div>{clientData?.billingContact}</div>
                              <div>{clientData?.phone}</div>
                          </>
                      )}
                  </div>
                  {/* Transaction Details */}
                  <div>
                      <div>
                          <div style={{ fontWeight: 'bold', marginBottom: '10px', fontSize: '14px' }}>
                          TRANSACTION ID: <span
                              style={{ fontWeight: 'normal', fontSize: '14px' }}>{activeTransaction?.id ?? 'N/A'}</span>
                          </div>
                          <div style={{ fontWeight: 'bold', marginBottom: '10px', fontSize: '14px' }}>
                              OPP TRANSACTION ID: <span
                              style={{
                                fontWeight: 'normal',
                                fontSize: '14px',
                              }}>{activeTransaction?.oppTransactionId ?? 'N/A'}</span>
                          </div>

                      </div>

                      {/* Draft Invoice */}
                      {(invoiceType === InvoiceType.PLAN || invoiceType === InvoiceType.SUBSCRIPTION) && (
                          <div>
                              <div style={{ fontWeight: 'bold', marginBottom: '10px', fontSize: '14px' }}>
                                  PLAN ID: <span
                                  style={{ fontWeight: 'normal', fontSize: '14px' }}>{itemId}</span>
                              </div>
                          </div>
                      )}
                  </div>
              </div>

              {/* Pay To */}
              <div style={{ marginTop: '20px' }}>
                  <div style={{ fontWeight: 'bold', marginBottom: '10px', fontSize: '14px' }}>PAY TO:</div>
                  <div>Excess Materials Exchange B.V.</div>
                  <div>Kanaalweg 1</div>
                  <div>2628EB Delft</div>
                  <div>The Netherlands</div>
                  <div>VAT: NL857920704801</div>
              </div>

              {/* Table */}
              <table style={{ width: '100%', marginTop: '20px', borderCollapse: 'collapse' }}>
                  <thead>
                  <tr>
                      {['TITLE', 'DESCRIPTION', 'UNIT PRICE', 'QTY', 'TAX', 'TOTAL'].map((header) => (
                          <th key={header} style={{
                            fontWeight: 'bold',
                            borderBottom: '1px solid #ddd',
                            padding: '10px',
                            textAlign: 'left',
                          }}>
                              {header}
                          </th>
                      ))}
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                      <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{itemName ?? 'N/A'}</td>
                      <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{itemDescription ?? 'N/A'}</td>
                      <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                          {invoiceType === InvoiceType.PLAN
                            ? itemPrice
                            : (activeTransaction ? activeTransaction?.amount / 100 : 1).toFixed(2)}{' '}
                          EUR
                      </td>
                      <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>1</td>
                      <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>VAT 0% Rated</td>
                      <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                          {invoiceType === InvoiceType.PLAN
                            ? itemPrice
                            : (activeTransaction ? activeTransaction?.amount / 100 : 1).toFixed(2)}{' '}
                          EUR
                      </td>
                  </tr>
                  </tbody>
              </table>

              {/* Total */}
              <div style={{ marginTop: '20px', fontWeight: 'bold' }}>
                  TOTAL: {invoiceType === InvoiceType.PLAN ? itemPrice : (activeTransaction ? activeTransaction?.amount / 100 : 1).toFixed(2)} EUR
              </div>
              <div style={{
                fontWeight: 'bold',
                borderBottom: '1px solid #ddd',
                padding: '10px',
                textAlign: 'left',
              }}>
              </div>
              <div style={{
                marginBottom: '5px',
                marginTop: '30px',
              }}>
                  <div style={{ fontWeight: 'bold', marginBottom: '10px', fontSize: '14px' }}>Payment Instructions</div>
                  <div>IBAN: NL22 DEUT 7058 1626 04</div>
                  <div>BIC: DEUTNL2AXXX</div>
                  <div>Account Name: Excess Materials Exchange B.V.</div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                      {/* Pay To */}
                      <div style={{ marginTop: '10px' }}>
                          <div style={{ fontWeight: 'bold', marginBottom: '10px', fontSize: '14px' }}>Company details
                          </div>
                          <div>Excess Materials Exchange B.V.</div>
                          <div>Kanaalweg 1</div>
                          <div>2628EB Delft</div>
                          <div>The Netherlands</div>
                          <div>VAT: NL857920704801</div>
                      </div>

                      <div style={{ marginTop: '10px' }}>
                          <div style={{ fontWeight: 'bold', marginBottom: '10px', fontSize: '14px' }}>Bank address</div>
                          <div>Deutsche Bank AG</div>
                          <div>De Entree 196</div>
                          <div>Amsterdam</div>
                          <div>Netherlands</div>
                      </div>
                  </div>
              </div>
              <div style={{
                fontWeight: 'bold',
                borderBottom: '1px solid #ddd',
                padding: '10px',
                textAlign: 'left',
              }}>
                  </div>
                  <div style={{
                    marginBottom: '5px',
                    marginTop: '20px',
                  }}>
                      <Markdown text={itemDetails ?? ''}/>
                  </div>
                  {termsAndConditions && <a href={termsAndConditions} target="_blank"
                                            rel="noopener noreferrer">EME general terms and conditions for
                      services </a>}
              </div>
          </div>
  );
};

export default Receipt;
