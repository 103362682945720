import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getEntryTags, isFetchingEntryTags } from '../../../selectors/EntryTag';
import { activateEntryTag, fetchEntryTags, storeEntryTag } from '../../../actions/EntryTag';
import { EntryTag } from '../../../types/EntryTag';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';



const EntryTagDataTable = () => {

  const entryTags = useSelector(getEntryTags);
  const isLoading = useSelector(isFetchingEntryTags);
  const [toggle, setToggle] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);
  const [entryTag, setActiveEntryTag] = useState<EntryTag | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.ENTRY_TAG_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.ENTRY_TAG_UPDATE, row.id));
  };

  const onArchive = (row: EntryTag) => {
    setActiveEntryTag(row);
    setToggle(true);
  };

  const onUnArchive = (row: EntryTag) => {
    setActiveEntryTag(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (entryTag?.id) {
      if (unArchiveToggle){
        await activateEntryTag(entryTag?.id);
      } else {
        await storeEntryTag(entryTag?.id);
      }
      setRefetch(!refetch);
    }
    setActiveEntryTag(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'entryTitle', label: 'Entry', mobileFriendly: true },
      { key: 'tagValue', label: 'Tag', mobileFriendly: true },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: 'Archive', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive }],
    hideButton: true,
  };

  return (
      <View title="EntryTags" onAddNew={onCreate}>
        <DataTable fetchFunction={fetchEntryTags} paging={entryTags?.paging}
                   isLoading={isLoading}  data={entryTags?.data || []} config={config} dependencies={refetch} />
        <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Entry' : 'Archive Entry'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
          {unArchiveToggle ? 'Are you sure you want to unarchive this entry?' : 'Are you sure you want to archive this entry?'}
        </Modal>
      </View>
  );
};

export default EntryTagDataTable;

