import {
  FETCH_TRANSACTION_FAILURE,
  FETCH_TRANSACTION_REQUEST,
  FETCH_TRANSACTION_RESPONSE,
  FETCH_TRANSACTIONS_FAILURE,
  FETCH_TRANSACTIONS_REQUEST,
  FETCH_TRANSACTIONS_RESPONSE,
  POST_TRANSACTION_FAILURE,
  POST_TRANSACTION_RESPONSE,
  CLEAR_ACTIVE_TRANSACTION,
  TransactionActionTypes,
  TransactionState,
  POST_TRANSACTION_REQUEST,
} from '../types/Transaction';
import { parseValidationErrors } from '../helpers/functions';

const initialState: TransactionState = {
  transactions: null,
  activeTransaction: null,
  isFetchingTransactions: false,
  fetchingTransactionsFailed: false,
  isPostingTransaction: false,
  postingTransactionFailed: false,
  postingTransactionError: null,
  postingTransactionValidationErrors: null,
};

const transactionsReducer = (
  state = initialState,
  action: TransactionActionTypes,
): TransactionState => {
  switch (action.type) {
    case FETCH_TRANSACTIONS_REQUEST:
      return {
        ...state,
        transactions: null,
        isFetchingTransactions: true,
        fetchingTransactionsFailed: false,
      };
    case FETCH_TRANSACTIONS_RESPONSE:
      return {
        ...state,
        transactions: action.payload || [],
        isFetchingTransactions: false,
        fetchingTransactionsFailed: false,
      };
    case FETCH_TRANSACTIONS_FAILURE:
      return {
        ...state,
        transactions: null,
        isFetchingTransactions: false,
        fetchingTransactionsFailed: true,
      };
    case FETCH_TRANSACTION_REQUEST:
      return {
        ...state,
        activeTransaction: null,
        isFetchingTransactions: true,
        fetchingTransactionsFailed: false,
      };
    case FETCH_TRANSACTION_RESPONSE:
      return {
        ...state,
        activeTransaction: action.payload,
        isFetchingTransactions: false,
        fetchingTransactionsFailed: false,
      };
    case FETCH_TRANSACTION_FAILURE:
      return {
        ...state,
        activeTransaction: null,
        isFetchingTransactions: false,
        fetchingTransactionsFailed: true,
      };
    case POST_TRANSACTION_REQUEST:
      return {
        ...state,
        isPostingTransaction: true,
        postingTransactionError: null,
        postingTransactionValidationErrors: null,
        postingTransactionFailed: false,
      };
    case POST_TRANSACTION_RESPONSE:
      return {
        ...state,
        isPostingTransaction: false,
        postingTransactionError: null,
        postingTransactionValidationErrors: null,
        postingTransactionFailed: false,
      };
    case POST_TRANSACTION_FAILURE:
      return {
        ...state,
        isPostingTransaction: false,
        postingTransactionError: action.payload.error || 'An error occurred',
        postingTransactionValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingTransactionFailed: true,
      };
    case CLEAR_ACTIVE_TRANSACTION:
      return {
        ...state,
        activeTransaction: null,
        transactions: null,
      };
    default:
      return state;
  }
};

export default transactionsReducer;
