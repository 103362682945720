import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getMarketplaceEntries, isFetchingMarketplaceEntries } from '../../../selectors/MarketplaceEntry';
import { destroyMarketplaceEntry, fetchMarketplaceEntries } from '../../../actions/MarketplaceEntry';
import { MarketplaceEntry } from '../../../types/MarketplaceEntry';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';

const MarketplaceEntryDataTable = () => {
  const marketplaceEntries = useSelector(getMarketplaceEntries);
  const isLoading = useSelector(isFetchingMarketplaceEntries);
  const [toggle, setToggle] = useState(false);
  const [marketplaceEntry, setActiveMarketplaceEntry] = useState<MarketplaceEntry | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.MARKETPLACE_ENTRY_CREATE);
  };

  const onDelete = (row: MarketplaceEntry) => {
    setActiveMarketplaceEntry(row);
    setToggle(true);
  };

  const onConfirmDelete = async () => {
    setToggle(false);
    if (marketplaceEntry?.id) {
      await destroyMarketplaceEntry(marketplaceEntry?.id);
      setRefetch(!refetch);
    }
    setActiveMarketplaceEntry(null);
  };

  const config = {
    columns: [
      { key: 'marketplaceTitle', label: 'Marketplace', mobileFriendly: true },
      { key: 'entryTitle', label: 'Entry', mobileFriendly: true },
    ],
    actions: [
      { icon: 'bin', label: '', onClick: onDelete },
    ],
    hideButton: true,
  };

  return (
      <View title="Marketplace/Entry" onAddNew={onCreate}>
        <DataTable
            fetchFunction={fetchMarketplaceEntries}
            isLoading={isLoading}
            paging={marketplaceEntries?.paging}
            data={marketplaceEntries?.data || []}
            config={config}
            dependencies={refetch}
        />
        <Modal show={toggle} title="Delete Marketplace/Entry" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
          Are you sure you want to delete this marketplace/entry?
        </Modal>
      </View>
  );
};

export default MarketplaceEntryDataTable;
