import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  title?: string | undefined,
  description?: string | undefined,
  image: string,
  type?: string | undefined,
};

const SEO = (props: Props) => {
  const url = window.location.href; // Gets the full current URL
  const { title = '', description = '', image, type = '' } = props;

  return (
      <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title}/>
          <meta property="og:description" content={description}/>
          <meta property="og:image" content={image}/>
          <meta property="og:url" content={url}/>
          <meta property="og:type" content={type}/>
      </Helmet>
  );
};

export default SEO;
