import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getViewRoles, isFetchingViewRoles } from '../../../selectors/ViewRole';
import { destroyViewRole, fetchViewRoles } from '../../../actions/ViewRole';
import { ViewRole } from '../../../types/ViewRole';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';


const ViewRoleDataTable = () => {

  const viewRoles = useSelector(getViewRoles);
  const isLoading = useSelector(isFetchingViewRoles);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [viewRole, setActiveViewRole] = useState<ViewRole | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.VIEW_ROLE_CREATE);
  };

  const onDelete = (row: ViewRole) => {
    setActiveViewRole(row);
    setToggle(true);
  };

  const onConfirmDelete = () => {
    setToggle(false);
    if (viewRole?.id) {
      //@ts-ignore
      dispatch(destroyViewRole(viewRole?.id));
      setRefetch(!refetch);
    }
    setActiveViewRole(null);
  };


  const config = {
    columns: [
      { key: 'viewTitle', label: 'View', mobileFriendly: true },
      { key: 'roleTitle', label: 'Role', mobileFriendly: true },

    ],
    actions: [
      { icon: 'bin', label: '', onClick: onDelete },
    ],
    hideButton: true,
  };

  return (
      <View title="View/Role" onAddNew={onCreate}>
            <DataTable fetchFunction={fetchViewRoles} paging={viewRoles?.paging}
                       isLoading={isLoading} data={viewRoles?.data || []} config={config} dependencies={refetch}/>
            <Modal show={toggle} title="Delete View/Role" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
                Are you sure you want to delete this view/role?
            </Modal>
        </View>
  );
};

export default ViewRoleDataTable;