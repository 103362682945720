import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getAuctionBids, isFetchingAuctionBids } from '../../../selectors/AuctionBid';
import { storeAuctionBid, fetchAuctionBids } from '../../../actions/AuctionBid';
import { AuctionBid } from '../../../types/AuctionBid';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';
import { formatUrl, removeIds } from '../../../helpers/functions';

const AuctionBidDataTable = () => {
  const auctionBids = useSelector(getAuctionBids);
  const isLoading = useSelector(isFetchingAuctionBids);
  const [toggle, setToggle] = useState(false);
  const [auctionBid, setActiveAuctionBid] = useState<AuctionBid | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.AUCTION_BID_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.AUCTION_BID_UPDATE, row.id));
  };

  const onArchive = (row: AuctionBid) => {
    setActiveAuctionBid(row);
    setToggle(true);
  };

  const onConfirmDelete = async () => {
    setToggle(false);
    if (auctionBid?.id) {
      await storeAuctionBid(auctionBid?.id);
      setRefetch(!refetch);
    }
    setActiveAuctionBid(null);
  };

  const config = {
    columns: [
      { key: 'auctionTitle', label: 'Auction', mobileFriendly: true },
      { key: 'entryTitle', label: 'Entry', mobileFriendly: true },
      { key: 'userEmail', label: 'Bidder', mobileFriendly: true },
      { key: 'amount', label: 'Price', mobileFriendly: true },
      { key: 'created', label: 'Time Created', mobileFriendly: true },
    ],
    actions: [
      { icon: 'bin', label: '', onClick: onArchive },
      { icon: 'edit', label: '', onClick: onUpdate },
    ],
    hideButton: true,
  };

  return (
      <View title="Auction/Bid" onAddNew={onCreate}>
            <DataTable
                isLoading={isLoading}
                fetchFunction={fetchAuctionBids}
                paging={auctionBids?.paging}
                data={auctionBids?.data || []}
                config={config}
                exclusionList={removeIds(auctionBids?.data[0])}
                dependencies={refetch}
            />
            <Modal show={toggle} title="Delete Auction/Bid" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
                Are you sure you want to delete this auction bid?
            </Modal>
        </View>
  );
};

export default AuctionBidDataTable;
