import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getTransaction(id: string, dataTableParams?: DataTableParamsModel) {
  const url = `${c.API_ENDPOINTS.TRANSACTION}`;


  return (
    axios({
      url: `${url}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getTransactions(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.TRANSACTION,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postTransaction(
  oppTransactionId: string, status: string, merchant_uid: string, profile_uid: string,
  amount: number, currency: string, payment_method: string, payment_flow: string, redirect_url: string, expirationSepaFlag: boolean, additionalSepaDays: number,
) {
  return (
    axios({
      url: c.API_ENDPOINTS.TRANSACTION,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        oppTransactionId,
        status,
        merchant_uid,
        profile_uid,
        amount,
        currency,
        payment_method,
        payment_flow,
        redirect_url,
        expirationSepaFlag, 
        additionalSepaDays,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function putTransaction(
  id: string,
  oppTransactionId: string, status: string, merchant_uid: string, profile_uid: string,
  amount: number, currency: string, payment_method: string, payment_flow: string, redirect_url: string, expirationSepaFlag: boolean, additionalSepaDays: number,
) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.TRANSACTION}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        id,
        oppTransactionId,
        status,
        merchant_uid,
        profile_uid,
        amount,
        currency,
        payment_method,
        payment_flow,
        redirect_url,
        expirationSepaFlag,
        additionalSepaDays,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveTransaction(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.TRANSACTION}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function unarchiveTransaction(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.TRANSACTION}/${id}/unarchive`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}
