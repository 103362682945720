import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import gallery from '../AssetGallery/AssetGallery';
import './SearchBar.scss';
import Input from '../Input/Input';
import Pill from '../Pill';
import { EntryStatus } from '../../helpers/constants';

type Item = {
  value: string;
  label?: string;
};

type Props = {
  placeholder: string;
  onSearch: (term: string, filterField?: string | [], sortField?: string | [], direction?: boolean, statusField?: string | [], visibilityField?: string | []) => void;
  fields: Item[];
  filterByStatus?: boolean,
  filterByVisibility?: boolean,
  defaultFilterField?: string | [];
  defaultSortField?: string;
  defaultStatusField?: string | undefined;
  defaultVisibilityField?: string | undefined;
  orderBy?: boolean;
  defaultSearch?: string;
  preSort?: string;
  preFilter?: string;
  preOrder?: string;
};

const SearchBar = (props: Props) => {
  const {
    placeholder, onSearch = () => {
    }, fields = [], filterByStatus = false, filterByVisibility = false, defaultFilterField = 'All', defaultSortField, defaultSearch = '', defaultStatusField = '', defaultVisibilityField = '', orderBy = true, preSort = '', preFilter = '', preOrder = '',
  } = props;
  const [searchText, setSearchText] = useState(defaultSearch || '');
  const [filterField, setFilterField] = useState(preFilter || defaultFilterField);
  const [sortField, setSortField] = useState(preSort || defaultSortField);
  const [order, setDirection] = useState(preOrder === 'asc' ?? orderBy);
  const [statusField, setStatusField] = useState<any | undefined | ''>(defaultStatusField);
  const [visibilityField, setVisibilityField] = useState<any | undefined | ''>(defaultVisibilityField);

  const statuses = Object.values(EntryStatus);
  const visibilityStatus = ['Public', 'Private'];

  const onInnerSearch = () => {
    onSearch(searchText, filterField, sortField, order, statusField, visibilityField);
  };

  const onChange = (value:string) => {
    setSearchText(value);
  };
  const removeFilter = () => {
    setFilterField(defaultFilterField);
  };

  const removeStatus = () => {
    setStatusField(defaultStatusField);
  };

  const removeVisibility = () => {
    setVisibilityField(defaultVisibilityField);
  };

  const removeSortBy = () => {
    setSortField(defaultSortField);
  };

  const changeDirection = () => {
    setDirection(!order);
  };

  const onBlur = (value:string) => {
    setSearchText(value);
  };

  useEffect(()=>{
    onSearch(searchText, filterField, sortField, order, statusField, visibilityField);
  }, [visibilityField]);

  useEffect(()=>{
    onSearch(searchText, filterField, sortField, order, statusField, visibilityField);
  }, [statusField]);


  useEffect(() => {
    onSearch(searchText, filterField, sortField, order, statusField, visibilityField);
  }, [sortField]);

  useEffect(()=>{
    if (searchText === ''){
      onSearch(searchText, filterField, sortField, order, statusField, visibilityField);
    }
  }, [searchText]);

  useEffect(() => {
    if (sortField){
      onSearch(searchText, filterField, sortField, order, statusField, visibilityField);
    }
  }, [order]);

  const filterBy = fields?.find(f => f.value === filterField)?.label;
  const sortBy = fields?.find(f => f.value === sortField)?.label;
  const sortList = fields.filter((f) => f.label !== 'All');
  const statusBy = statuses?.find(f => f == statusField);
  const visibilityBy = visibilityStatus?.find(f => f == visibilityField);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onInnerSearch();
    }
  };

  const filterByPill = () =>{
    if (!filterBy || filterBy === 'All' || filterBy === defaultFilterField){
      return;
    }
    return (
        <div className='filter-by'>
            <Pill text={`Filter By: ${fields?.find(f => f.value === filterField)?.label || ''}`} type={'black'} cancellable onClick={removeFilter}/>
        </div>
    );
  };

  const statusByPill = () =>{
    if (!statusBy || statusBy === defaultStatusField){
      return;
    }
    return (
            <div className='filter-by'>
                <Pill text={`Status By: ${statuses?.find(f => f === statusField) || ''}`} type={'black'} cancellable onClick={removeStatus}/>
            </div>
    );
  };

  const visibilityByPill = () =>{
    if (!visibilityBy || visibilityBy === defaultVisibilityField){
      return;
    }
    return (
            <div className='filter-by'>
                <Pill text={`Status By: ${visibilityStatus?.find(f => f === visibilityField) || ''}`} type={'black'} cancellable onClick={removeVisibility}/>
            </div>
    );
  };

  const sortByPill = () =>{
    if (!sortBy || sortBy === defaultSortField || !preSort){
      return;
    }
    return (
      <div className='filter-by'>
          <Pill text={`Sort By: ${fields?.find(f => f.value === sortField)?.label || ''}`} type={'black'} cancellable onClick={removeSortBy}/>
      </div>
    );
  };

  return (
        <>
            <div className="search-bar">
                <div className="input-group">
                    <Input
                        value={searchText} onChange={onChange}
                        onBlur={onBlur} placeholder={placeholder} onKeyDown={onKeyDown}
                    />
                    <span className="input-group-btn">
                        <div className="btn btn-search" onClick={onInnerSearch}>
                            <img className="search-icon" src={gallery.searchImg} alt='search-icon'/>
                        </div>
                    </span>
                </div>
                <div className="search-bar__controls">
                        <Dropdown>
                            <Dropdown.Toggle variant="success">
                                <div className={`search-bar__controls__icon-group ${filterByPill() && 'search-bar__controls__icon-group--active'}`}>
                                <img className="search-bar__controls__icon-group__icon" src={gallery.filterImg} alt='filter-icon'/>
                                <div className="search-bar__controls__icon-group__text d-none d-xl-block">Filter</div>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                                {fields.map((f, i) =>
                                  (<Dropdown.Item key={i} onClick={() => setFilterField(f.value)}>{f.label || f.value}</Dropdown.Item>))}
                            </Dropdown.Menu>
                        </Dropdown>
                    {filterByStatus && (
                        <Dropdown>
                            <Dropdown.Toggle variant="success">
                                <div className={`search-bar__controls__icon-group ${statusByPill() && 'search-bar__controls__icon-group--active'}`}>
                                    <img className="search-bar__controls__icon-group__icon" src={gallery.filterStatus} alt='filter-icon'/>
                                    <div className="search-bar__controls__icon-group__text d-none d-xl-block">Status</div>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                                {statuses.map((f, i) =>
                                  (<Dropdown.Item key={i} onClick={() => setStatusField(f)}>{f}</Dropdown.Item>))}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                    {filterByVisibility && (
                        <Dropdown>
                            <Dropdown.Toggle variant="success">
                                <div className={`search-bar__controls__icon-group ${visibilityByPill() && 'search-bar__controls__icon-group--active'}`}>
                                    <img className="search-bar__controls__icon-group__icon" src={gallery.filterStatus} alt='filter-icon'/>
                                    <div className="search-bar__controls__icon-group__text d-none d-xl-block">Status</div>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                                {visibilityStatus.map((f, i) =>
                                  (<Dropdown.Item key={i} onClick={() => setVisibilityField(f)}>{f}</Dropdown.Item>))}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                    <Dropdown>
                        <Dropdown.Toggle variant="success">
                            <div className={`search-bar__controls__icon-group ${sortByPill() && 'search-bar__controls__icon-group--active'}`}>
                                <img className="search-bar__controls__icon-group__icon" src={gallery.sortByImg} alt='sort-by-icon'/>
                                <div className="search-bar__controls__icon-group__text d-none d-xl-block">Sort By</div>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="end">
                            {sortList.map((f, i) =>
                              (<Dropdown.Item key={i} onClick={() => setSortField(f.value)}>{f.label || f.value}</Dropdown.Item>))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <div className="search-bar__controls__direction">
                        <div className="search-bar__controls__icon-group" onClick={changeDirection}>
                            <img className={`search-bar__controls__icon-group__icon ${ order && 'search-bar__controls__icon-group__icon--asc'}`} src={gallery.arrowAscImg} alt='direction-icon'/>
                            <div className={`search-bar__controls__icon-group__text d-none d-xl-block ${ order && 'search-bar__controls__icon-group__text--asc'}`}>
                            {order ? 'ASC' : 'DESC'}
                            </div>
                        </div>
                    </div>
                    {(filterByPill() || sortByPill() || statusByPill() || visibilityByPill()) &&
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="search-bar__controls__divider">
                          <div className="search-bar__controls__vl"/>
                      </div>
                          <div className="search-bar__controls__pills">
                            {filterByPill()}
                            {sortByPill()}
                            {statusByPill()}
                            {visibilityByPill()}
                          </div>
                        </div>
                    }
                </div>
            </div>
        </>
  );
};

export default SearchBar;

