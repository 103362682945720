import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getPopularSearches, isFetchingPopularSearches } from '../../../selectors/PopularSearch';
import { destroyPopularSearch, fetchPopularSearches } from '../../../actions/PopularSearch';
import { PopularSearch } from '../../../types/PopularSearch';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';

const PopularSearchDataTable = () => {

  const popularSearches = useSelector(getPopularSearches);
  const isLoading = useSelector(isFetchingPopularSearches);
  const [toggle, setToggle] = useState(false);
  const [popularSearch, setActivePopularSearch] = useState<PopularSearch | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.POPULAR_SEARCH_CREATE);
  };
  const onDelete = (row: PopularSearch) => {
    setActivePopularSearch(row);
    setToggle(true);
  };

  const onConfirmDelete = async () => {
    setToggle(false);
    if (popularSearch?.id) {
      await destroyPopularSearch(popularSearch?.id);
      setRefetch(!refetch);
    }
    setActivePopularSearch(null);
  };

  const config = {
    columns: [
      { key: 'searchTerm', label: 'Search Term', mobileFriendly: true },
      { key: 'searchType', label: 'Search Type', mobileFriendly: true },
      { key: 'popularity', label: 'Popularity', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'bin', label: '', onClick: onDelete }],
  };


  return <View>
    <>
      <DataTable title="Popular Searches" ctaTitle="Create Popular Search" onCreate={onCreate} isLoading={isLoading}
                 fetchFunction={fetchPopularSearches} paging={popularSearches?.paging}
                 data={popularSearches?.data || []} config={config} dependencies={refetch}/>
      <Modal show={toggle} title="Delete Popular Search" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
        Are you sure you want to delete this Popular Search?
      </Modal>
    </>
  </View>;
};

export default PopularSearchDataTable;
