import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getRoles, isFetchingRoles } from '../../../selectors/Role';
import { activateRole, fetchRoles, storeRole } from '../../../actions/Role';
import { Role } from '../../../types/Role';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';
import { getLoggedInRole } from '../../../selectors/Auth';


const RoleDataTable = () => {

  const roles = useSelector(getRoles);
  const isLoading = useSelector(isFetchingRoles);
  const [toggle, setToggle] = useState(false);
  const [role, setActiveRole] = useState<Role | null>();
  const [refetch, setRefetch] = useState(false);
  const loggedInRole = useSelector(getLoggedInRole);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.ROLE_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.ROLE_UPDATE, row.id));
  };

  const onArchive = (row: Role) => {
    setActiveRole(row);
    setToggle(true);
  };

  const onUnArchive = (row: Role) => {
    setActiveRole(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (role?.id) {
      if (unArchiveToggle){
        await activateRole(role?.id);
      } else {
        await storeRole(role?.id);
      }
      setRefetch(!refetch);
    }
    setActiveRole(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: false },
      { key: 'accessLevel', label: 'Level Of Access', mobileFriendly: false },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: loggedInRole?.accessLevel === 0 ? [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive },
    ] : [],
    hideButton: true,
  };

  return <View title="Roles" onAddNew={onCreate}>
    <>
      <DataTable fetchFunction={fetchRoles} paging={roles?.paging}
                 isLoading={isLoading} data={roles?.data || []} config={config} dependencies={refetch} />
      <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Role' : 'Archive Role'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
        {unArchiveToggle ? 'Are you sure you want to unarchive this Role?' : 'Are you sure you want to archive this Role?'}
      </Modal>
    </>
  </View>;
};

export default RoleDataTable;
