import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getSolutionBadges, isFetchingSolutionBadges } from '../../../selectors/SolutionBadge';
import { activateSolutionBadge, fetchSolutionBadges, storeSolutionBadge } from '../../../actions/SolutionBadge';
import { SolutionBadge } from '../../../types/SolutionBadge';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const SolutionBadgeDataTable = () => {

  const solutionBadges = useSelector(getSolutionBadges);
  const isLoading = useSelector(isFetchingSolutionBadges);
  const [toggle, setToggle] = useState(false);
  const [solutionBadge, setActiveSolutionBadge] = useState<SolutionBadge | null>();
  const [refetch, setRefetch] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);


  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.SOLUTION_BADGE_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.SOLUTION_BADGE_UPDATE, row.id));
  };

  const onArchive = (row: SolutionBadge) => {
    setActiveSolutionBadge(row);
    setToggle(true);
  };

  const onUnArchive = (row: SolutionBadge) => {
    setActiveSolutionBadge(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (solutionBadge?.id) {
      if (unArchiveToggle){
        await activateSolutionBadge(solutionBadge?.id);
      } else {
        await storeSolutionBadge(solutionBadge?.id);
      }
      setRefetch(!refetch);
    }
    setActiveSolutionBadge(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'badgeValue', label: 'Badge', mobileFriendly: true },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive },
    ],
    hideButton: true,
  };

  return (
      <View title="SolutionBadges" onAddNew={onCreate}>
        <DataTable fetchFunction={fetchSolutionBadges} paging={solutionBadges?.paging} isLoading={isLoading}
                   data={solutionBadges?.data || []} config={config} dependencies={refetch} />
        <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Solution Badge' : 'Archive Solution Badge'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
          {unArchiveToggle ? 'Are you sure you want to unarchive this Solution Badge?' : 'Are you sure you want to archive this Solution Badge?'}
        </Modal>
      </View>
  );
};

export default SolutionBadgeDataTable;

