import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getRoleClients, isFetchingRoleClients } from '../../../selectors/RoleClient';
import { destroyRoleClient, fetchRoleClients } from '../../../actions/RoleClient';
import { RoleClient } from '../../../types/RoleClient';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';


const RoleClientDataTable = () => {

  const roleClients = useSelector(getRoleClients);
  const isLoading = useSelector(isFetchingRoleClients);
  const [toggle, setToggle] = useState(false);
  const [roleClient, setActiveRoleClient] = useState<RoleClient | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.ROLE_CLIENT_CREATE);
  };



  const onDelete = (row: RoleClient) => {
    setActiveRoleClient(row);
    setToggle(true);
  };

  const onConfirmDelete = async () => {
    setToggle(false);
    if (roleClient?.id) {
      await destroyRoleClient(roleClient?.id);
      setRefetch(!refetch);
    }
    setActiveRoleClient(null);
  };


  const config = {
    columns: [
      { key: 'roleTitle', label: 'Role', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },

    ],
    actions: [
      { icon: 'bin', label: '', onClick: onDelete },
    ],
    hideButton: true,
  };

  return (
      <View title="Role/Client" onAddNew={onCreate}>
            <DataTable fetchFunction={fetchRoleClients} paging={roleClients?.paging} isLoading={isLoading}
                       data={roleClients?.data || []} config={config} dependencies={refetch}/>
            <Modal show={toggle} title="Delete Role/Client" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
                Are you sure you want to delete this Role/Client?
            </Modal>

        </View>
  );
};

export default RoleClientDataTable;