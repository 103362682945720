import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c, { LeadStatus } from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createLead, fetchActiveLead, fetchLeads, updateLead } from '../../../actions/Lead';
import CheckBox from '../../../components/CheckBox';
import {
  getActiveLead,
  isPostingLead,
  postingLeadError,
  postingLeadFailed,
  postingLeadValidationErrors,
  isFetchingLeads,
} from '../../../selectors/Lead';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import DropDown from '../../../components/DropDown';


const LeadForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeLead = useSelector(getActiveLead);
  const isPosting = useSelector(isPostingLead);
  const postingFailed = useSelector(postingLeadFailed);
  const postingError = useSelector(postingLeadError);
  const postingValidationErrors = useSelector(postingLeadValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.LEAD_UPDATE);

  const formConfig = [
    { field: 'firstName', validators: [isRequired] },
    { field: 'lastName', validators: [isRequired] },
    { field: 'email', validators: [] },
    { field: 'phoneNumber', validators: [isRequired] },
    { field: 'companyName', validators: [isRequired] },
    { field: 'companyCommercialNumber', validators: [isRequired] },
    { field: 'companyPhone', validators: [isRequired] },
    { field: 'companyEmail', validators: [isRequired] },
    { field: 'companyWebsite', validators: [isRequired] },
    { field: 'roleInCompany', validators: [isRequired] },
    { field: 'city', validators: [isRequired] },
    { field: 'country', validators: [isRequired] },
    { field: 'referredBy', validators: [] },
  ];


  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [referredBy, setReferredBy] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyCommercialNumber, setCompanyCommercialNumber] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [roleInCompany, setRoleInCompany] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [verification, setVerification] = useState(LeadStatus.PENDING);
  const [transaction, setTransaction] = useState(LeadStatus.PENDING);
  const [agreement, setAgreement] = useState(LeadStatus.PENDING);
  const [onboarded, setOnboarded] = useState(LeadStatus.PENDING);
  const isFetching = useSelector(isFetchingLeads);
  
  
  const leadStatuses = [
    LeadStatus.PENDING,
    LeadStatus.ACCEPTED,
    LeadStatus.REJECTED,
  ];
  const itemsStatuses = leadStatuses?.map(r => ({ label: r, value: r })) || [];

  useEffect(() => {
    dispatch(fetchLeads());
    if (editMode) {
      dispatch(fetchActiveLead(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeLead && editMode) {
      setFirstName(activeLead?.firstName);
      setLastName(activeLead?.lastName);
      setEmail(activeLead?.email);
      setReferredBy(activeLead?.referredBy);
      setPhoneNumber(activeLead?.phoneNumber);
      setCompanyName(activeLead?.companyName);
      setCompanyCommercialNumber(activeLead?.companyCommercialNumber);
      setCompanyPhone(activeLead?.companyPhone);
      setCompanyEmail(activeLead?.companyEmail);
      setCompanyWebsite(activeLead?.companyWebsite);
      setRoleInCompany(activeLead?.roleInCompany);
      setCity(activeLead?.city);
      setCountry(activeLead?.country);
      setTermsAccepted(activeLead?.termsAccepted);
      setVerification(activeLead?.verification);
      setTransaction(activeLead?.transaction);
      setAgreement(activeLead?.agreement);
      setOnboarded(activeLead?.onboarded);
    }
  }, [activeLead]);


  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.LEAD);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createLead(firstName, lastName, email, phoneNumber, companyName, companyCommercialNumber, companyPhone, companyEmail, companyWebsite, roleInCompany,
        city, country, termsAccepted, verification, transaction, agreement, onboarded, referredBy));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateLead(activeId, firstName, lastName, email, phoneNumber, companyName, companyCommercialNumber, companyPhone, companyEmail, companyWebsite, roleInCompany,
        city, country, termsAccepted, verification, transaction, agreement, onboarded, referredBy));
    }
  };

  const onSelectDropdownValue = (obj: any, setStateFunction: Function) => {
    setStateFunction(obj.value);
  };

  return (
        <View isLoading={isFetching}>
            <h4>{editMode ? 'Edit' : 'Create'} Lead</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
          <div>
            {v}
            {err}
          </div>
            <form>
                <Input v={v} err={err}
                       name="firstName"
                       id="firstName"
                       label="First Name"
                       required
                       value={firstName}
                       onChange={setFirstName}
                       disabled={isPosting || isFetching}
                       onBlur={setFirstName}
                       placeholder="Insert a firstName"
                />
                <Input v={v} err={err}
                       name="lastName"
                       id="lastName"
                       label="lastName"
                       required
                       value={lastName}
                       onChange={setLastName}
                       disabled={isPosting || isFetching}
                       onBlur={setLastName}
                       placeholder="Insert a lastName"
                />
                <Input v={v} err={err}
                       name="email"
                       id="email"
                       label="Email"
                       value={email}
                       onChange={setEmail}
                       disabled={isPosting || isFetching}
                       onBlur={setEmail}
                       placeholder="Insert an email"
                />
              <Input v={v} err={err}
                     name="referredBy"
                     id="referredBy"
                     label="Referred By"
                     value={referredBy}
                     onChange={setReferredBy}
                     disabled={isPosting || isFetching}
                     onBlur={setReferredBy}
                     placeholder="Insert referral information"
              />
                <Input v={v} err={err}
                       name="phoneNumber"
                       id="phoneNumber"
                       label="Phone Number"
                       type="tel"
                       value={phoneNumber}
                       onChange={setPhoneNumber}
                       disabled={isPosting || isFetching}
                       onBlur={setPhoneNumber}
                       placeholder="Insert a phone number"
                />
              <Input v={v} err={err}
                     name="companyName"
                     id="companyName"
                     label="Company Name"
                     value={companyName}
                     onChange={setCompanyName}
                     disabled={isPosting || isFetching}
                     onBlur={setCompanyName}
                     placeholder="Insert a Company Name"
              />
                <Input v={v} err={err}
                       name="companyCommercialNumber"
                       id="companyCommercialNumber"
                       label="Company Number / Chamber of Commerce Number"
                       value={companyCommercialNumber}
                       onChange={setCompanyCommercialNumber}
                       disabled={isPosting || isFetching}
                       onBlur={setCompanyCommercialNumber}
                       placeholder="Insert a companyCommercialNumber"
                />
              <Input v={v} err={err}
                     name="companyPhone"
                     id="companyPhone"
                     label="Company Phone"
                     type="tel"
                     value={companyPhone}
                     onChange={setCompanyPhone}
                     disabled={isPosting || isFetching}
                     onBlur={setCompanyPhone}
                     placeholder="Insert a Company Phone"
              />
              <Input v={v} err={err}
                     name="companyEmail"
                     id="companyEmail"
                     label="Company Email"
                     value={companyEmail}
                     onChange={setCompanyEmail}
                     disabled={isPosting || isFetching}
                     onBlur={setCompanyEmail}
                     placeholder="Insert a Company Email"
              />
              <Input v={v} err={err}
                     name="companyWebsite"
                     id="companyWebsite"
                     label="Company Website"
                     value={companyWebsite}
                     onChange={setCompanyWebsite}
                     disabled={isPosting || isFetching}
                     onBlur={setCompanyWebsite}
                     placeholder="Insert a Company Website"
              />
              <Input v={v} err={err}
                     name="roleInCompany"
                     id="roleInCompany"
                     label="Role In Company"
                     value={roleInCompany}
                     onChange={setRoleInCompany}
                     disabled={isPosting || isFetching}
                     onBlur={setRoleInCompany}
                     placeholder="Insert a Role In Company"
              />

              <Input v={v} err={err}
                     name="city"
                     id="city"
                     label="City"
                     value={city}
                     onChange={setCity}
                     disabled={isPosting || isFetching}
                     onBlur={setCity}
                     placeholder="Insert a City"
              />

              <Input v={v} err={err}
                     name="country"
                     id="country"
                     label="Country"
                     value={country}
                     onChange={setCountry}
                     disabled={isPosting || isFetching}
                     onBlur={setCountry}
                     placeholder="Insert a Country"
              />

              <CheckBox label="Terms Accepted" checked={termsAccepted} onChange={setTermsAccepted}/>

              <div className="row">
                <div className="col-md-6">

                  <DropDown v={v} err={err}
                            id="verification"
                            placeholder="Please select a status"
                            label="Verification"
                            items={itemsStatuses}
                            value={verification}
                            disabled={isPosting || isFetching}
                            onSelect={(obj: any) => onSelectDropdownValue(obj, setVerification)}
                            type="default"
                  />
                  <DropDown v={v} err={err}
                            id="agreement"
                            placeholder="Please select a status"
                            label="Agreement"
                            items={itemsStatuses}
                            value={agreement}
                            disabled={isPosting || isFetching}
                            onSelect={(obj: any) => onSelectDropdownValue(obj, setAgreement)}
                            type="default"
                  />
                </div>
                <div className="col-md-6">
                  <DropDown v={v} err={err}
                            id="transaction"
                            placeholder="Please select a status"
                            label="Payment" //changed name only in frontend due to client confusion
                            items={itemsStatuses}
                            value={transaction}
                            disabled={isPosting || isFetching}
                            onSelect={(obj: any) => onSelectDropdownValue(obj, setTransaction)}
                            type="default"
                  />
                  <DropDown v={v} err={err}
                            id="onboarded"
                            placeholder="Please select a status"
                            label="Onboarded"
                            items={itemsStatuses}
                            value={onboarded}
                            disabled={isPosting || isFetching}
                            onSelect={(obj: any) => onSelectDropdownValue(obj, setOnboarded)}
                            type="default"
                  />
                </div>
              </div>

              <div className="row">
                    <div className="col-md-4">
                        <Button disabled={!isFormValid() || !termsAccepted} isLoading={isPosting || isFetching}
                                onClick={() => editMode ? onSave() : onSubmit()}
                                title={editMode ? 'SAVE' : 'CREATE'}/>
                    </div>
                </div>
            </form>
        </View>
  );

};

export default LeadForm;
