import { useLocation } from 'react-router-dom';
import axios from 'axios';

import { FormError } from '../types/Common';
import { RefObject, useEffect, useState } from 'react';
import { Entry } from '../types/Entry';
import { Marketplace } from '../types/Marketplace';
import { formatInTimeZone } from 'date-fns-tz';
import c, { EME_CLIENT_UUIDS, EME_GROUP_UUIDS } from '../helpers/constants';
import { User } from '../types/User';
import { useSelector } from 'react-redux';
import { getLoggedInUser } from '../selectors/Auth';
import { AuctionEntry } from '../types/AuctionEntry';
import { Auction } from '../types/Auction';
import { AuctionBid } from '../types/AuctionBid';
import { Offer } from '../types/Offer';
import { getFormStructure } from '../api/Form';
import * as XLSX from 'xlsx';

const lPrefix = 'MATERIALS_';

export const objectToQueryString: any = (obj: any, prefix: string) => {
  let str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p];
      str.push((v !== null && typeof v === 'object') ?
        objectToQueryString(v, k) :
        encodeURIComponent(k) + '=' + encodeURIComponent(v));
    }
  }
  return str.join('&');
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const localStorageSetItem = (key: string, value: string) => {
  window.localStorage.setItem(`${lPrefix}${key}`, value);
};

export const localStorageGetItem = (key: string) => {
  return window.localStorage.getItem(`${lPrefix}${key}`);
};

export const getToken =  () => {
  return localStorageGetItem('token') || '';
};

export const setToken = (token: string) => {
  localStorageSetItem('token', token);
};

export const getUserToken =  () => {
  return localStorageGetItem('activeUser') || '';
};

export function countMatches(row: any, fields:string[], searchWord: string) {
  return fields.reduce((totalMatches, field) => {
    const fieldValue = row?.[field];
    if (fieldValue) {
      const fieldMatches = fieldValue.match(new RegExp(searchWord, 'gi')) || [];
      return totalMatches + fieldMatches.length;
    }
    return totalMatches;
  }, 0);
}

// Function to remove duplicate entries out of string array

export function removeDuplicates(array: string[]): string[]{
  return array.filter((item, index) => array.indexOf(item) === index);
}

export function generatePassword() {
  const length = 8;
  const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const symbols = '!@#$%^&*()-_=+';

  const getRandomChar = (pool: string) => {
    return pool.charAt(Math.floor(Math.random() * pool.length));
  };

  // Ensure each required character type is present
  let password = '';
  password += getRandomChar(uppercaseLetters); // At least one uppercase letter
  password += getRandomChar(lowercaseLetters); // At least one lowercase letter
  password += getRandomChar(numbers); // At least one number
  password += getRandomChar(symbols); // At least one symbol

  // Fill the remaining length with a random mix of all types
  const allCharacters = uppercaseLetters + lowercaseLetters + numbers + symbols;
  for (let i = password.length; i < length; i++) {
    password += getRandomChar(allCharacters);
  }

  // Shuffle the password to randomize the order
  password = password.split('').sort(() => Math.random() - 0.5).join('');

  return password;
}


// 2023-12-15 15:30:000 -> 2023-12-15T15:30:00.000Z
export const convertToLocal = (dateTimeString: string) => {
  if (!dateTimeString) {
    return 0;
  }
  const [datePart, timePart] = dateTimeString.split(' ');
  const [year, month, day] = datePart.split('-');
  const [hour, minute, second] = timePart.split(':');
  const utcDateTime = new Date(Date.UTC(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute), Number(second)));
  const utcString = utcDateTime.toISOString();
  return formatInTimeZone(utcString, Intl.DateTimeFormat().resolvedOptions().timeZone, 'yyyy-MM-dd HH:mm:ss');
};

export const ensurePositiveInteger = (val: string) => {
  const regex = /^[1-9]\d*$/;
  if (regex.test(val)) {
    return val;
  } else if (val === '' || val === '0') {
    return '';
  } else {
    const positiveInteger = val.replace(/[^0-9]/g, '');
    return (positiveInteger || '');
  }
};

export const ensurePositiveIntegerOrZero = (val: string) => {
  const regex = /^0$|^[1-9]\d*$/;
  if (regex.test(val)) {
    return val;
  } else {
    const positiveInteger = val.replace(/^0+/, '').replace(/[^0-9]/g, '');
    return positiveInteger || '0';
  }
};

export const setUserToken = (user: string) => {
  localStorageSetItem('activeUser', user);
};

// export const setLocalVersion = (manVer: string) => {
//   localStorageSetItem('version', manVer);
// };

export const getLocalVersion = () => {
  return localStorageGetItem('version') || '';
};


// @ts-ignore
export function formatUrl(path, ...vars) {
  const matches = path.match(/(:[a-z])\w+/g);
  let newString = path;
  // @ts-ignore
  matches.forEach((v, i) => {
    if (vars[i]) {
      newString = newString.replace(v, vars[i]);
      newString = newString.replace('?', '');
    } else {
      newString = newString.replace(`/${v}`, '');
      newString = newString.replace('?', '');
    }
  });
  return newString;
}

export const belongsToMyClient = (user: User, clientId: string) => {
  return user?.clients.some(client => client.clientId === clientId);
};

export function latLngCalculation(mapPrivateItems: { geolocation: string, id: string }[], mapPublicItems: { geolocation: string, id: string }[] ){
  // check if geolocation is a valid float number
  const publicLatList = mapPublicItems?.map( x =>  parseFloat(x?.geolocation.split('~')[0]));
  const privateLatList = mapPrivateItems?.map( x =>  parseFloat(x?.geolocation.split('~')[0]));
  // Map lng calculations
  const publicLngList = mapPublicItems?.map( x =>  parseFloat(x?.geolocation.split('~')[1]));
  const privateLngList = mapPrivateItems?.map( x =>  parseFloat(x?.geolocation.split('~')[1]));
  let  publicLat = 52.1312863;
  let privateLat = 52.1312863;
  let  publicLng = -0.0544277;
  let privateLng = -0.0544277;
  if (publicLatList && !isNaN(publicLatList[0])  && publicLatList.length > 0) {
    publicLat = publicLatList[0];
  }
  if (privateLatList && !isNaN(privateLatList[0])  && privateLatList.length > 0) {
    privateLat = privateLatList[0];
  }
  if (publicLngList && !isNaN(publicLngList[0])  && publicLngList.length > 0) {
    publicLng = publicLngList[0];
  }
  if (privateLngList && !isNaN(privateLngList[0])  && privateLngList.length > 0) {
    privateLng = privateLngList[0];
  }

  return {
    publicLat,
    privateLat,
    publicLng,
    privateLng };
}

export function parseValidationErrors(rawErrors: any) {
  if (!rawErrors) {
    return [];
  }
  const errors: FormError[] = [];
  Object.keys(rawErrors).forEach(k => {
    errors.push({
      field: k,
      errors: rawErrors[k],
    });
  });
  return errors;
}

export function formatTime(milliseconds: number) {
  if (!milliseconds) {
    return 'N/A';
  }
  if (milliseconds < 0) {
    return '0d 0h 0m 0s';
  }
  const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
  const hours = Math.floor((milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);

  return `${days}d ${hours}h ${minutes}m ${seconds}s`;
}

export const downloadQRCode = (qrCodeRef: RefObject<HTMLDivElement>) => {
  try {
    if (qrCodeRef && qrCodeRef.current) {
      const qrCodeSVG = qrCodeRef.current.querySelector('svg');

      if (qrCodeSVG) {
        const svgString = new XMLSerializer().serializeToString(qrCodeSVG);
        const blob = new Blob([svgString], { type: 'image/svg+xml' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'qrcode.svg';
        link.click();

        window.URL.revokeObjectURL(url);
      }
    }
  } catch (error) {
    console.error('Error downloading QR code:', error);
  }
};

export function generateQRCodeValue(providedEntry: Entry) {
  return formatUrl(c.API_ENDPOINTS.QR_REDIRECT, providedEntry.id);
}

export const getRateColor = (rate?: number | string, col?: string): string | undefined => {
  if (col === 'minRate' || col === 'maxRate' || col === 'actualRate' || col === 'total' || col === undefined){
    if (rate === undefined || rate === null) {
      return '#000000'; // Default color if no rate is provided
    }
    return rate < 0 ? 'green' : rate > 0 ? 'red' : undefined;
  }
};

axios.interceptors.request.use((config) => {
  /** In dev, intercepts request and logs it into console for dev */
  // console.info('✉️ ', config);
  const token = getToken();
  // console.log(token);
  // @ts-ignore
  config.headers.Authorization =  `Bearer ${token}`;
  return config;
}, (error) => {
  console.error('✉️ ', error);
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error?.response?.status === 401) { //AKA Unauthorised
    // window.location.href = c.APP_ROUTES.USER_LOGIN;
    // localStorage.setItem('returnUrl', location.pathname);
    // console.error('You are not authorised for this resource. Please login');
  }
  return Promise.reject(error);
});



function getMediaQuery(size: number) {
  return matchMedia(`(max-width: ${size}px)`);
}

export function useBreakpoint(size: number): boolean {
  const [isMatched, setIsMatched] = useState(() => {
    return getMediaQuery(size).matches;
  });

  useEffect(() => {
    const query = getMediaQuery(size);

    const handler = () => setIsMatched(query.matches);

    query.addEventListener('change', handler);
    handler();

    return () => {
      query.removeEventListener('change', handler);
    };
  }, [size]);

  return isMatched;
}

export const getActiveUserSubscription = (user: any, activeClientId: string | undefined): { hasActiveSubscription: boolean, activeSubscription: any | null, statusSubscription: any | null, activeClientIdSubscription: string | null, isPremium: boolean } => {
  // Check if user and user.clients are defined
  if (!user || !user.clients) {
    return { hasActiveSubscription: false, activeSubscription: null, statusSubscription: null, activeClientIdSubscription: null, isPremium: false };
  }

  // Find the first client that has an activeSubscription
  const activeClient = user.clients.find((client: { activeSubscription: any | null, clientId : string, statusSubscription: any | null }) => (client.clientId === activeClientId));



  // Return an object with the status and the activeSubscription (if any)
  return {
    hasActiveSubscription: !!activeClient?.activeSubscription,
    statusSubscription: activeClient ? activeClient?.statusSubscription : null,
    activeSubscription: activeClient ? activeClient?.activeSubscription : null,
    activeClientIdSubscription: activeClient ? activeClient?.clientId : null,
    isPremium: activeClient ? activeClient?.isPremium : false,
  };
};

export const getActiveGroup = () => {
  const activeUser = useSelector(getLoggedInUser);

  // Check localStorage first
  let groupId = localStorageGetItem('selectedGroupId');
  let groupTitle = localStorageGetItem('selectedGroupTitle');

  // If no localStorage values, check for EME criteria
  if (!groupId || !groupTitle) {
    const emeGroup = activeUser?.groups.find(
      (group) => EME_GROUP_UUIDS.includes(group.groupId) || group.groupTitle === 'EME',
    );

    if (emeGroup) {
      groupId = emeGroup.groupId;
      groupTitle = emeGroup.groupTitle;
    } else {
      // Default to the first available group if no match
      groupId = activeUser?.groups[0]?.groupId || '';
      groupTitle = activeUser?.groups[0]?.groupTitle || '';
    }
  }

  return {
    id: groupId,
    title: groupTitle,
  };
};

export const getActiveClient = () => {
  const activeUser = useSelector(getLoggedInUser);

  // Check localStorage first
  let clientId = localStorageGetItem('selectedClientId');
  let clientTitle = localStorageGetItem('selectedClientTitle');

  // If no localStorage values, check for 'EME' client criteria
  if (!clientId || !clientTitle) {
    const emeClient = activeUser?.clients.find(
      (client) => EME_CLIENT_UUIDS.includes(client.clientId) || client.clientTitle === 'EME-Client',
    );

    if (emeClient) {
      clientId = emeClient.clientId;
      clientTitle = emeClient.clientTitle;
    } else {
      // Default to the first available client if no match
      clientId = activeUser?.clients[0]?.clientId || '';
      clientTitle = activeUser?.clients[0]?.clientTitle || '';
    }
  }

  return {
    id: clientId,
    title: clientTitle,
  };
};

export function removeIds(list: Entry | Marketplace | AuctionEntry | Auction | AuctionBid | Offer | undefined): string[] {
  const excludedList: string[] = [];

  if (!list) return excludedList; // Handle undefined case early

  if ('id' in list) excludedList.push('id');
  if ('groupId' in list) excludedList.push('groupId');
  if ('formId' in list) excludedList.push('formId'); // Check if formId exists only when applicable
  if ('clientId' in list) excludedList.push('clientId');
  if ('assetId' in list) excludedList.push('assetId');
  if ('asset' in list) excludedList.push('asset');
  if ('groupShortcode' in list) excludedList.push('groupShortcode');
  if ('clientShortcode' in list) excludedList.push('clientShortcode');
  if ('winnerUserId' in list) excludedList.push('winnerUserId');
  if ('auctionId' in list) excludedList.push('auctionId');
  if ('auctionEntryId' in list) excludedList.push('auctionEntryId');
  if ('userId' in list) excludedList.push('userId');
  if ('entryId' in list) excludedList.push('entryId');
  if ('transactionId' in list) excludedList.push('transactionId');
  if ('shipmentContactId' in list) excludedList.push('shipmentContactId');
  if ('solutionId' in list) excludedList.push('solutionId');
  if ('createdBy' in list) excludedList.push('createdBy');
  if ('updatedBy' in list) excludedList.push('updatedBy');
  if ('archivedBy' in list) excludedList.push('archivedBy');
  if ('viewId' in list) excludedList.push('viewId');

  return excludedList;
}


export function useMobile() {

  return useBreakpoint(768);
}

export function redirectToBackendIfMatch(clientShortcode: string, groupShortcode: string, entryId: string)  {
  const expectedGroupShortcode = 'mqUFWBvNwawg';
  const expectedClientShortcode = 'lmJ8Pq3VAt4W';
  if (clientShortcode === expectedClientShortcode && groupShortcode === expectedGroupShortcode) {
    // Redirect to backend
    window.location.href = formatUrl(c.API_ENDPOINTS.QR_REDIRECT, entryId);
  }
}


export function exportToCSV(data: any[], filename = 'data.csv') {
  // Extract the headers from the keys of the first object
  const headers = Object.keys(data[0]).join(',');

  // Map each object to a CSV row
  const rows = data.map(obj =>
    Object.values(obj)
      .map(value => `"${value}"`) // Escape commas and quotes
      .join(','),
  );

  // Combine headers and rows
  const csvContent = [headers, ...rows].join('\n');

  // Create a Blob and generate a download link
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const downloadFormStructure = async (formId: string) => {
  try {
    const response = await getFormStructure(formId); // This is already JSON

    // Define headers (Title, Description, and field titles, without Primary Asset)
    const headers = ['Title', 'Description', ...response.fields.map((f: { title: string }) => f.title)];

    // Define UUIDs (formId, formDescription, and field IDs)
    const uuids = [
      response.formId, // Form ID
      response.description, // Form description
      ...response.fields.map((f: { id: string }) => f.id), // Field IDs
    ];

    // Combine titles and UUIDs into a single header row
    const combinedHeaders = headers.map((title, index) => {
      return title === 'Title'
        ? `${title} ~ ${uuids[0]}` // Append formId to Title
        : title === 'Description'
          ? title // Keep Description as it is
          : `${title} ~ ${uuids[index]}`; // Add UUID for other fields
    });

    // Create an empty row for data input (second row)
    const emptyRow = new Array(headers.length).fill('');

    // Prepare data for the sheet (headers + empty row)
    const dataToExport = [combinedHeaders, emptyRow];

    // Convert to worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(dataToExport);

    // Create a new workbook and append the sheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Form Structure');

    // Generate the xlsx file and trigger download
    XLSX.writeFile(workbook, `form_structure_${formId}.xlsx`);

    console.log('XLSX file downloaded successfully.');
  } catch (error) {
    console.error('Error downloading structure:', error);
  }
};