import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getPlan(id: string, dataTableParams?: DataTableParamsModel) {
  const url = `${c.API_ENDPOINTS.PLAN}`;


  return (
    axios({
      url: `${url}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getPlans(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.PLAN,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postPlan(
  title: string,
  description: string,
  details: string,
  clientId: string,
  monthlyFee: number,
) {
  return (
    axios({
      url: c.API_ENDPOINTS.PLAN,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        title,
        description,
        details,
        clientId,
        monthlyFee,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function putPlan(
  id: string,
  title: string,
  description: string,
  details: string,
  clientId: string,
  monthlyFee: number,
) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.PLAN}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        title,
        description,
        details,
        monthlyFee,
        clientId,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archivePlan(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.PLAN}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function unarchivePlan(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.PLAN}/${id}/unarchive`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}
