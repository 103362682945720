import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import c from '../../helpers/constants';
import './EntryRenderer.scss';
import {
  fetchActiveEntry,
  fetchEntryByShortcodeGuest,
} from '../../actions/Entry';
import { BoxArrowUpRight } from 'react-bootstrap-icons';
import QRCode from 'react-qr-code';

import { fetchingEntriesFailed, getActiveEntry, isFetchingEntries } from '../../selectors/Entry';

import { fetchEntryFields } from '../../actions/EntryField';
import { getEntryFields, isFetchingEntryFields } from '../../selectors/EntryField';
import { EntryField } from '../../types/EntryField';
import { fetchFieldPropertiesByEntry } from '../../actions/FieldProperty';
import { getFieldProperties } from '../../selectors/FieldProperty';
import CarouselPreview from '../../components/CarouselPreview';
import MaterialMap from '../../components/MaterialMap';
import { getCategories } from '../../selectors/Category';
import { fetchCategories } from '../../actions/Category';
import { Category } from '../../types/Category';
import Button from '../../components/Button';
import { formatUrl, getToken } from '../../helpers/functions';
import {
  fetchEntryHistoryByShortcodes,
  fetchEntryTimelineByShortcodes,
} from '../../actions/EntryHistory';
import { fetchEntryFieldHistoryByShortcodes } from '../../actions/EntryFieldHistory';
import { getEntryEdges, isFetchingEntryEdges } from '../../selectors/EntryEdge';
import { fetchEntryEdges } from '../../actions/EntryEdge';
import ForceGraph from '../../components/ForceGraph';
import Timeline from '../../components/Timeline';
import noImage from '../../assets/images/no-preview.png';
import { getAssets } from '../../selectors/Asset';
import { getActiveConfiguration } from '../../selectors/Configuration';
import Card from '../../components/Card/Card';
import assetGallery from '../../components/AssetGallery/AssetGallery';
import Tabs from '../../components/Tabs/Tabs';
import { fetchActiveEntryTagsByEntryId } from '../../actions/EntryTag';
import { getEntryTags } from '../../selectors/EntryTag';
import Pill from '../../components/Pill/Pill';
import ImpactOverview from '../MatchOverview/ImpactOverview';
import { getEntryTimeline } from '../../selectors/EntryHistory';
import { downloadQRCode, generateQRCodeValue } from '../../helpers/functions';
import Lightbox from '../../components/Lightbox/Lightbox';
import DataTable from '../../components/DataTable/DataTable';
import useShortcodes from '../../hooks/ShortcodeHook';
import LandingPageView from '../../components/LandingPageView';
import { getPopularSearches } from '../../selectors/PopularSearch';
import { DataTableParamsModel } from '../../types/Common';
import { fetchPopularSearches } from '../../actions/PopularSearch';
import LegalDisclaimer from '../../components/EntryLegalDisclaimer';

function ViewRenderer() {
  const dispatch = useDispatch();
  // @ts-ignore
  const {
    isUsingShortcodes,
    clientShortcode,
    groupShortcode,
  } = useShortcodes(c.APP_ROUTES.ENTRY_RENDERER_SHORTCODE, c.APP_ROUTES.ENTRY_RENDERER_PUBLIC);

  let matchUpdate = !isUsingShortcodes ? useRouteMatch(c.APP_ROUTES.ENTRY_RENDERER_PUBLIC) : useRouteMatch(c.APP_ROUTES.ENTRY_RENDERER_SHORTCODE);

  const activeEntry = useSelector(getActiveEntry);
  const categories = useSelector(getCategories);
  const entryFields = useSelector(getEntryFields);
  const entryEdges = useSelector(getEntryEdges);
  const history = useHistory();
  const fieldProperties = useSelector(getFieldProperties);
  const allAssets = useSelector(getAssets);
  const isFetching = useSelector(isFetchingEntryFields);
  const isFetchingEntry = useSelector(isFetchingEntries);
  const isLoadingEntryEdges = useSelector(isFetchingEntryEdges);
  const entryFailToLoad = useSelector(fetchingEntriesFailed);
  const activeConfig = useSelector(getActiveConfiguration);
  const entryTags = useSelector(getEntryTags);
  const popularSearches = useSelector(getPopularSearches);
  const entryTimeline = useSelector(getEntryTimeline);
  const qrCodeRef = useRef<HTMLDivElement>(null);
  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const sourceParam = urlParams.get('source');
  const isAuction = sourceParam === 'auction';

  let activeCategories: Category[] = [];
  // @ts-ignore
  entryFields?.data?.forEach(fp => {
    const found = activeCategories.find(ccc => ccc.id === fp.categoryId);
    if (!found) {
      const cat = categories?.data.find(cc => cc.id === fp.categoryId);
      if (cat) {
        activeCategories.push(cat);
      }
    }
  });

  activeCategories = activeCategories.sort((a, b) => a.order < b.order ? -1 : 1);

  const onLoginToView = () => {
    history.push(c.APP_ROUTES.USER_LOGIN);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // @ts-ignore
  const [entryId, setEntryId] = useState(matchUpdate?.params?.id);

  const goToSignIn = () => {
    history.push(c.APP_ROUTES.USER_LOGIN);
  };

  useEffect(() => {
    if (entryId) {
      dispatch(fetchActiveEntry(entryId, true));
    }
  }, [dispatch]);

  useEffect(() => {
    const dataTableParams: DataTableParamsModel = {
      colKey: 'popularity',
      sortDirection: 'desc',
      paging: '10',
    };
    dispatch(fetchPopularSearches(dataTableParams));
  }, [dispatch]);

  useEffect(() => {
    // @ts-ignore
    if (matchUpdate?.params?.id !== entryId) {
      // @ts-ignore
      setEntryId(matchUpdate?.params?.id);
      if (isUsingShortcodes && groupShortcode && clientShortcode){
        // @ts-ignore
        dispatch(fetchEntryByShortcodeGuest(matchUpdate?.params?.id, groupShortcode, clientShortcode));
      } else {
        // @ts-ignore
        dispatch(fetchActiveEntry(matchUpdate?.params?.id, true));
      }
    }
    // @ts-ignore
  }, [matchUpdate?.params?.id]);

  useEffect(() => {
    if (entryId && activeEntry && activeEntry?.groupShortcode && activeEntry?.clientShortcode) {
      dispatch(fetchCategories({ fetchParams: { groupShortcode: activeEntry?.groupShortcode,
        clientShortcode: activeEntry?.clientShortcode } }));
      dispatch(fetchEntryFields({ searchField: 'entryId', searchWord: entryId,
        fetchParams: { 'groupShortcode': activeEntry?.groupShortcode, 'clientShortcode': activeEntry?.clientShortcode } }));
      dispatch(fetchFieldPropertiesByEntry(entryId));
      dispatch(fetchEntryHistoryByShortcodes(activeEntry?.groupShortcode, activeEntry?.clientShortcode, entryId));
      dispatch(fetchEntryTimelineByShortcodes(activeEntry?.groupShortcode, activeEntry?.clientShortcode, entryId));
      dispatch(fetchEntryFieldHistoryByShortcodes(activeEntry?.groupShortcode, activeEntry?.clientShortcode, entryId));
      dispatch(fetchActiveEntryTagsByEntryId(entryId));
    }
  }, [activeEntry]);


  const filterAssets = (assetId: string) => {
    return allAssets?.data.find(item => item.id === assetId);
  };

  const renderMapField = (entryField: EntryField) => {
    // const fieldProperty = fieldProperties?.data.find(fp => fp.fieldId === entryField?.fieldId && fp.fieldTypeOptionKey === 'value' && fp.categoryId === categoryId);
    const parts = entryField?.value?.split('~');
    return (
            <>
                <div className='entry-category__title'>{entryField?.fieldTitle}:</div>
                <div className='entry-category__container'>
                    <div className="row" key={entryField.id}>
                        {/* @ts-ignore */}
                        <MaterialMap isEntryRenderer={true} address={parts[3]} zoom={14} lat={parseFloat(parts[0])} lng={parseFloat(parts[1])}/>
                    </div>
                </div>
            </>);
  };

  const renderCarouselField = (entryField: EntryField) => {
    const urls = entryField?.value?.split(',').map(u => `${c.API_ENDPOINTS.ASSET_FILE}/${u}`);
    return (urls.length > 0 && <>
            <div className="entry-category__title">{entryField?.fieldTitle}:</div>
            <div className="entry-category__container">
                <CarouselPreview imageUrls={urls}/>
            </div>
        </>);
  };

  const renderTextField = (entryField: EntryField) => {
    const fieldProperty = fieldProperties?.data.find(fp => fp.fieldId === entryField?.fieldId && fp.fieldTypeOptionKey === 'isPrivate');
    const isPrivate = fieldProperty?.value === 'true';

    return (
            <div key={entryField.id} className='col-md-6'>
                <div className="entry-category__label">
                    <div>{entryField?.fieldTitle}:</div>
                </div>
                <div className="entry-category__sub-label">
                    {!getToken() && isPrivate ? (
                        <Button title="Login To View" onClick={onLoginToView} />
                    ) : (
                        <div>{entryField?.value}</div>
                    )}
                </div>
            </div>
    );
  };




  const renderFileField = (entryField: EntryField) => {
    // const fieldProperty = fieldProperties?.data.find(fp => fp.fieldId === entryField?.fieldId && fp.fieldTypeOptionKey === 'value' && fp.categoryId === categoryId);
    if (entryField.value != '') {
      return (<div className="row my-3" key={entryField.id}>

                <h5>{entryField?.fieldTitle}:</h5>

                <div className="col">
                    {entryField?.value.split(',').map((im: string) => entryField?.value.length > 0 &&
                        <>
                            <iframe src={`${c.API_ENDPOINTS.ASSET_FILE}/${im}`} className="img-preview img-thumbnail"
                                    onError={(event) => event.currentTarget.src = noImage}/>
                            <p className={'mx-4 file-pill'}>{filterAssets(im)?.title} <span
                                className="badge rounded-pill bg-info text-dark">.{filterAssets(im)?.extension}</span> <a
                                target="_blank"
                                href={`${c.API_ENDPOINTS.ASSET_FILE}/${im}`}
                                rel="noreferrer">
                                <BoxArrowUpRight/>
                            </a>
                            </p>
                        </>,
                    )}
                </div>
            </div>);
    }
  };

  const renderEntry = (categoryId: string) => {
    const decide = (e: EntryField) => {
      if (categoryId !== e.categoryId) {
        return;
      }
      switch (e.fieldType) {
        case 'map':
          return null;
        case 'file':
          return renderFileField(e);
        case 'decompositionImage':
          return renderFileField(e);
        case 'dropdown':
          return renderTextField(e);
        case 'text':
          return renderTextField(e);
        case 'textArea':
          return renderTextField(e);
        case 'epc':
          return renderTextField(e);
        case 'sku':
          return renderTextField(e);
        case 'requiredEnergyInJoule':
          return renderTextField(e);
        case 'carousel':
          return null;
        default:
          return <div>Unknown Field</div>;
      }
    };
    return (
            <>
                {entryFields?.data?.sort((a, b) => a.order < b.order ? -1 : 1)?.map(e => decide(e))}
            </>
    );
  };

  const renderMedia = (categoryId: string) => {
    const decide = (e: EntryField) => {
      if (categoryId !== e.categoryId) {
        return;
      }
      switch (e.fieldType) {
        case 'map':
          return null;
        case 'file':
          return null;
        case 'decompositionImage':
          return null;
        case 'dropdown':
          return null;
        case 'text':
          return null;
        case 'epc':
          return null;
        case 'sku':
          return null;
        case 'requiredEnergyInJoule':
          return null;
        case 'carousel':
          return renderCarouselField(e);
        default:
          return <div>Unknown Field</div>;
      }
    };
    return (
            <div>
                {entryFields?.data?.sort((a, b) => a.order < b.order ? -1 : 1)?.map(e => decide(e))}
            </div>
    );
  };

  const renderLocation = (categoryId: string) => {
    const decide = (e: EntryField) => {
      if (categoryId !== e.categoryId) {
        return;
      }
      switch (e.fieldType) {
        case 'map':
          return renderMapField(e);
        case 'file':
          return null;
        case 'decompositionImage':
          return null;
        case 'dropdown':
          return null;
        case 'text':
          return null;
        case 'epc':
          return null;
        case 'sku':
          return null;
        case 'requiredEnergyInJoule':
          return null;
        case 'carousel':
          return null;
        default:
          return <div>Unknown Field</div>;
      }
    };
    return (
            <div>
                {entryFields?.data?.sort((a, b) => a.order < b.order ? -1 : 1)?.map(e => decide(e))}
            </div>
    );
  };

  const renderCategories = () => {
    const tabs = activeCategories?.map(category => ({
      label: category.title,
      content: (
                <div className="row justify-content-between align-items-start">
                    {renderEntry(category.id)
                    }
                </div>
      ),
    })) || [];

    return <Tabs tabs={tabs} />;
  };

  const renderLarge = () => {
    if (activeCategories.length > 0) {
      return (
                <>
                    {activeCategories?.map(category => (
                        <div className="col-12 mb-3" key={category.id}>
                            {/* @ts-ignore */}
                            {renderMedia(category.id)}
                        </div>
                    ))}
                    <div className="my-3"/>
                </>
      );
    } else {
      return null;
    }
  };

  const renderLocationInForm = () => {
    return (activeCategories?.map(category => (
            <div className="col-12 mb-3" key={category.id}>
                {/* @ts-ignore */}
                {renderLocation(category.id)}
            </div>
    )));
  };

  const onNavigate = (theEntryId: string) => {
    {
      history.push(formatUrl(c.APP_ROUTES.ENTRY_RENDERER_PUBLIC, theEntryId));
    }
  };

  const renderChildEdges = () => {
    const config = {
      columns: [
        { key: 'toEntryTitle', label: 'Child Title', mobileFriendly: true },
        { key: 'toEntryDescription', label: 'Child Description', mobileFriendly: true },
      ],
      actions: [],
      hideButton: true,
      pageSizes : [6, 10, 20, 30],
    };

    return (
            <div className="row">
                <DataTable
                    paging={entryEdges?.paging}
                    data={entryEdges?.data || []}
                    config={config}
                    dependencies={[entryId, 1]}
                    isLoading={isLoadingEntryEdges}
                    fetchFunction={fetchEntryEdges}
                    fetchParams={{ 'groupShortcode': activeEntry?.groupShortcode, 'clientShortcode': activeEntry?.clientShortcode }}
                    baseSearch={{ 'fromEntryId':entryId, 'toEntryStatus': ['Public', 'Reused'] }}
                    isTable={false}
                    lineItemTemplate={(row: any) => (
                        <div key={row.id} className="col-md-4">
                            <Card
                                onClick={() => onNavigate(row.toEntryId)}
                                cardId={row.id}
                                title={row.toEntryTitle}
                                description={row.toEntryDescription}
                                imageUrl={`${c.API_ENDPOINTS.ASSET_FILE}/${row.toEntryAssetId}`}
                            />
                        </div>
                    )}
                />
            </div>
    );
  };

  const renderParentEdges = () => {
    const config = {
      columns: [
        { key: 'fromEntryTitle', label: 'Parent Title', mobileFriendly: true },
        { key: 'fromEntryDescription', label: 'Parent Description', mobileFriendly: true },
      ],
      actions: [],
      hideButton: true,
      pageSizes : [6, 10, 20, 30],
    };

    return (
            <div className="row">
                <DataTable
                    paging={entryEdges?.paging}
                    data={entryEdges?.data || []}
                    dependencies={[entryId, 2]}
                    config={config}
                    isLoading={isLoadingEntryEdges}
                    fetchFunction={fetchEntryEdges}
                    fetchParams={{ 'groupShortcode': activeEntry?.groupShortcode, 'clientShortcode': activeEntry?.clientShortcode }}
                    baseSearch={{ 'toEntryId': entryId, 'fromEntryStatus': ['Public', 'Reused'] }}
                    isTable={false}
                    lineItemTemplate={(row: any) => (
                        <div key={row.id} className="col-md-4 h-100">
                            <Card
                                onClick={() => onNavigate(row.fromEntryId)}
                                cardId={row.id}
                                title={row.fromEntryTitle}
                                description={row.fromEntryDescription}
                                imageUrl={`${c.API_ENDPOINTS.ASSET_FILE}/${row.fromEntryAssetId}`}
                            />
                        </div>
                    )}
                />
            </div>
    );
  };


  const hasAssets = () => {
    const assets = entryFields?.data?.filter(ef => ef.fieldType === 'carousel').map(a => a.value.split(',').map(u=> `${c.API_ENDPOINTS.ASSET_FILE}/${u}`));
    return (assets && assets?.length > 0);
  };

  const assetsPre = entryFields?.data?.filter(ef => ef.fieldType === 'carousel' && ef.value != '' && ef.entryId === activeEntry?.id).map(a => a.value.split(',').map(u=> `${c.API_ENDPOINTS.ASSET_FILE}/${u}`));
  const assets = assetsPre?.flat();

  if (assets){
    assets?.unshift(`${c.API_ENDPOINTS.ASSET_FILE}/${activeEntry?.assetId}`);
  }

  // Items for the force graph
  const childEdges = entryEdges?.data?.filter(ee => ee.fromEntryId === activeEntry?.id);
  const parentEdges = entryEdges?.data?.filter(ee => ee.toEntryId === activeEntry?.id);
  const isDataIncomplete = (
    activeEntry === null ||
        assets === undefined ||
        entryFields === null ||
        isFetching ||
        isFetchingEntry
  );


  const renderRelationships = () => {
    const tabs = [
      {
        label: 'Parent Connections',
        icon: assetGallery.connectionsImg,
        content: renderParentEdges(),
      },
      {
        label: 'Child Connections',
        icon: assetGallery.connectionsImg,
        content: renderChildEdges(),
      },
    ];

    return (
            <div className="row align-items-start">
                <div className="col-xl-4 col-lg-12">
                    <ForceGraph
                        parentItems={parentEdges}
                        childItems={childEdges}
                        activeEntry={activeEntry}/>
                </div>
                <div className="col-xl-8 col-lg-12 offwhite-container">
                    <Tabs tabs={tabs} />
                </div>
            </div>
    );
  };

  useEffect(() => {
    if (entryFailToLoad){
      history.push(c.APP_ROUTES.ERROR_404);
    }
  }, [entryFailToLoad]);


  const toFullSizeAsset = (assetId: string | undefined) => {
    const fullSizeAssetURL = `${c.API_ENDPOINTS.ASSET_FILE}/${assetId}`;
    setSelectedImage(fullSizeAssetURL);
    setShowLightbox(true);
  };


  return (
        <LandingPageView
            isLoading={isDataIncomplete}
            popularSearches={popularSearches}
            topHidden={isAuction}
        >
            <div className="mx-5">
                <h2 className="my-3">{activeEntry?.title}</h2>
                {showLightbox && (
                    <Lightbox imageUrl={selectedImage} onClose={() => setShowLightbox(false)} />
                )}
                <div className="entry-category__title">Main Information</div>
                <div className="entry-top">
                    <div className="row">
                        <div className='col-xl-6 col-md-12'>
                            <div className="entry-top__position">
                                <div className="entry-top__frame">
                                    <img className="entry-top__image" src={`${c.API_ENDPOINTS.ASSET_FILE}/${activeEntry?.assetId}`} alt={'primary-asset'} onError={(event) => event.currentTarget.src = noImage} />
                                    <img className="entry-top__image__full-size" src={assetGallery.zoomImg} alt='fullsize' onClick={() => toFullSizeAsset(activeEntry?.assetId)} onError={(event) => event.currentTarget.src = noImage}/>
                                    {activeEntry && activeConfig?.enableQR == true && (
                                        <div className="entry-top__qr-code" ref={qrCodeRef}>
                                            <QRCode
                                                value={generateQRCodeValue(activeEntry)}
                                            />
                                            <div onClick={() => downloadQRCode(qrCodeRef)} className='entry-top__qr-code__download'>
                                                <img src={assetGallery.downloadImg} alt='qr-download'/>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12 d-flex flex-column justify-content-center mt-4">
                            <div className="entry-category__label">
                                Title
                            </div>
                            <div className="entry-category__sub-label">
                                {activeEntry?.title}
                            </div>

                            <div className="entry-category__label">
                                Description
                            </div>
                            <div className="entry-category__sub-label">
                                {activeEntry?.description}
                            </div>
                            {entryTags && <div>
                                <div className="entry-category__label">
                                    Tags
                                </div>
                                {entryTags?.data.map((tag, index) => (
                                    <Pill
                                        key={index}
                                        text={tag.tagValue}
                                        type="white"
                                        icon="tag"
                                    />
                                ))}
                            </div>}
                        </div>
                    </div>
                </div>
                {hasAssets() &&
                    renderLarge()
                }
                <div className='mb-3'>
                    {renderCategories()}
                </div>
                {(entryTimeline && entryTimeline?.data.length > 0) && <div className="col-12 d-none">
                    <div className='entry-category__title'>Tracking & Tracing</div>
                    <div className='entry-category__container'>
                        <Timeline entryId={activeEntry?.id} clientShortCode={activeEntry?.clientShortcode} groupShortCode={activeEntry?.groupShortcode} />
                    </div>
                </div>}
                <div className="col-12">
                    <div className='entry-category__title'>Connections</div>
                    <div className="entry-category__container">
                        {activeEntry && renderRelationships()}
                    </div>
                </div>
                <div className="my-3"/>
                {!isAuction && <div className="col-12">
                    <div className="entry-category__title">Solutions</div>
                    <div className="entry-category__container">
                        <div className="position-relative">
                            <div className="blur-block">
                                <ImpactOverview/>
                            </div>
                            <div className="sign-in-block">
                                <h2 className="text-center">Sign-In Required to View</h2>
                                <Button title={'Sign In'} onClick={goToSignIn}/>
                            </div>
                        </div>
                    </div>
                </div>}
                {renderLocationInForm()}
                <LegalDisclaimer />
            </div>
        </LandingPageView>
  );
}

export default ViewRenderer;
