import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createTransaction, fetchActiveTransaction, updateTransaction } from '../../../actions/Transaction';
import {
  getActiveTransaction,
  isPostingTransaction,
  postingTransactionError,
  postingTransactionFailed,
  postingTransactionValidationErrors,
  isFetchingTransactions,
} from '../../../selectors/Transaction';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import CheckBox from '../../../components/CheckBox';

const TransactionForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeTransaction = useSelector(getActiveTransaction);
  const isPosting = useSelector(isPostingTransaction);
  const isFetching = useSelector(isFetchingTransactions);
  const postingFailed = useSelector(postingTransactionFailed);
  const postingError = useSelector(postingTransactionError);
  const postingValidationErrors = useSelector(postingTransactionValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.TRANSACTION_UPDATE);

  const formConfig = [
    { field: 'id', validators: [] },
    { field: 'oppTransactionId', validators: [] },
    { field: 'status', validators: [] },
    { field: 'merchant_uid', validators: [] },
    { field: 'profile_uid', validators: [] },
    { field: 'amount', validators: [isRequired] },
    { field: 'currency', validators: [isRequired] },
    { field: 'payment_method', validators: [] },
    { field: 'payment_flow', validators: [] },
    { field: 'redirect_url', validators: [] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [oppTransactionId, setOppTransactionId] = useState('');
  const [status, setStatus] = useState('');
  const [merchantUid, setMerchantUid] = useState('');
  const [profileUid, setProfileUid] = useState('');
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentFlow, setPaymentFlow] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [expirationSepaFlag, setExpirationSepaFlag] = useState(false);
  const [additionalSepaDays, setAdditionalSepaDays] = useState(30);

  useEffect(() => {
    if (editMode) {
      dispatch(fetchActiveTransaction(activeId));
    }
  }, [dispatch, editMode, activeId]);

  useEffect(() => {
    if (activeTransaction && editMode) {
      setOppTransactionId(activeTransaction?.oppTransactionId);
      setStatus(activeTransaction?.status);
      setMerchantUid(activeTransaction?.merchant_uid);
      setProfileUid(activeTransaction?.profile_uid);
      setAmount(activeTransaction?.amount);
      setCurrency(activeTransaction?.currency);
      setPaymentMethod(activeTransaction?.payment_method);
      setPaymentFlow(activeTransaction?.payment_flow);
      setRedirectUrl(activeTransaction?.redirect_url);
      setExpirationSepaFlag(activeTransaction?.expirationSepaFlag);
      setAdditionalSepaDays(activeTransaction?.additionalSepaDays);
    }
  }, [activeTransaction, editMode]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.TRANSACTION);
    }
  }, [isPosting, postingFailed, hasSubmitted, history]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createTransaction(oppTransactionId, status, merchantUid, profileUid, amount, currency, paymentMethod, paymentFlow, redirectUrl, expirationSepaFlag, additionalSepaDays));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateTransaction(activeId, oppTransactionId, status, merchantUid, profileUid, amount, currency, paymentMethod, paymentFlow, redirectUrl, expirationSepaFlag, additionalSepaDays));
    }
  };

  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} Transaction</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <Input
                    v={v}
                    err={err}
                    name="oppTransactionId"
                    id="oppTransactionId"
                    label="Opp Transaction ID"
                    value={oppTransactionId}
                    onChange={setOppTransactionId}
                    disabled={isPosting || isFetching}
                    placeholder="Insert Opp Transaction ID"
                />
                <Input
                    v={v}
                    err={err}
                    name="status"
                    id="status"
                    label="Status"
                    value={status}
                    onChange={setStatus}
                    disabled={isPosting || isFetching}
                    placeholder="Insert Status"
                />
                <Input
                    v={v}
                    err={err}
                    name="merchant_uid"
                    id="merchant_uid"
                    label="Merchant UID"
                    value={merchantUid}
                    onChange={setMerchantUid}
                    disabled={isPosting || isFetching}
                    placeholder="Insert Merchant UID"
                />
                <Input
                    v={v}
                    err={err}
                    name="profile_uid"
                    id="profile_uid"
                    label="Profile UID"
                    value={profileUid}
                    onChange={setProfileUid}
                    disabled={isPosting || isFetching}
                    placeholder="Insert Profile UID"
                />
                <Input
                    v={v}
                    err={err}
                    name="amount"
                    id="amount"
                    label="Amount"
                    type="number"
                    value={amount}
                    onChange={setAmount}
                    disabled={isPosting || isFetching}
                    placeholder="Insert Amount"
                />
                <Input
                    v={v}
                    err={err}
                    name="currency"
                    id="currency"
                    label="Currency"
                    value={currency}
                    onChange={setCurrency}
                    disabled={isPosting || isFetching}
                    placeholder="Insert Currency"
                />
                <Input
                    v={v}
                    err={err}
                    name="payment_method"
                    id="payment_method"
                    label="Payment Method"
                    value={paymentMethod}
                    onChange={setPaymentMethod}
                    disabled={isPosting || isFetching}
                    placeholder="Insert Payment Method"
                />
                <Input
                    v={v}
                    err={err}
                    name="payment_flow"
                    id="payment_flow"
                    label="Payment Flow"
                    value={paymentFlow}
                    onChange={setPaymentFlow}
                    disabled={isPosting || isFetching}
                    placeholder="Insert Payment Flow"
                />
                <Input
                    v={v}
                    err={err}
                    type="number"
                    name="additionalSepaDays"
                    id="additionalSepaDays"
                    label="Additional Sepa Days"
                    value={additionalSepaDays}
                    onChange={setAdditionalSepaDays}
                    disabled={isPosting || isFetching}
                    placeholder="Insert Additional Sepa Days"
                />
                <CheckBox label="Expiration Sepa Flag" checked={expirationSepaFlag} onChange={setExpirationSepaFlag}/>
                <Input
                    v={v}
                    err={err}
                    name="redirect_url"
                    id="redirect_url"
                    label="Redirect URL"
                    value={redirectUrl}
                    onChange={setRedirectUrl}
                    disabled={isPosting || isFetching}
                    placeholder="Insert Redirect URL"
                />
                <div className="row">
                    <div className="col-md-4">
                        <Button
                            disabled={!isFormValid()}
                            isLoading={isPosting || isFetching}
                            onClick={() => (editMode ? onSave() : onSubmit())}
                            title={editMode ? 'SAVE' : 'CREATE'}
                        />
                    </div>
                </div>
            </form>
        </View>
  );
};

export default TransactionForm;
