import React, { useEffect } from 'react';
import './Lightbox.scss';
import noImage from '../../assets/images/no-preview.png';

interface LightboxProps {
  imageUrl: string;
  onClose: () => void;
}

const Lightbox: React.FC<LightboxProps> = ({ imageUrl, onClose }: LightboxProps) => {
  useEffect(() => {
    // Disable scrolling on the background when the lightbox is open
    document.body.style.overflow = 'hidden';
    // Re-enable scrolling when the lightbox is closed
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  return (
        <div className="lightbox-overlay" onClick={onClose}>
            <div className="lightbox-content">
                <img src={imageUrl} alt="Full-size asset" onError={(event) => event.currentTarget.src = noImage} />
            </div>
        </div>
  );
};

export default Lightbox;
