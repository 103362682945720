import React from 'react';
import './ProposalInfo.scss';

interface TitleDescriptionProps {
  title: string;
  description?: string | string[];
}

interface ProposalInfoProps {
  mainTitle: string;
  items: TitleDescriptionProps[];
}


const ProposalInfo: React.FC<ProposalInfoProps> = ({ mainTitle, items }) => {

  return (
        <div className="my-4" key={mainTitle}>
            <div className="proposal-info__title">{mainTitle}</div>
            <div className="proposal-info__line"></div>
            { items && items.length > 0 ?
            <div className="row mb-3">
                {items.map((item, index) => (
                    <>
                        {item.description && <div key={index} className="col-md-3">
                            <div className="col-md-12 proposal-info__subtitle">
                                {item.title}
                            </div>
                            <div className="col-md-12 proposal-info__description">
                                {Array.isArray(item.description)
                                  ? item.description.join(', ')
                                  : item.description}
                            </div>
                        </div>}
                    </>
                ))}
            </div> : <div className="col-md-12 ">{ mainTitle === 'Solution Provider Information' ? 'Solution is not defined yet' : 'Shipment is not defined yet' }</div>}
        </div>
  );
};

export default ProposalInfo;
