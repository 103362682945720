import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import noImage from '../../assets/images/no-preview.png';
import './CarouselPreview.scss';
import assetGallery from '../AssetGallery/AssetGallery';
import Lightbox from '../Lightbox/Lightbox';

interface Props {
  imageUrls: string[];
  imagesToShow?: number;
}

const CarouselPreview: React.FC<Props> = ({ imageUrls = [], imagesToShow }) => {
  const slider1Ref = useRef<Slider>(null);
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const imageNumber = imageUrls?.length >= 3 ? 3 : imageUrls?.length;
  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const settingsMain = {
    centerMode: true,
    dots: true,
    centerPadding: imagesToShow === 1 ? '0px' : imageNumber === 1 ? '0px' : '60px',
    slidesToShow: imagesToShow ?? imageNumber,
    beforeChange: (current: number, next: number) => {
      setActiveSlide(next);
    },
    responsive: [
      {
        breakpoint: 800,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: imagesToShow ? imagesToShow : imageNumber ? imageNumber : 3,
        },
      },
      {
        breakpoint: 650,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 1,
        },
      },
    ],
  };

  const toFullSizeAsset = (fullSizeAssetURL: any | undefined) => {
    setSelectedImage(fullSizeAssetURL);
    setShowLightbox(true);
  };

  return (<>
    {showLightbox && (
        <Lightbox imageUrl={selectedImage} onClose={() => setShowLightbox(false)} />
    )}
        <div className="carousel">
          <Slider ref={slider1Ref} {...settingsMain}>
            {imageUrls.map((imgUrl, i) => (
                <div key={`${imgUrl}-${i}`}>
                  <div className="carousel__img">
                    <img
                        className="carousel__img__inner"
                        src={imgUrl}
                        alt="carousel-img"
                        onError={(event) => (event.currentTarget.src = noImage)}
                    />
                  </div>
                  {activeSlide === i && (
                      <img
                          className="carousel__full-size"
                          src={assetGallery.zoomImg}
                          alt="fullsize"
                          onClick={() => toFullSizeAsset(imgUrl)}
                      />
                  )}
                </div>
            ))}
          </Slider>
        </div>
    </>
  );
};

export default CarouselPreview;
