import axios from 'axios';
import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';
import { PublicTypes } from '../types/PublicTypes';

export function getAuctionBid(id: string) {
  return axios({
    url: `${c.API_ENDPOINTS.AUCTION_BID}/${id}`,
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    },
  })
    .then((response) => response?.data)
    .catch((error) => error.response?.data);
}

export function getAuctionBids(
  dataTableParams?: DataTableParamsModel,
  groupShortcode?: string,
  clientShortcode?: string,
) {
  const params: any = {};
  const baseUrl = `${c.API_ENDPOINTS.AUCTION_BID}`;

  const url = groupShortcode && clientShortcode
    ? `${baseUrl}-shortcode`
    : baseUrl;

  if (dataTableParams?.searchField === 'auctionId' && dataTableParams?.searchWord) {
    params.providedUuid = dataTableParams.searchWord;
    params.publicType = PublicTypes.AUCTION_BID;
  }

  if (dataTableParams) {
    params.dataTableParams = dataTableParams;
  }

  return axios({
    url,
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    },
    params,
  })
    .then((response) => response?.data)
    .catch((error) => error.response?.data);
}



export function archiveAuctionBid(id: string) {
  return axios({
    url: `${c.API_ENDPOINTS.AUCTION_BID}/${id}`,
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
    },
  })
    .then((response) => response?.data)
    .catch((error) => error.response?.data);
}

export function postAuctionBid(auctionId: string, entryId: string, userId: string, amount: string) {
  return axios({
    url: c.API_ENDPOINTS.AUCTION_BID,
    method: 'POST',
    headers: {
      'Accept': 'application/json',
    },
    data: {
      auctionId,
      entryId,
      userId,
      amount,
    },
  })
    .then((response) => response?.data)
    .catch((error) => error.response?.data);
}

export function placePostAuctionBid(auctionEntryId: string, userId: string, amount: string) {
  return axios({
    url: c.API_ENDPOINTS.AUCTION_BID_PLACE,
    method: 'POST',
    headers: {
      'Accept': 'application/json',
    },
    data: {
      auctionEntryId,
      userId,
      amount,
    },
  })
    .then((response) => response?.data)
    .catch((error) => error.response?.data);
}

export function putAuctionBid(
  activeId:string,
  auctionId:string,
  entryId:string,
  userId:string,
  amount:string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.AUCTION_BID}/${activeId}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        activeId,
        auctionId,
        entryId,
        userId,
        amount,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}
