import React from 'react';
import './Button.scss';

type Props = {
  title: string;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  isReview?: boolean;
  variant?: 'black' | 'red' | 'green' | 'yellow' | 'default';
};

const Button = (props: Props) => {
  const { title, onClick, isLoading = false, disabled, isReview = false, variant = 'default' } = props;

  const onClicked = () => {
    if (onClick && typeof onClick === 'function' && !isLoading && !disabled) {
      onClick();
    }
  };

  return (
      <div
          className={`e-button ${variant} ${disabled && 'opaque'} ${isReview ? 'review' : ''}`}
          onClick={onClicked}
      >
        {!isLoading && <span className="e-button__text">{title}</span>}

        {isLoading && (
            <div
                className={`spinner-border spinner-border-sm spinner-color ${isReview ? 'spinner-orange' : ''}`}
                role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
        )}
      </div>
  );
};

export default Button;
