import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_CONFIGURATIONS_REQUEST,
  FETCH_CONFIGURATIONS_RESPONSE,
  FETCH_CONFIGURATIONS_FAILURE,
  FETCH_CONFIGURATIONS_DATA_REQUEST,
  FETCH_CONFIGURATIONS_DATA_RESPONSE,
  FETCH_CONFIGURATIONS_DATA_FAILURE,
  FETCH_CONFIGURATION_REQUEST,
  FETCH_CONFIGURATION_RESPONSE,
  FETCH_CONFIGURATION_FAILURE,
  POST_CONFIGURATION_FAILURE,
  POST_CONFIGURATION_REQUEST,
  POST_CONFIGURATION_RESPONSE,
  ConfigurationActionTypes, ConfigurationDataModel, Configuration,
} from '../types/Configuration';

import { RootState } from '../store/configureStore';

import {
  getConfiguration,
  getConfigurations,
  postConfiguration,
  putConfiguration,
  archiveConfiguration,
  unarchiveConfiguration,
  getConfigurationByShortcodes,
  getConfigurationByLogin, getDefaultConfiguration, getConfigurationByClientId,
} from '../api/Configuration';
import { DataTableParamsModel } from '../types/Common';


export function fetchConfigurationsRequest(): ConfigurationActionTypes {
  return {
    type: FETCH_CONFIGURATIONS_REQUEST,
    payload: null,
  };
}

export function fetchConfigurationsResponse(
  configurations: ConfigurationDataModel,
): ConfigurationActionTypes {
  return {
    type: FETCH_CONFIGURATIONS_RESPONSE,
    payload: configurations,
  };
}

export function fetchConfigurationsFailure(): ConfigurationActionTypes {
  return {
    type: FETCH_CONFIGURATIONS_FAILURE,
    payload: null,
  };
}

export function fetchConfigurationsDataRequest(): ConfigurationActionTypes {
  return {
    type: FETCH_CONFIGURATIONS_DATA_REQUEST,
    payload: null,
  };
}

export function fetchConfigurationsDataResponse(
  configurationsData: ConfigurationDataModel,
): ConfigurationActionTypes {
  return {
    type: FETCH_CONFIGURATIONS_DATA_RESPONSE,
    payload: configurationsData,
  };
}

export function fetchConfigurationsDataFailure(): ConfigurationActionTypes {
  return {
    type: FETCH_CONFIGURATIONS_DATA_FAILURE,
    payload: null,
  };
}

export function fetchConfigurationRequest(): ConfigurationActionTypes {
  return {
    type: FETCH_CONFIGURATION_REQUEST,
    payload: null,
  };
}

export function fetchConfigurationResponse(
  configuration: Configuration,
): ConfigurationActionTypes {
  return {
    type: FETCH_CONFIGURATION_RESPONSE,
    payload: configuration,
  };
}

export function fetchConfigurationFailure(): ConfigurationActionTypes {
  return {
    type: FETCH_CONFIGURATION_FAILURE,
    payload: null,
  };
}

export const fetchActiveConfiguration = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchConfigurationRequest());
    const asyncResp: any = await getConfiguration(id);
    if (asyncResp?.success) {
      await dispatch(fetchConfigurationResponse(asyncResp.data));
    } else {
      await dispatch(fetchConfigurationFailure());
    }
  };

export const fetchDefaultConfiguration = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchConfigurationRequest());
    const asyncResp: any = await getDefaultConfiguration();
    if (asyncResp?.success) {
      await dispatch(fetchConfigurationResponse(asyncResp.data));
    } else {
      await dispatch(fetchConfigurationFailure());
    }
  };

export const fetchConfigurationByShortcode = (groupShortcode: string, clientShortcode: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchConfigurationRequest());
    const asyncResp: any = await getConfigurationByShortcodes(groupShortcode, clientShortcode);
    if (asyncResp?.success) {
      await dispatch(fetchConfigurationResponse(asyncResp.data));
    } else {
      await dispatch(fetchConfigurationFailure());
    }
  };

export const fetchConfigurationByLogin = (roleId: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchConfigurationRequest());
    const asyncResp: any = await getConfigurationByLogin(roleId);
    if (asyncResp?.success) {
      await dispatch(fetchConfigurationResponse(asyncResp.data));
    } else {
      await dispatch(fetchConfigurationFailure());
    }
  };

export const fetchConfigurationByClientId = (clientId: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchConfigurationRequest());
    const asyncResp: any = await getConfigurationByClientId(clientId);
    if (asyncResp?.success) {
      await dispatch(fetchConfigurationResponse(asyncResp.data));
    } else {
      await dispatch(fetchConfigurationFailure());
    }
  };

export function postConfigurationRequest(): ConfigurationActionTypes {
  return {
    type: POST_CONFIGURATION_REQUEST,
    payload: null,
  };
}

export function postConfigurationResponse(): ConfigurationActionTypes {
  return {
    type: POST_CONFIGURATION_RESPONSE,
    payload: null,
  };
}

export function postConfigurationFailure(error: string, validationErrors: any): ConfigurationActionTypes {
  return {
    type: POST_CONFIGURATION_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}


export const fetchConfigurations = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchConfigurationsRequest());
    const asyncResp: any = await getConfigurations(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchConfigurationsResponse(asyncResp));
    } else {
      await dispatch(fetchConfigurationsFailure());
    }
  };

export const fetchConfigurationsData = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchConfigurationsDataRequest());
    const asyncResp: any = await getConfigurations(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchConfigurationsDataResponse(asyncResp));
    } else {
      await dispatch(fetchConfigurationsDataFailure());
    }
  };

export const storeConfiguration = async (id: string) => {
  try {
    await archiveConfiguration(id);
  } catch (error) {
    throw error;
  }
};

export const activateConfiguration = async (id: string) => {
  try {
    await unarchiveConfiguration(id);
  } catch (error) {
    throw error;
  }
};

export const createConfiguration = (
  groupId:string, clientId:string, backgroundColor:string,
  foregroundColor: string, primaryActionColor: string, secondaryActionColor: string, successColor: string, warningColor: string,
  dropdownColor: string, primaryTextColor: string, secondaryTextColor: string, primaryLogo: string, secondaryLogo: string, favicon:string,
  defaultRole: string | null, welcomeContent: string | null, allowedFileTypes: string | null, maxUploadSize: number | null, customRedirectUrl: string | null, webCommitmentIntegration: boolean,
  enableAI: boolean, enableAuctionPayments: boolean, executeAIWizardOnUpdate: boolean, isPremium: boolean, enableQR: boolean, reservedStatus: boolean, notifyOnNewEntry: boolean, allowAnonymousViewFetch:boolean, allowAnonymousEntryFetch:boolean,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postConfigurationRequest());
    const asyncResp: any = await postConfiguration(groupId, clientId, backgroundColor,
      foregroundColor, primaryActionColor, secondaryActionColor, successColor, warningColor, dropdownColor, primaryTextColor,
      secondaryTextColor, primaryLogo, secondaryLogo, favicon, defaultRole, welcomeContent, allowedFileTypes, maxUploadSize, customRedirectUrl, webCommitmentIntegration,
      enableAI, enableAuctionPayments, executeAIWizardOnUpdate, isPremium, enableQR, reservedStatus, notifyOnNewEntry, allowAnonymousViewFetch, allowAnonymousEntryFetch, active) ;
    if (asyncResp?.success) {
      await dispatch(postConfigurationResponse());
    } else {
      await dispatch(postConfigurationFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateConfiguration = (
  id:string, groupId:string, clientId:string, backgroundColor:string,
  foregroundColor: string, primaryActionColor: string, secondaryActionColor: string, successColor: string,
  warningColor: string, dropdownColor: string, primaryTextColor: string,
  secondaryTextColor: string, primaryLogo: string, secondaryLogo: string,
  favicon:string, defaultRole: string | null, welcomeContent: string | null, allowedFileTypes: string | null, maxUploadSize: number | null,
  customRedirectUrl: string | null,  webCommitmentIntegration: boolean, enableAI: boolean, enableAuctionPayments:boolean, executeAIWizardOnUpdate: boolean,
  isPremium: boolean, enableQR: boolean, reservedStatus: boolean, notifyOnNewEntry: boolean,
  allowAnonymousViewFetch:boolean, allowAnonymousEntryFetch:boolean, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postConfigurationRequest());
    const asyncResp: any = await putConfiguration(id, groupId, clientId, backgroundColor,
      foregroundColor, primaryActionColor, secondaryActionColor, successColor, warningColor, dropdownColor, primaryTextColor, secondaryTextColor,
      primaryLogo, secondaryLogo, favicon, defaultRole, welcomeContent, allowedFileTypes, maxUploadSize, customRedirectUrl, webCommitmentIntegration,
      enableAI, enableAuctionPayments, executeAIWizardOnUpdate, isPremium, enableQR,  reservedStatus, notifyOnNewEntry, allowAnonymousViewFetch, allowAnonymousEntryFetch, active);
    if (asyncResp?.success) {
      await dispatch(postConfigurationResponse());
    } else {
      await dispatch(postConfigurationFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };