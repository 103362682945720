import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getConfigurations(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.CONFIGURATION,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getDefaultConfiguration() {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CONFIGURATION}-default`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getConfiguration(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CONFIGURATION}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getConfigurationByShortcodes(groupShortcode: string, clientShortcode: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CONFIGURATION}/${groupShortcode}/${clientShortcode}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getConfigurationByLogin(roleId: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CONFIGURATION}-role/${roleId}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getConfigurationByClientId(clientId: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CONFIGURATION}-client/${clientId}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveConfiguration(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CONFIGURATION}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function unarchiveConfiguration(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CONFIGURATION}/${id}/unarchive`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function postConfiguration(groupId:string, clientId:string, backgroundColor:string,
  foregroundColor: string, primaryActionColor: string, secondaryActionColor: string, successColor: string, warningColor: string,
  dropdownColor: string, primaryTextColor: string, secondaryTextColor: string,
  primaryLogo: string, secondaryLogo: string, favicon:string, defaultRole: string | null, welcomeContent: string | null, allowedFileTypes: string | null,
  maxUploadSize: number | null,
  customRedirectUrl: string | null, webCommitmentIntegration: boolean, enableAI: boolean, enableAuctionPayments: boolean, executeAIWizardOnUpdate: boolean,
  isPremium: boolean, enableQR: boolean, reservedStatus: boolean, notifyOnNewEntry: boolean, allowAnonymousViewFetch: boolean, allowAnonymousEntryFetch: boolean, active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.CONFIGURATION,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        backgroundColor,
        foregroundColor,
        primaryActionColor,
        secondaryActionColor,
        successColor,
        warningColor,
        dropdownColor,
        primaryTextColor,
        secondaryTextColor,
        primaryLogo,
        secondaryLogo,
        favicon,
        defaultRole,
        welcomeContent,
        allowedFileTypes,
        maxUploadSize,
        customRedirectUrl,
        webCommitmentIntegration,
        enableAI,
        enableAuctionPayments,
        executeAIWizardOnUpdate,
        isPremium,
        enableQR,
        reservedStatus,
        notifyOnNewEntry,
        allowAnonymousViewFetch,
        allowAnonymousEntryFetch,  
        active,

      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putConfiguration(
  id:string, groupId:string, clientId:string, backgroundColor:string,
  foregroundColor: string, primaryActionColor: string, secondaryActionColor: string, successColor: string,
  warningColor: string, dropdownColor: string, primaryTextColor: string,
  secondaryTextColor: string, primaryLogo: string, secondaryLogo: string,
  favicon:string, defaultRole: string | null, welcomeContent: string | null, allowedFileTypes: string | null,
  maxUploadSize: number | null,
  customRedirectUrl: string | null, webCommitmentIntegration: boolean, enableAI: boolean, enableAuctionPayments: boolean, executeAIWizardOnUpdate: boolean,
  isPremium: boolean, enableQR: boolean, reservedStatus: boolean, notifyOnNewEntry: boolean, allowAnonymousViewFetch: boolean, allowAnonymousEntryFetch: boolean,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CONFIGURATION}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        id,
        groupId,
        clientId,
        backgroundColor,
        foregroundColor,
        primaryActionColor,
        secondaryActionColor,
        successColor,
        warningColor,
        dropdownColor,
        primaryTextColor,
        secondaryTextColor,
        primaryLogo,
        secondaryLogo,
        favicon,
        defaultRole,
        welcomeContent,
        allowedFileTypes,
        maxUploadSize,
        customRedirectUrl,
        webCommitmentIntegration,
        enableAI,
        enableAuctionPayments,
        executeAIWizardOnUpdate,
        isPremium,
        enableQR,
        reservedStatus,
        notifyOnNewEntry,
        allowAnonymousViewFetch,
        allowAnonymousEntryFetch,  
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}