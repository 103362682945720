import React, { useEffect, useState } from 'react';
import View from '../../../components/View/View';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import Stepper from '../../../components/Stepper';

import {
  isPostingAuctionEntry,
  postingAuctionEntryError,
  postingAuctionEntryFailed, postingAuctionEntryValidationErrors,
} from '../../../selectors/AuctionEntry';
import { createAuctionWithItems } from '../../../actions/Auction';
import Button from '../../../components/Button';
import EditCard from '../../../components/EditCard/EditCard';
import CheckBox from '../../../components/CheckBox/CheckBox';
import Input from '../../../components/Input/Input';
import DateSelect from '../../../components/DateSelect';
import { useValidation } from '../../../hooks/ValidationHook';
import { isRequired } from '../../../helpers/validators';
import { ensurePositiveInteger, ensurePositiveIntegerOrZero } from '../../../helpers/functions';
import { useHistory } from 'react-router-dom';
import './AuctionWizard.scss';
import { addDays, format } from 'date-fns';
import { useContextProvider } from '../../../providers/ContextProvider';
import noImage from '../../../assets/images/no-preview.png';

const StepThree = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const isPosting = useSelector(isPostingAuctionEntry);
  const postingFailed = useSelector(postingAuctionEntryFailed);
  const postingError = useSelector(postingAuctionEntryError);
  const postingValidationErrors = useSelector(postingAuctionEntryValidationErrors);
  const { auctionData } = useContextProvider();
  const auction = auctionData.auctionDetails;
  const auctionEntriesByAuctionId = auctionData.selectedItems ?? [];

  const steps = [
    'Provide Auction Details',
    'Select Items For Auction',
    'Overview',
    'Success',
  ];

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [auctionEntryBatch, setAuctionEntryBatch] = useState([]);
  const [formConfig] = useState([]);
  const [overrideState, setOverrideState] = useState<{ [entryId: string]: boolean }>({});

  const { v, err, updateFields } = useValidation(formConfig, postingValidationErrors);
  const auctionDetails = auctionData.auctionDetails;
  const selectedItems = auctionData.selectedItems;

  useEffect(() => {
    if (!auctionDetails || Object.keys(auctionDetails).length === 0) {
      history.push(c.APP_ROUTES.AUCTION_CREATE_STEP_ONE);
    } else if (!selectedItems || Object.keys(selectedItems).length === 0){
      history.push(c.APP_ROUTES.AUCTION_CREATE_STEP_TWO);
    }
  }, [auctionDetails, selectedItems]);

  useEffect(() => {
    if (auctionEntriesByAuctionId && auctionEntriesByAuctionId.length != 0){
      const updatedAuctionEntryBatch = auctionEntriesByAuctionId.map((item: { endDateTime: string; }) => ({
        ...item,
        endDateTime: item.endDateTime,
      }));
      setAuctionEntryBatch(updatedAuctionEntryBatch);
    }

    if (auctionEntriesByAuctionId.length) {
      const innerFormConfig = [];
      for (let i = 0; i < auctionEntriesByAuctionId.length; i++) {
        const startingBidField = `startingBid_${i}`;
        const bidIncrementField = `bidIncrement_${i}`;
        const config = [
          { field: startingBidField, validators: [isRequired] },
          { field: bidIncrementField, validators: [isRequired] },
        ];
        innerFormConfig.push(...config);
      }
      updateFields(innerFormConfig);
    }
  }, [auctionEntriesByAuctionId]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.AUCTION_CREATE_STEP_FOUR);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (auctionData && auctionEntryBatch) {
      setHasSubmitted(true);
      dispatch(createAuctionWithItems(auctionData.auctionDetails, auctionEntryBatch));
      history.push(c.APP_ROUTES.AUCTION_CREATE_STEP_FOUR);
    }
  };

  const handleOverride = (entryId: string) => {
    setOverrideState((prevState) => ({
      ...prevState,
      [entryId]: !prevState[entryId],
    }));
  };

  const handleInputChange = (entryId: string, value: string | Date, property: string) => {
    if (auctionEntryBatch) {
      const updatedAuctionEntries: any = auctionEntryBatch.map((entry: { id: string; }) =>
        entry.id === entryId ? { ...entry, [property]: value } : entry,
      );
      setAuctionEntryBatch(updatedAuctionEntries);
    }
  };

  const auctionBox = (asset: string, title: string, description: string, startDateTime: string) =>{
    return (
    <div className='row my-3 auction-wizard__box'>
      <div className="col-md-6 col-12 p-3">
        <img loading="lazy" src={`${c.API_ENDPOINTS.ASSET_FILE}/${asset}`} alt="" className="auction-wizard__img-fluid" onError={(event) => event.currentTarget.src = noImage}/>
      </div>
      <div className="col-md-6 col-12 p-3">
        <div className="col-12 mb-3 auction-wizard__third-step__auction-title">
          {title}
        </div>
        <div className="col-12 mb-3">
          {description}
        </div>
        <div className="col-12 mb-2">
          <div className="col-12 auction-wizard__third-step__bold-title">
            Start Date & Time
          </div>
          <div className="col-12 auction-wizard__third-step__auction-date">
            {format(new Date(startDateTime), 'yyyy-MM-dd HH:mm:ss') }
          </div>
        </div>
      </div>
    </div>);
  };

  const renderInputColumn = (name: string, id: string, value: string, onChangeHandler: any, index: number) => (
      <div>
        <Input
            name={`${name}_${index}`}
            id={`${name}_${index}`}
            type='number'
            v={v}
            err={err}
            value={value}
            onChange={(e) => onChangeHandler(id, ensurePositiveInteger(e), name)}
            disabled={isPosting}
            onBlur={(e) => onChangeHandler(id, e, name)}
        />
      </div>
  );

  const renderStartingBid = (name: string, id: string, value: string, onChangeHandler: any, index: number) => (
      <div>
        <Input
            name={`${name}_${index}`}
            id={`${name}_${index}`}
            type='number'
            v={v}
            err={err}
            value={value}
            onChange={(e) => onChangeHandler(id, ensurePositiveIntegerOrZero(e), name)}
            disabled={isPosting}
            onBlur={(e) => onChangeHandler(id, e, name)}
        />
      </div>
  );



  const renderDateSelectColumn = (name: string, id : string, value :any, onChangeHandler: any, index: number) => (
      <div className="">
        <DateSelect
            id={`endDateSelect_${index}`}
            onChange={(e) => onChangeHandler(id, e, name)}
            value={new Date(value)}
            minDateTime={addDays(new Date(auction?.startDateTime as string), 1)}
        />
      </div>
  );
  return (
      <View title="Overview">
        <div className="container-fluid">
          {postingError && (
              <Alert className="mt-3" variant="danger">
                {postingError}
              </Alert>
          )}
          <div className="row justify-content-center mb-4">
            <div className="col-8">
              <Stepper steps={steps} activeStep={2} hideMobileLabels={true}/>
            </div>
          </div>
          <div className="mx-4">
          <div className="row  justify-content-center">
            {auction && auctionBox(auction?.assetId, auction?.title, auction?.description, auction?.startDateTime)}
          </div>
                <div className='row my-3 auction-wizard__box'>
                    {auctionEntryBatch && auctionEntryBatch.map((entry: any, index: number) => (
                      <div key={entry.id} className="col-xxl-6  col-12 my-4">
                        <EditCard
                          title={entry.title}
                          imageUrl={`${c.API_ENDPOINTS.ASSET_FILE}/${entry.assetId}`}
                      >
                          <div className="row my-1 justify-content-around auction-wizard__third-step__bold-title px-1">
                            <div className="col-md-3 col-12 p-2">
                              <div>Bid Increment</div>
                              {overrideState[entry.id] ? (
                                <>
                                  {renderInputColumn('bidIncrement', entry.id, entry.bidIncrement, handleInputChange, index)}
                                </>
                              ) : (
                                <>
                                  <div className="auction-wizard__third-step__entry--grey-color">{entry?.bidIncrement}</div>
                                </>
                              )}
                            </div>
                            <div className="col-md-3 col-12 p-2">
                              <div>Starting Bid</div>
                              {overrideState[entry.id] ? (
                                <>
                                  {renderStartingBid('startingBid', entry.id, entry.startingBid, handleInputChange, index)}
                                </>
                              ) : (
                                <>
                                  <div className="auction-wizard__third-step__entry--grey-color">{entry?.startingBid}</div>
                                </>
                              )}
                            </div>
                            <div className="col-md-6 col-12 p-2">
                              <div>End Date & Time</div>
                              {overrideState[entry.id] ? (
                                <>
                                  {renderDateSelectColumn('endDateTime', entry.id, entry.endDateTime, handleInputChange, index)}
                                </>
                              ) : (
                                <>
                                  <div className="auction-wizard__third-step__entry--grey-color">{format(new Date(entry.endDateTime), 'yyyy-MM-dd HH:mm:ss')}</div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="row my-2 justify-content-start">
                            <CheckBox onChange={() => handleOverride(entry.id)} type='switch' label='Override'/>
                          </div>
                        </EditCard>
                      </div>
                    ))}
                </div>
          <div className="row justify-content-end">
            <div className="col-auto">
              <Button disabled={auctionEntryBatch && auctionEntryBatch?.length < 1} isLoading={isPosting}
                      onClick={() =>  onSubmit()}
                      title={ 'Next'}/>
            </div>
          </div>
          </div>
        </div>
      </View>
  );
};

export default StepThree;

