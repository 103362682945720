import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getCompositions, isFetchingCompositions } from '../../../selectors/Composition';
import { activateComposition, fetchCompositions, storeComposition } from '../../../actions/Composition';
import { Composition } from '../../../types/Composition';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const CompositionDataTable = () => {

  const compositions = useSelector(getCompositions);
  const isLoading = useSelector(isFetchingCompositions);
  const [toggle, setToggle] = useState(false);
  const [composition, setActiveComposition] = useState<Composition | null>();
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);

  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.COMPOSITION_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.COMPOSITION_UPDATE, row.id));
  };

  const onArchive = (row: Composition) => {
    setActiveComposition(row);
    setToggle(true);
  };

  const onUnArchive = (row: Composition) => {
    setActiveComposition(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (composition?.id) {
      if (unArchiveToggle){
        await activateComposition(composition?.id);
      } else {
        await storeComposition(composition?.id);
      }
      setRefetch(!refetch);
    }
    setActiveComposition(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'material', label: 'Material', mobileFriendly: true },
      { key: 'variation', label: 'Variation', mobileFriendly: true },
      { key: 'CO2', label: 'CO2 Emissions', mobileFriendly: false },
      { key: 'unitOfMeasure', label: 'Unit of Measure', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: 'Archive', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive }],
    hideButton: true,
  };

  return (
        <View title="Compositions" onAddNew={onCreate}>
            <DataTable
                fetchFunction={fetchCompositions}
                data={compositions?.data || []}
                isLoading={isLoading}
                paging={compositions?.paging}
                config={config}
                dependencies={refetch}
            />
          <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Entry' : 'Archive Entry'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
            {unArchiveToggle ? 'Are you sure you want to unarchive this entry?' : 'Are you sure you want to archive this entry?'}
          </Modal>
        </View>
  );
};

export default CompositionDataTable;
