import React, { useEffect, useState } from 'react';
import './GreenReport.scss';
import Chart from '../../Chart';
import { Pie, Cell } from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import AssetGallery from '../../AssetGallery/AssetGallery';
import Button from '../../Button/Button';
import { useParams } from 'react-router-dom';
import { getActiveOffer } from '../../../selectors/Offer';
import { fetchActiveOffer } from '../../../actions/Offer';

interface MaterialData {
  name: string;
  weight: number;
  value: number;
  color: string;
}

interface ReportSectionProps {
  title: string;
  data: MaterialData[];
  chartData: MaterialData[];
  total: string;
}

const data1 = [
  { name: 'RAW MATERIAL RECYCLED', weight: 3800, value: 40, color : '1488C7' },
  { name: 'ENERGY RECOVER', weight: 2200, value: 23.2, color : '809DAD' },
  { name: 'DISPOSAL', weight: 3500, value: 36.8, color : 'D2E8F4' },
];

const data2 = [
  { name: 'WOOD', weight: 4138, value: 80.2, color : '1488C7' },
  { name: 'METAL', weight: 1006, value: 19.5, color : '809DAD' },
  { name: 'PLASTIC', weight: 15, value: 0.3, color : 'D2E8F4' },
];

const COLORS = ['#1488C7', '#809DAD', '#D2E8F4'];

const GreenReport = () => {
    
  const reportData = useSelector(getActiveOffer);
  const html2pdf = require('html2pdf.js');
  const dispatch = useDispatch();
  const { id } = useParams<{ id : string }>();
  const [formattedDate, setFormattedDate] = useState('');
  const [proposalTitle, setProposalTitle] = useState('');

  useEffect(() => {
    dispatch(fetchActiveOffer(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (reportData){
      if (reportData.date != null) {
        const date = new Date(Date.parse(reportData.date ));
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        setFormattedDate(`${day}-${month}-${year}`);
      }
      if (reportData.clientNumber){
        setProposalTitle(reportData.clientNumber);
      }
    }
  }, [reportData]);

  const downloadPdf = () => {
    const element = document.getElementById('greenReport');
    if (element) {
      let sanitizedTitle = proposalTitle?.replace(/[^a-zA-Z0-9 ]+/g, '').replace(/\s+/g, '-') ?? 'offer';
      const maxLength = 40;
      if (sanitizedTitle.length > maxLength) {
        sanitizedTitle = sanitizedTitle.substring(0, maxLength);
      }
      html2pdf()
        .from(element)
        .set({
          margin: 0,
          filename: `${sanitizedTitle}-green-report.pdf`,
          image: { type: 'jpeg', quality: 1 },
          html2canvas: {
            useCORS: true,
          },
          jsPDF: { unit: 'in', orientation: 'portrait' },
        })
        .save();
    }
  };

  const ReportSection: React.FC<ReportSectionProps> = ({ title,  data, total }) => (
      <div className="green-report__body-container mt-3">
        <div className="green-report__body-title">{title}</div>
        <div className="green-report__blue-line"></div>
        <div className="row justify-content-between">
          <div className="col">
            {data.map(e => (
                <div key={e.name} className="row">
                  <div className="col green-report__body-item-title">{e.weight}KG</div>
                  <div className="col green-report__body-item-title">{e.value}%</div>
                  <div className={`col align-self-center green-report__body-color__${e.color}`}></div>
                  <span className="green-report__body-item-title-small">{e.name}</span>
                </div>
            ))}
              <div className="row">
                  <div className="col green-report__body-item-title-green">{total}KG</div>
                  <div className="col green-report__body-item-title-green">100%</div>
                  <span className="green-report__body-item-title-small">TOTAL</span>
              </div>
          </div>

          <div className="green-report__chart-section col ml-1">
            <Chart width={270} height={270}>
              <Pie
                  data={data}
                  innerRadius={40}
                  outerRadius={130}
                  fill="#8884d8"
                  paddingAngle={0}
                  blendStroke={true}
                  dataKey="value"
                  isAnimationActive={false}
              >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </Chart>
          </div>
        </div>
      </div>
  );

  return (
      <>
          <div className='green-report__download'>
      <Button  title={'Download'} onClick={() => downloadPdf()}></Button>
          </div>
        <div id={'greenReport'}>
        <div className='green-report__container'>
          <div className="green-report__header row">
            <div className="heading col justify-content-start">Green Report</div>
            <div className="d-flex justify-content-between mt-2">
                <div className="green-report__subheader">
                    {proposalTitle ?? 'PROPOSAL 1'}
                </div>
                <div className="green-report__subheader">{formattedDate}</div>
            </div>
          </div>

          {/*<div className="green-report__info">*/}
          {/*          Take advantage of our special offer on Circuit Breaker 1! This essential component ensures*/}
          {/*          safety and*/}
          {/*          efficiency in your electrical grid by automatically interrupting electricity flow during*/}
          {/*          overloads or*/}
          {/*          short*/}
          {/*          circuits, protecting your system from damage.*/}
          {/* </div>*/}
          <ReportSection
              title="PICKED UP MATERIAL"
              data={data1}
              chartData={data1}
              total = {'9500'}
          />
          <ReportSection
              title="RAW MATERIAL RECYCLED WITH THE TREATMENT"
              data={data2}
              chartData={data2}
              total = {'5159'}
          />
          <div className="green-report__body-container mt-3">
            <div className="green-report__body-title">ENVIRONMENTAL IMPACT OF THE PROPOSAL</div>
            <div className="green-report__blue-line"></div>
            <div className="row">
              <div className="bolt-icon col">
                <div className="green-report__body-item-title">16.479KWH</div>
                <div className="green-report__subheader">ENERGY SAVED EQUIVALENT TO:</div>
              </div>
              <div className="cloud-icon col ">
                <div className="green-report__body-item-title">4.092KG CO2 EQ</div>
                <div className="green-report__subheader">CO2 AVOIDED EQUIVALENT TO:</div>
              </div>
            </div>
            <div className="row mt-5 d-flex justify-items-center">
                <div className="d-flex align-items-center gap-3 green-report__body-title">
                  <img src={AssetGallery.peopleRoof} alt="roof" width={40} height={40} />
                  <div>Average consumption per year of 5 families</div>
                </div>
                <div className="d-flex align-items-center gap-3 green-report__body-title">
                  <img src={AssetGallery.car} alt="car" width={40} height={40}/>
                  <div>CO2 produced by a small car that runs 11300 km</div>
                </div>
                <div className="d-flex align-items-center gap-3 green-report__body-title">
                  <img src={AssetGallery.treeCity} alt="tree" width={40} height={40}/>
                  <div>CO2 absorbed by 41 trees in 1 year</div>
                </div>
            </div>
          </div>
             <div className="green-report__info">
              <div>{"The Green Report, meticulously curated and issued by ABB, stands as a prestigious symbol of eco-consciousness and environmental stewardship. It signifies a steadfast dedication to sustainability and underscores a firm commitment to conserving our planet's precious resources. As a recognized certification, the Green Report embodies ABB's unwavering pledge to prioritize environmental responsibility and pave the way for a greener, more sustainable future."}</div>
             </div>
          <div className="green-report__footer mt-5">
            <div>{formattedDate}</div>
            <div>
              <img src={AssetGallery.abbLogo} alt="ABB Logo"/>
            </div>
          </div>
        <div className="end-section"></div>
      </div>
        </div>
          </>

  );
};

export default GreenReport;