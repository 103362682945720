import React, { useState, useEffect } from 'react';
import Modal from '../Modal/Modal'; // Your existing Modal component
import XLSXDropzone from '../XLSXDropzone/XLSXDropzone';
import { downloadFormStructure } from '../../helpers/functions';

interface BulkImportModalProps {
  show: boolean;
  onHide: () => void;
  formId: string;
  toggleRefetch?: () => void;
}

const BulkImportModal: React.FC<BulkImportModalProps> = ({ show, onHide, formId, toggleRefetch }) => {
  const [data, setData] = useState<Record<string, string>[]>([]);

  const handleFileRead = (readData: Record<string, string>[]) => {
    setData(readData);
    console.log('Parsed XLSX Data:', readData); // Log parsed data to the console
  };

  const handleClear = () => {
    setData([]); // Clear the state holding the XLSX data
  };

  // Clear all data whenever the modal is hidden (on exit)
  useEffect(() => {
    if (!show) {
      setData([]); // Reset XLSX data when modal is closed
    }
  }, [show]);

  return (
        <Modal show={show} title={'Bulk Import'} onHide={onHide}>
            <XLSXDropzone formId={formId} onFileRead={handleFileRead} onClear={handleClear} toggleRefetch={toggleRefetch}/>
            {data.length > 0 && (
                <div>
                    <h4>XLSX Data:</h4>
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                </div>
            )}
            <span
                style={{
                  color: 'blue',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => downloadFormStructure(formId)}
            >
              Download sample XLSX
            </span>
        </Modal>
  );
};

export default BulkImportModal;