import React from 'react';
import Card from '../../../components/Card/Card';
import c from '../../../helpers/constants';
import DataTable from '../../../components/DataTable';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getActiveUser, isFetchingUsers } from '../../../selectors/User';
import { formatUrl } from '../../../helpers/functions';
import { getAuctionBids, isFetchingAuctionBids } from '../../../selectors/AuctionBid';
import { fetchAuctionBids } from '../../../actions/AuctionBid';

const MyBids = () => {

  const auctions = useSelector(getAuctionBids);
  const fetchingAuctionBids = useSelector(isFetchingAuctionBids);
  const fetchingUsers = useSelector(isFetchingUsers);
  const isLoading = fetchingAuctionBids || fetchingUsers;
  const history = useHistory();
  const activeUser = useSelector(getActiveUser);
  const onView = (auctionId: string) => {
    history.push(formatUrl(c.APP_ROUTES.VIEW_AUCTION_ENTRY, auctionId));
  };
  const config = {
    columns: [ { key: 'auctionTitle', label: 'Auction Title', mobileFriendly: true },
      { key: 'entryTitle', label: 'Product Title', mobileFriendly: true },
      { key: 'amount', label: 'Bid', mobileFriendly: true },
    ],
    actions: [],
    hideButton: true,
  };
  return <>
    {activeUser && <div><DataTable paging={auctions?.paging} data={auctions?.data || []} config={config}
                     isLoading={isLoading}
                     fetchFunction={fetchAuctionBids} baseSearch={{ 'userId': activeUser?.id }}
                     isTable={false}
                     lineItemTemplate={(row: any) =>
                         <div key={row.id} className="col-md-6 col-sm-6 mb-4">
                           <Card
                               cardId={row.index}
                               title={row.auctionTitle}
                               description={`You have bid ${row.amount} for the ${row.entryTitle}`}
                               imageUrl={`${c.API_ENDPOINTS.ASSET_FILE}/${row.assetId}`}
                               onClick={() => onView(row.auctionEntryId)}
                               status={row.auctionEntryStatus}
                           />
                         </div>
                     }
    /></div>}
  </>;
};

export default MyBids;



