import React from 'react';
import HeadlessView from '../../../components/HeadlessView';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useHistory } from 'react-router-dom';


const UserPendingVerification = () => {
  const history = useHistory();
    
  const toLogin = () => {
    history.push(c.APP_ROUTES.USER_LOGIN);
  };

  return <HeadlessView title="Registration complete!">
      <div className="container">
          <div className="row">
              <div className="col-12 p-5">
                <h6>Please check your email for additional information.</h6>
                  <div className="mt-3">
                      <Button onClick={() => toLogin()}
                              title={'Back to Login'}/>
                  </div>

              </div>
          </div>
      </div>
</HeadlessView>;

};

export default UserPendingVerification;
