import React, { useEffect, useMemo, useState } from 'react';
import './Subscription.scss';
import c, { InvoiceType, TransactionStatus, TERMS_CONDITIONS_FILE } from '../../helpers/constants';
import Button from '../../components/Button';
import EMEModal from '../../components/Modal';
import { postOPPTransaction } from '../../api/Subscription';
import { getPlans, isFetchingPlans } from '../../selectors/Plan';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlans } from '../../actions/Plan';
import { createSubscription, fetchActiveSubscription, fetchSubscriptions } from '../../actions/Subscription';
import { getActiveClient, getActiveUserSubscription } from '../../helpers/functions';
import { getLoggedInUser } from '../../selectors/Auth';
import { getActiveSubscription, getSubscriptions, isFetchingSubscriptions } from '../../selectors/Subscription';
import View from '../../components/View/View';
import { currencyMappings, TransactionSourceType } from '../../helpers/constants';
import { DataTableParamsModel } from '../../types/Common';
import { isFetchingTransactions } from '../../selectors/Transaction';
import Markdown from './MarkdownRenderer';
import Receipt from './Receipt';
import AssetGallery from '../../components/AssetGallery';
import Pill from '../../components/Pill';

export interface TransactionResponse {
  id: string;
  redirect_url: string;
  amount: number;
  status: string;
  return_url: string;
  payment_method: string;
}

const Subscription = () => {
  const plans = useSelector(getPlans);
  const activeSubscription = useSelector(getActiveSubscription);
  const activeUser = useSelector(getLoggedInUser);
  const pendingClientSubscriptionTransactions = useSelector(getSubscriptions);
  const isLoadingTransactions = useSelector(isFetchingTransactions);
  const isLoadingSubscriptions = useSelector(isFetchingSubscriptions);
  const isLoadingPlans = useSelector(isFetchingPlans);
  const isLoading = isLoadingPlans || isLoadingSubscriptions || isLoadingTransactions;
  const dispatch = useDispatch();
  const [transactionData, setTransactionData] = useState<TransactionResponse | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [planDataId, setPlanDataId] = useState('');
  const [showModalDetails, setShowModalDetails] = useState(false);
  const [showModalInvoice, setShowModalInvoice] = useState(false);
  const [subPlan, setSubPlan] = useState('');
  const [returnUrl, setReturnUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const client = getActiveClient();
  const userHasActiveSubscription = getActiveUserSubscription(activeUser, client.id);
  const planModal = useMemo(() => plans?.data?.find(p => p.id === planDataId), [plans, planDataId]);
  const hasPendingSepaTransaction = (pendingClientSubscriptionTransactions?.data?.[0] &&
      pendingClientSubscriptionTransactions.data[0].transactionPaymentMethod === 'sepa' &&
      pendingClientSubscriptionTransactions.data[0].transactionStatus === 'pending');

  const latestTransaction = pendingClientSubscriptionTransactions?.data?.[0];

  useEffect(()=>{
    dispatch(fetchPlans());
    if (userHasActiveSubscription.activeSubscription) {
      dispatch(fetchActiveSubscription(userHasActiveSubscription.activeSubscription));
    }
  }, [userHasActiveSubscription.activeSubscription]);

  useEffect(() => {
    if (client.id) {
      const transactionDataTableParams: DataTableParamsModel = {
        paging: '100',
        baseSearch: { 'transactionStatus': TransactionStatus.PENDING, 'clientId': client.id },
        searchField: 'transactionSourceType',
        searchWord: TransactionSourceType.SUBSCRIPTION ?? 'undefined', //fallback to string that will return no items
      };
      dispatch(fetchSubscriptions(transactionDataTableParams));
    }
  }, [dispatch, client.id]);

  const handleSelectTier = async (planId: string, productName: string, fee: number) => {

    if (latestTransaction) {
      if (hasPendingSepaTransaction) {
        window.location.href = latestTransaction.transactionRedirectUrl;
        return;
      }

      if (!hasPendingSepaTransaction) {
        return;
      }
    }
    setSubPlan(planId);
    setLoading(true);
    if (client && client.id) {
      const asyncResp = await postOPPTransaction(client?.id, '', productName, fee, '', TransactionSourceType.SUBSCRIPTION, currencyMappings.euro);
      if (asyncResp?.success && asyncResp?.data && asyncResp?.data?.payment_method === 'free') {
        setReturnUrl(asyncResp?.data?.return_url);
        setTransactionData(asyncResp?.data);
        setShowModal(true); // Open modal after transaction is created
      } else if (asyncResp?.success && asyncResp?.data && asyncResp?.data?.payment_method !== 'free') {
        setTransactionData(asyncResp?.data);
        setReturnUrl('');
        setShowModal(true);
      } else {
        setShowModal(true);
        setErrorMessage(asyncResp?.error);
      }
    }
  };

  const handleModalClose = () =>{
    setLoading(false);
    setShowModal(false);
  };

  const handleShowModalInvoice = (planId: string, active: boolean) =>{
    setPlanDataId(planId);
    setShowModalInvoice(active);
  };

  const handleShowModalDetails = (planId: string, active: boolean) =>{
    setPlanDataId(planId);
    setShowModalDetails(active);
  };

  const handleProceedToBank = async () => {
    setLoadingButton(true);
    if (transactionData && !returnUrl && client?.id) {
      await dispatch(createSubscription(subPlan, client?.id, transactionData.id, '', '' ));
      window.location.href = transactionData?.redirect_url; // Redirect to OPP payment page
    } else if (returnUrl && client?.id && transactionData){
      await dispatch(createSubscription(subPlan, client?.id, transactionData.id, '', ''));
      window.location.href = returnUrl;
    }
  };

  return (
      <View title={'Subscription'} isLoading={isLoading}>
        <div className="subscription-container">
          {(pendingClientSubscriptionTransactions && pendingClientSubscriptionTransactions?.data?.length > 0 && !hasPendingSepaTransaction) && (
              <div className="p-4 bg-warning text-dark rounded-3 shadow mb-3">
                <p>Your payment is currently pending, and no further action is needed at this time. If the payment is
                  not received, the transaction will automatically expire. Once it expires,
                  you’ll have the option to initiate a new transaction.</p>
              </div>
          )}
          {(pendingClientSubscriptionTransactions && pendingClientSubscriptionTransactions?.data.length > 0 && hasPendingSepaTransaction) && (
              <div className="p-4 bg-warning text-dark rounded-3 shadow mb-3">
                <p>Your SEPA payment is currently pending. Bank processing may take some time before it reaches us. You can view the SEPA details again by clicking the Proceed button.</p>
              </div>
          )}
          <h1>Select your subscription plan</h1>
          <div className="subscription-container__card-container">
            {plans && plans.data
              .filter((plan) => plan.client_title === null || plan.client_title.id === client.id)
              .sort((a, b) => a.monthlyFee - b.monthlyFee) // Sort by monthlyFee in ascending order
              .map((plan) =>
                          <div key={plan.id} className={`subscription-container__card ${
                            activeSubscription?.plan.title === plan.title ? 'subscription-container__card-selected' : ''
                          } ${loading ? 'subscription-container__card-loading' : ''} ${(pendingClientSubscriptionTransactions && pendingClientSubscriptionTransactions?.data?.length > 0 && !hasPendingSepaTransaction) ? 'subscription-container__card-disabled' : ''}`}>
                            <div className="row">
                              <div className="col-10">
                              <h2>{plan.title}</h2>
                              </div>
                              <div className="col-2 subscription-container__receipt">
                              <img
                                  onClick={() => handleShowModalInvoice(plan.id, true)}
                                  alt={'receipt'} src={AssetGallery.receiptImg}/>
                              </div>
                            </div>
                            <Pill text={`Access fees: € ${plan.monthlyFee}`} type={'white'}/>
                            <p>{plan.description}</p>
                            <div className="row">
                              <div className="col-12">
                              <Button
                                  disabled={activeSubscription?.plan.title === plan.title || (latestTransaction?.transactionStatus === 'pending' && latestTransaction?.transactionPaymentMethod !== 'sepa')}
                                  onClick={() => (!(activeSubscription?.plan.title === plan.title)) && handleSelectTier(plan.id, plan.title, plan.monthlyFee)}
                                  title="Proceed"/>
                              </div>
                              <div className="col-12 text-center text-decoration-underline" style={{ visibility: plan.details ? 'visible' : 'hidden', opacity: 0.8, fontSize: 15 }}
                              >
                                <small className='subscription-container__card-show' onClick={() => handleShowModalDetails(plan.id, true)}>Show more</small>
                              </div>
                                  </div>
                                </div>,
              )
                    }
                </div>
          {activeSubscription?.plan.title && (
              <div className="selected-tier">
                <h1>Your active tier is: {activeSubscription?.plan.title}</h1>
              </div>
          )}
          <EMEModal
              show={showModal}
              onHide={handleModalClose}
              title={
                (transactionData && transactionData?.redirect_url) || returnUrl
                  ? !returnUrl
                    ? loadingButton
                      ? 'Redirecting, please wait'
                      : 'Subscription Selection'
                    : 'Finalise your subscription'
                  : 'Something went wrong'
              }
          >
                {((transactionData && transactionData?.redirect_url) || returnUrl)  ?
                  <>
                    <div className="row">
                      <div className="col">
                        By clicking on “Proceed”:<br/>
                        <ul>
                          <li>
                            a services agreement between Excess Materials Exchange B.V. (<b>EME</b>)
                            and {client.title} (<b>User</b>) is concluded, to which the <a href={`${c.APP_ROUTES.CORE}/${TERMS_CONDITIONS_FILE}`} target="_blank"
                               rel="noopener noreferrer">EME general terms and conditions for services </a>
                            (the <b>General Terms</b>) apply. These General Terms, which inter alia include a
                            choice of forum for the competent court in Amsterdam, the Netherlands and a choice of law for the
                            laws of the Netherlands, can be viewed and saved by clicking on the hyperlink above.
                          </li>
                          <li>
                            User will be redirected to the payment environment for payment of the access fees.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                    <div className="col-auto">
                        <Button disabled={loadingButton} onClick={handleModalClose} title="Cancel"></Button>
                    </div>
                    <div className="col-auto">
                        <Button disabled={loadingButton} onClick={handleProceedToBank} title="Proceed"></Button>
                    </div>
                </div>
                  </>
                  :
                    <div className="row">
                        <div className="col-auto">
                          {errorMessage ?
                            errorMessage :
                            'Refresh the page and try again, if the issue persist contact an administrator.'}
                        </div>
                    </div> }
            </EMEModal>

          <EMEModal
              show={showModalInvoice}
              onHide={() => setShowModalInvoice(false)}
          >
            <Receipt
                index={planModal?.id}
                clientData={client}
                itemId={planModal?.id}
                itemName={planModal?.title}
                itemDescription={planModal?.description}
                itemPrice = {planModal?.monthlyFee}
                itemDetails={planModal?.details}
                invoiceType={InvoiceType.PLAN}
                termsAndConditions={`${c.APP_ROUTES.CORE}/${TERMS_CONDITIONS_FILE}`}
            />
          </EMEModal>
          <EMEModal
              show={showModalDetails}
              onHide={() => setShowModalDetails(false)}
          >
            <Markdown text={planModal?.details ?? ''}/>
          </EMEModal>
        </div>
      </View>
  );
};

export default Subscription;
