import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getOffers, isFetchingOffers } from '../../../selectors/Offer';
import { activateOffer, fetchOffers, storeOffer } from '../../../actions/Offer';
import { Offer } from '../../../types/Offer';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const OfferDataTable = () => {
  const offers = useSelector(getOffers);
  const isLoading = useSelector(isFetchingOffers);
  const [toggle, setToggle] = useState(false);
  const [offer, setActiveOffer] = useState<Offer | null>(null);
  const [refetch, setRefetch] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.OFFER_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.OFFER_UPDATE, row.id));
  };

  const onArchive = (row: Offer) => {
    setActiveOffer(row);
    setToggle(true);
  };

  const onUnArchive = (row: Offer) => {
    setActiveOffer(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (offer?.id) {
      if (unArchiveToggle){
        await activateOffer(offer?.id);
      } else {
        await storeOffer(offer?.id);
      }
      setRefetch(!refetch);
    }
    setActiveOffer(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'subject', label: 'Subject', mobileFriendly: false },
      { key: 'details', label: 'Details', mobileFriendly: true },
      { key: 'date', label: 'Date', mobileFriendly: true },
      { key: 'clientNumber', label: 'Client Number', mobileFriendly: false },
      { key: 'currency', label: 'Currency', mobileFriendly: false },
      { key: 'pickupLocation', label: 'Pickup Location', mobileFriendly: true },
      { key: 'collectionDate', label: 'Collection Date', mobileFriendly: true },
      { key: 'notesToEME', label: 'Notes to EME', mobileFriendly: false },
      { key: 'notesToShippingProvider', label: 'Notes to Shipping Provider', mobileFriendly: false },
      { key: 'clientTitle', label: 'Donor Client', mobileFriendly: false },
      { key: 'salesRepUserEmail', label: 'Sales Rep User', mobileFriendly: false },
      { key: 'shipmentContactEmail', label: 'Shipment Contact', mobileFriendly: false },
      { key: 'solutionTitle', label: 'Solution', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive },
    ],
    hideButton: true,
  };


  return (
        <View title="Offers" onAddNew={onCreate}>
            <DataTable
                fetchFunction={fetchOffers}
                data={offers?.data || []}
                isLoading={isLoading}
                paging={offers?.paging}
                config={config}
                dependencies={refetch}
            />
          <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Offer' : 'Archive Offer'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
            {unArchiveToggle ? 'Are you sure you want to unarchive this offer?' : 'Are you sure you want to archive this offer?'}
          </Modal>
        </View>
  );
};

export default OfferDataTable;
