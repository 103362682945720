import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import View from '../../components/View';
import '../../components/View/View.scss';
import { formatUrl } from '../../helpers/functions';
import { getLoggedInUser } from '../../selectors/Auth';
import { isFetchingOffers } from '../../selectors/Offer';
import c from '../../helpers/constants';
import useUserRoles from '../../hooks/userRoleHook';
import { useHistory } from 'react-router-dom';
import { Offer } from '../../types/Offer';

interface OfferRedirectProps {
  activeStatus: string;
  offerId: string;
  activeOfferObject: Offer | null;
}

const OfferRedirect: React.FC<OfferRedirectProps> = ({ activeStatus, activeOfferObject, offerId }) => {
  const history = useHistory();
  const { isABBUser, isABBClientAdmin, isEMESuperAdmin, isEMEClientAdmin } = useUserRoles();
  const loggedInUser = useSelector(getLoggedInUser);
  const isFetching = useSelector(isFetchingOffers);


  useEffect(() => {
    if (loggedInUser && activeOfferObject && activeOfferObject?.id === offerId && activeStatus === activeOfferObject?.statusCodes?.value) {
      const statusCode = activeOfferObject?.statusCodes?.value;

      if ((isEMESuperAdmin || isEMEClientAdmin) && statusCode === 'Pending Estimation') {
        history.push(formatUrl(c.APP_ROUTES.CREATE_ESTIMATION, offerId));
      } else if ((isEMESuperAdmin || isEMEClientAdmin) && statusCode === 'Changes Requested') {
        history.push(formatUrl(c.APP_ROUTES.CREATE_ESTIMATION, offerId));
      } else if ((isABBClientAdmin || isEMESuperAdmin) && statusCode === 'Pending Verification') {
        history.push(formatUrl(c.APP_ROUTES.REVIEW_OFFER, offerId));
      } else if ((isABBClientAdmin || isEMESuperAdmin) && statusCode === 'Pending Offer') {
        history.push(formatUrl(c.APP_ROUTES.REVIEW_ESTIMATION, offerId));
      } else if ((isABBUser || isEMESuperAdmin || isABBClientAdmin) && statusCode === 'Draft') {
        history.push(formatUrl(c.APP_ROUTES.REVIEW_OFFER, offerId));
      } else {
        history.push(c.APP_ROUTES.DEAL_HUB);
      }
    }
  }, [activeOfferObject, loggedInUser, isEMESuperAdmin, isEMEClientAdmin, isABBClientAdmin, isABBUser, offerId, history]);

  return (
      <View isLoading={isFetching}>
        Out of bounds!
      </View>
  );
};

export default OfferRedirect;
