import { ArrowClockwise, X } from 'react-bootstrap-icons';
import Input from '../Input';
import Button from '../Button';
import React, { useEffect, useState } from 'react';
import { useValidation } from '../../hooks/ValidationHook';
import { isRequired } from '../../helpers/validators';
import noImage from '../../assets/images/no-preview.png';
import { isPostingAsset } from '../../selectors/Asset';
import { useSelector } from 'react-redux';
import { useMobile } from '../../helpers/functions';
// import { Spinner } from 'react-bootstrap';


type Props = {
  file: any;
  index: number;
  onSubmit: (file: any, title: string, index: number) => void;
  onDelete: (index: number) => void;
  isValid?: boolean;
};

const FileForm = (props: Props) => {
  const { file, index, onSubmit, onDelete, isValid = true } = props;
  const isMobile = useMobile();
  const [title, setTitle] = useState(() => {
    const parts = file.name.split('.');
    parts.pop(); // Remove the last element (file extension)
    return parts.join('.');
  });
  const isPosting = useSelector(isPostingAsset);
  const formConfig = [
    { field: 'title', validators: [isRequired] },
  ];

  const [rotation, setRotation] = useState<{ [key: string]: number }>({});

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, []);

  const getMimeTypeFromExtension = (ext: string) => {
    const mimeTypes: { [key: string]: string } = {
      png: 'image/png',
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      gif: 'image/gif',
      bmp: 'image/bmp',
      webp: 'image/webp',
    };

    return mimeTypes[ext.toLowerCase()] || 'image/jpeg'; // Default to JPG
  };

  const rotateImageBeforeUpload = (f: any, degrees: number, callback: (rotatedFile: File) => void) => {
    const img = new Image();
    img.src = URL.createObjectURL(f);
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) return;

      // Set canvas dimensions for rotation
      if (degrees % 180 === 0) {
        canvas.width = img.width;
        canvas.height = img.height;
      } else {
        canvas.width = img.height;
        canvas.height = img.width;
      }

      const mimeType = getMimeTypeFromExtension(f.type);

      // Rotate image
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((degrees * Math.PI) / 180);
      ctx.drawImage(img, -img.width / 2, -img.height / 2);

      // Convert to Blob and create new File
      canvas.toBlob((blob) => {
        if (blob) {
          const rotatedFile = new File([blob], f.name, { type: f.type });
          callback(rotatedFile);
        }
      }, mimeType);
    };
  };


  const onInnerSubmit = (f: any, t: string) => {

    if (!isMobile ? (triggerValidation() && !isPosting) : !isPosting) {
      if (rotation[index]) {
        rotateImageBeforeUpload(f, rotation[index], (rotatedFile) => {
          onSubmit(rotatedFile, t, index);
        });
      } else {
        onSubmit(f, t, index);
      }
    }
  };

  const handleRotate = () => {
    setRotation((prev) => ({
      ...prev,
      [index]: ((prev[index] || 0) + 90) % 360, // Rotate in 90-degree steps
    }));
  };

  // Automatically submit form if title already exists
  useEffect(() => {
    if (title && file && isMobile ) {
      onInnerSubmit(file, title);
    }
  }, [title, file]);

  return (

        <div className="row " style={{ visibility: isMobile ? 'hidden' : 'visible', height: isMobile ? '1px' : '100%' }}>
            <div className="col-md-6">
                <div className="img-delete" onClick={() => onDelete(index)}>
                    <X />
                </div>
                <img
                    src={file.preview}
                    alt={file.path}
                    className={isValid ? 'img-preview img-thumbnail' : 'img-preview img-thumbnail error'}
                    style={{ transform: `rotate(${rotation[index] || 0}deg) scale(${rotation[index] % 180 !== 0 ? 0.8 : 1})`, transition: 'transform 0.3s ease' }} // Apply rotation
                    onError={(event) => (event.currentTarget.src = noImage)}
                />
                <div
                    className="file-picker__img-rotate"
                    onClick={handleRotate}
                    style={{ pointerEvents: isPosting ? 'none' : 'auto', opacity: isPosting ? 0.5 : 1 }}
                >
                  <ArrowClockwise />
                </div>
            </div>
            <div className="col-md-6">
                {isValid && (
                    <>
                        <Input
                            v={v}
                            err={err}
                            id="title"
                            label="Title"
                            required
                            onChange={setTitle}
                            onBlur={setTitle}
                            value={title}
                        />
                        {/*<Button title="Rotate" onClick={handleRotate} />*/}
                        <span className=''>&nbsp;</span>
                        <Button
                            title="Upload"
                            isLoading={isPosting}
                            disabled={!isFormValid() || !isValid}
                            onClick={() => onInnerSubmit(file, title)}
                        />
                    </>
                )}
            </div>
        </div>
  );
};

export default FileForm;
