import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import View from '../../components/View';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import c, { unitMappings, UnitType } from '../../helpers/constants';
import './EntryRenderer.scss';
import {
  duplicateEntryWithFields,
  fetchActiveEntry,
  fetchEntryByShortcodeGuest,
  updateEntryFromQR, updateEntryStatus,
  updateEntryWithFields,
} from '../../actions/Entry';
import { BoxArrowUpRight } from 'react-bootstrap-icons';
import QRCode from 'react-qr-code';

import { fetchingEntriesFailed, getActiveEntry, isFetchingEntries, isPostingEntry } from '../../selectors/Entry';

import { fetchEntryFields } from '../../actions/EntryField';
import { getEntryFields, isFetchingEntryFields } from '../../selectors/EntryField';
import { EntryField } from '../../types/EntryField';
import { fetchFieldProperties } from '../../actions/FieldProperty';
import { getFieldProperties } from '../../selectors/FieldProperty';
import CarouselPreview from '../../components/CarouselPreview';
import MaterialMap from '../../components/MaterialMap';
import { getCategories } from '../../selectors/Category';
import { fetchCategories } from '../../actions/Category';
import { Category } from '../../types/Category';
import Button from '../../components/Button';
import { formatUrl, getToken, getUserToken, redirectToBackendIfMatch, useMobile } from '../../helpers/functions';
import {
  fetchEntryHistory,
  fetchEntryHistoryByShortcodes,
  fetchEntryTimeline,
  fetchEntryTimelineByShortcodes,
} from '../../actions/EntryHistory';
import { fetchEntryFieldHistory, fetchEntryFieldHistoryByShortcodes } from '../../actions/EntryFieldHistory';
import { getEntryEdges, isFetchingEntryEdges } from '../../selectors/EntryEdge';
import { fetchEntryEdges } from '../../actions/EntryEdge';
import { matchMakeByEntry } from '../../actions/Match';
import ForceGraph from '../../components/ForceGraph';
import Timeline from '../../components/Timeline';
import noImage from '../../assets/images/no-preview.png';
import { getAssets } from '../../selectors/Asset';
import { getActiveConfiguration } from '../../selectors/Configuration';
import Modal from '../../components/Modal';
import { fetchFormFields } from '../../actions/FormField';
import { getFormFields } from '../../selectors/FormField';
import { getLoggedInRole } from '../../selectors/Auth';
import Input from '../../components/Input';
import Card from '../../components/Card/Card';
import assetGallery from '../../components/AssetGallery/AssetGallery';
import Tabs from '../../components/Tabs/Tabs';
import { fetchActiveEntryTagsByEntryId } from '../../actions/EntryTag';
import { getEntryTags } from '../../selectors/EntryTag';
import Pill from '../../components/Pill/Pill';
import ImpactOverview from '../MatchOverview/ImpactOverview';
import { getEntryTimeline } from '../../selectors/EntryHistory';
import { downloadQRCode, generateQRCodeValue } from '../../helpers/functions';
import Lightbox from '../../components/Lightbox/Lightbox';
import DataTable from '../../components/DataTable/DataTable';
import useShortcodes from '../../hooks/ShortcodeHook';
import LegalDisclaimer from '../../components/EntryLegalDisclaimer';
import { getEntryEdges as entryEdgesApi } from '../../api/EntryEdge';
import SEO from '../../components/SEO/SEO';

function ViewRenderer() {
  const dispatch = useDispatch();
  // @ts-ignore
  const {
    isUsingShortcodes,
    clientShortcode,
    groupShortcode,
  } = useShortcodes(c.APP_ROUTES.ENTRY_RENDERER_SHORTCODE, c.APP_ROUTES.ENTRY_RENDERER);

  let matchUpdate = !isUsingShortcodes ? useRouteMatch(c.APP_ROUTES.ENTRY_RENDERER) : useRouteMatch(c.APP_ROUTES.ENTRY_RENDERER_SHORTCODE);

  const activeEntry = useSelector(getActiveEntry);
  const categories = useSelector(getCategories);
  const entryFields = useSelector(getEntryFields);
  const formFields = useSelector(getFormFields);
  const entryEdges = useSelector(getEntryEdges);
  const userRole = useSelector(getLoggedInRole);
  const history = useHistory();
  const fieldProperties = useSelector(getFieldProperties);
  const allAssets = useSelector(getAssets);
  const isFetching = useSelector(isFetchingEntryFields);
  const isFetchingEntry = useSelector(isFetchingEntries);
  const isLoadingEntryEdges = useSelector(isFetchingEntryEdges);
  const isPosting = useSelector(isPostingEntry);
  const loggedIn = getUserToken();
  const isMobile = useMobile();
  const currentRole = useSelector(getLoggedInRole);
  const entryFailToLoad = useSelector(fetchingEntriesFailed);
  const activeConfig = useSelector(getActiveConfiguration);
  const entryTags = useSelector(getEntryTags);
  const entryTimeline = useSelector(getEntryTimeline);
  const [QRView, setQRView] = useState(false);
  const [showGeoLocationModal, setShowGeoLocationModal] = useState(false);
  const [showPleaseWait, setShowPleaseWait] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [numberOfDuplicates, setNumberOfDuplicates] = useState(0);
  const queryParams = new URLSearchParams(window.location.search);
  const QRSParam = queryParams.get('source') === 'QRScan';
  const [startingNumber, setStartingNumber] = useState(undefined);
  const qrCodeRef = useRef<HTMLDivElement>(null);
  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [geolocError, setGeolocError] = useState('');
  const [geocodeError, setGeocodeError] = useState('');
  const [showReservedModal, setShowReservedModal] = useState(false);
  const [hasEntryEdges, setHasEntryEdges] = useState(false);

  const mapField = formFields?.data.filter(field => field.fieldTypeTitle === 'map');
  let activeCategories: Category[] = [];
  // @ts-ignore
  entryFields?.data?.forEach(fp => {
    const found = activeCategories.find(ccc => ccc.id === fp.categoryId);
    if (!found) {
      const cat = categories?.data.find(cc => cc.id === fp.categoryId);
      if (cat) {
        activeCategories.push(cat);
      }
    }
  });

  activeCategories = activeCategories.sort((a, b) => a.order < b.order ? -1 : 1);

  const onLoginToView = () => {
    history.push(c.APP_ROUTES.USER_LOGIN);
  };

  const onProcessingButton = () => {
    if (activeEntry && !isFetchingEntry){
      dispatch(updateEntryFromQR(activeEntry.id, activeEntry.groupId, activeEntry.clientId, activeEntry.formId, activeEntry.assetId,
        activeEntry.title, activeEntry.description, activeEntry.active, 'Processing'));
    }
  };

  const onShippedButton = () => {
    if (activeEntry && !isFetchingEntry){
      dispatch(updateEntryFromQR(activeEntry.id, activeEntry.groupId, activeEntry.clientId, activeEntry.formId, activeEntry.assetId,
        activeEntry.title, activeEntry.description, activeEntry.active, 'Shipped'));
    }
  };

  const onReceivedButton = () => {
    if (activeEntry && !isFetchingEntry){
      dispatch(updateEntryFromQR(activeEntry.id, activeEntry.groupId, activeEntry.clientId, activeEntry.formId, activeEntry.assetId,
        activeEntry.title, activeEntry.description, activeEntry.active, 'Received'));
    }
  };

  const onCanceledButton = () => {
    if (activeEntry && !isFetchingEntry){
      dispatch(updateEntryFromQR(activeEntry.id, activeEntry.groupId, activeEntry.clientId, activeEntry.formId, activeEntry.assetId,
        activeEntry.title, activeEntry.description, activeEntry.active, 'Canceled'));
    }
  };

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // @ts-ignore
  const [entryId, setEntryId] = useState(matchUpdate?.params?.id);

  useEffect(() => {
    if (entryId && !groupShortcode && !clientShortcode && !isUsingShortcodes) {
      dispatch(fetchCategories());
      dispatch(fetchActiveEntry(entryId));
    } else if (entryId && groupShortcode && clientShortcode) {
      redirectToBackendIfMatch( clientShortcode, groupShortcode, entryId );
      dispatch(fetchCategories({ fetchParams: { 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode } }));
      dispatch(fetchEntryByShortcodeGuest(entryId, groupShortcode, clientShortcode));
    }
    if (queryParams.has('QRControl') && queryParams.get('QRControl') === 'true') {
      setQRView(true);
    }
  }, [dispatch]);

  useEffect(() => {
    // @ts-ignore
    if (matchUpdate?.params?.id !== entryId) {
      // @ts-ignore
      setEntryId(matchUpdate?.params?.id);
      if (isUsingShortcodes && groupShortcode && clientShortcode){
        // @ts-ignore
        dispatch(fetchEntryByShortcodeGuest(matchUpdate?.params?.id, groupShortcode, clientShortcode));
      } else {
        // @ts-ignore
        dispatch(fetchActiveEntry(matchUpdate?.params?.id));
      }
    }
    // @ts-ignore
  }, [matchUpdate?.params?.id]);

  useEffect(() => {
    if (entryId && activeEntry && !groupShortcode && !clientShortcode) {
      dispatch(fetchEntryFields({ searchField: 'entryId', searchWord: entryId }));
      dispatch(fetchFormFields({ searchField:'formId', searchWord: activeEntry?.formId }));
      dispatch(fetchFieldProperties({ searchField:'formId', searchWord: activeEntry?.formId }));
      dispatch(fetchEntryHistory(entryId));
      dispatch(fetchEntryTimeline(entryId));
      dispatch(fetchEntryFieldHistory(entryId));
      dispatch(fetchActiveEntryTagsByEntryId(entryId));
      dispatch(matchMakeByEntry(entryId));
    } else if (entryId && activeEntry && groupShortcode && clientShortcode) {
      dispatch(fetchEntryFields({ searchField: 'entryId', searchWord: entryId,
        fetchParams: { 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode } }));
      dispatch(fetchFormFields({ searchField:'formId', searchWord: activeEntry?.formId,
        fetchParams: { 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode } }));
      dispatch(fetchFieldProperties({ searchField:'formId', searchWord: activeEntry?.formId,
        fetchParams: { 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode } }));
      dispatch(fetchEntryHistoryByShortcodes(groupShortcode, clientShortcode, entryId));
      dispatch(fetchEntryTimelineByShortcodes(groupShortcode, clientShortcode, entryId));
      dispatch(fetchEntryFieldHistoryByShortcodes(groupShortcode, clientShortcode, entryId));
      dispatch(fetchActiveEntryTagsByEntryId(entryId));
    }
  }, [activeEntry]);

  useEffect(() => {
    // Check if the user is on a mobile device
    // Check if this is the first page the user is loading
    if ((isMobile && history.length <= 2) && QRSParam) {
      setShowGeoLocationModal(true);
    }
  }, [history]);

  useEffect(() => {
    const checkEntryEdges = async () => {
      if (activeEntry) {
        const response = await entryEdgesApi({
          searchWord: activeEntry.id,
        });
        setHasEntryEdges(response.data.length > 0);
      }
    };

    checkEntryEdges();
  }, [activeEntry]);

  const filterAssets = (assetId: string) => {
    return allAssets?.data.find(item => item.id === assetId);
  };

  const renderMapField = (entryField: EntryField) => {
    const parts = entryField?.value?.split('~');
    return (
        <>
          <div className='entry-category__title'>{entryField?.fieldTitle}:</div>
            <div className='entry-category__container'>
              <div className="row" key={entryField.id}>
                {/* @ts-ignore */}
                <MaterialMap isEntryRenderer={true} address={parts[3]} zoom={14} lat={parseFloat(parts[0])} lng={parseFloat(parts[1])}/>
          </div>
        </div>
          </>);
  };

  const renderCarouselField = (entryField: EntryField) => {
    const urls = entryField?.value?.split(',').map(u => `${c.API_ENDPOINTS.ASSET_FILE}/${u}`);
    return (urls.length > 0 && <>
      <div className="entry-category__title">{entryField?.fieldTitle}:</div>
      <div className="entry-category__container">
           <CarouselPreview imageUrls={urls}/>
      </div>
    </>);
  };

  const renderTextField = (entryField: EntryField) => {
    let passportUnit = '';
    let unitSymbol = '';

    const fieldProperty = fieldProperties?.data.find(fp => fp.fieldId === entryField?.fieldId && fp.fieldTypeOption === 'isPrivate');
    const passportUnitsOptions = fieldProperties?.data.find(e => e.fieldId === entryField?.fieldId && e.fieldTypeOption === 'passportUnits');
    if (passportUnitsOptions){
      passportUnit = passportUnitsOptions.value;
      unitSymbol = unitMappings[passportUnit as UnitType];
    }
    const isPrivate = fieldProperty?.value === 'true';

    return (
        <div key={entryField.id} className='col-md-6'>
          <div className="entry-category__label">
            <div>{entryField?.fieldTitle}:</div>
          </div>
          <div className="entry-category__sub-label">
            {!getToken() && isPrivate ? (
                <Button title="Login To View" onClick={onLoginToView} />
            ) : (
                <div>{entryField?.value} {unitSymbol}</div>
            )}
          </div>
        </div>
    );
  };

  const renderFileField = (entryField: EntryField) => {
    if (entryField.value != '') {
      return (<div className="row my-3" key={entryField.id}>

        <h5>{entryField?.fieldTitle}:</h5>

        <div className="col">
          {entryField?.value.split(',').map((im: string) => entryField?.value.length > 0 &&
              <>
                <iframe src={`${c.API_ENDPOINTS.ASSET_FILE}/${im}`} className="img-preview img-thumbnail"
                        onError={(event) => event.currentTarget.src = noImage}/>
                <p className={'mx-4 file-pill'}>{filterAssets(im)?.title} <span
                    className="badge rounded-pill bg-info text-dark">.{filterAssets(im)?.extension}</span> <a
                    target="_blank"
                    href={`${c.API_ENDPOINTS.ASSET_FILE}/${im}`}
                    rel="noreferrer">
                  <BoxArrowUpRight/>
                </a>
                </p>
              </>,
          )}
        </div>
      </div>);
    }
  };

  const renderEntry = (categoryId: string) => {
    const filteredFields = entryFields?.data?.filter(e => categoryId === e.categoryId);
    console.log(filteredFields, 'filtered fields');

    // Process the fields and render them
    const renderedFields = filteredFields
      ?.sort((a, b) => (a.order < b.order ? -1 : 1))
      ?.map(e => {
        switch (e.fieldType) {
          case 'map':
          case 'carousel':
            return null;
          case 'file':
          case 'decompositionImage':
            return renderFileField(e);
          case 'dropdown':
          case 'text':
          case 'epc':
          case 'sku':
          case 'textArea':
          case 'requiredEnergyInJoule':
            return renderTextField(e);
          default:
            return <div>Unknown Field</div>;
        }
      })
      ?.filter(Boolean); // Remove null values

    // Return null if no fields are rendered
    if (!renderedFields?.length) {
      return null;
    }

    return <>{renderedFields}</>;
  };

  const renderMedia = (categoryId: string) => {
    const decide = (e: EntryField) => {
      if (categoryId !== e.categoryId) {
        return;
      }
      switch (e.fieldType) {
        case 'map':
          return null;
        case 'file':
          return null;
        case 'decompositionImage':
          return null;
        case 'dropdown':
          return null;
        case 'text':
          return null;
        case 'textArea':
          return null;
        case 'epc':
          return null;
        case 'sku':
          return null;
        case 'requiredEnergyInJoule':
          return null;
        case 'carousel':
          return renderCarouselField(e);
        default:
          return <div>Unknown Media</div>;
      }
    };
    return (
        <div>
          {entryFields?.data?.sort((a, b) => a.order < b.order ? -1 : 1)?.map(e => decide(e))}
        </div>
    );
  };

  const renderLocation = (categoryId: string) => {
    const decide = (e: EntryField) => {
      if (categoryId !== e.categoryId) {
        return;
      }
      switch (e.fieldType) {
        case 'map':
          return renderMapField(e);
        case 'file':
          return null;
        case 'decompositionImage':
          return null;
        case 'dropdown':
          return null;
        case 'text':
          return null;
        case 'textArea':
          return null;
        case 'epc':
          return null;
        case 'sku':
          return null;
        case 'requiredEnergyInJoule':
          return null;
        case 'carousel':
          return null;
        default:
          return <div>Unknown Location</div>;
      }
    };
    return (
        <div>
          {entryFields?.data?.sort((a, b) => a.order < b.order ? -1 : 1)?.map(e => decide(e))}
        </div>
    );
  };

  const renderCategories = () => {
    const tabs = activeCategories
      ?.map(category => {
        const content = renderEntry(category.id);
        if (content === null) return null; // Skip categories with no content
        return {
          label: category.title,
          content: (
                <div className="row justify-content-between align-items-start">
                  {content}
                </div>
          ),
        };
      })
      ?.filter((tab): tab is { label: string; content: JSX.Element } => tab !== null); // Type guard to remove nulls

    // If no valid tabs exist, return null
    if (!tabs || tabs.length === 0) {
      return null;
    }

    return <Tabs tabs={tabs} />;
  };

  const renderLarge = () => {
    if (activeCategories.length > 0) {
      return (
          <>
              {activeCategories?.map(category => (
                  <div className="col-12 mb-3" key={category.id}>
                    {/* @ts-ignore */}
                    {renderMedia(category.id)}
                  </div>
              ))}
            <div className="my-3"/>
          </>
      );
    } else {
      return null;
    }
  };

  const renderLocationInForm = () => {
    return (activeCategories?.map(category => (
        <div className="col-12 mb-3" key={category.id}>
          {/* @ts-ignore */}
          {renderLocation(category.id)}
        </div>
    )));
  };

  const onNavigate = (theEntryId: string) => {
    if (isUsingShortcodes && groupShortcode && clientShortcode){
      history.push(formatUrl(c.APP_ROUTES.ENTRY_RENDERER_SHORTCODE, groupShortcode, clientShortcode, theEntryId));
    } else {
      history.push(formatUrl(c.APP_ROUTES.ENTRY_RENDERER, theEntryId));
    }
  };

  const renderChildEdges = () => {
    const config = {
      columns: [
        { key: 'toEntryTitle', label: 'Child Title', mobileFriendly: true },
        { key: 'toEntryDescription', label: 'Child Description', mobileFriendly: true },
      ],
      actions: [],
      hideButton: true,
      pageSizes : [6, 10, 20, 30],
    };

    return (
        <div className="row">
          <DataTable
              paging={entryEdges?.paging}
              data={entryEdges?.data || []}
              config={config}
              dependencies={2}
              isLoading={isLoadingEntryEdges}
              fetchFunction={fetchEntryEdges}
              fetchParams={isUsingShortcodes && { 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode }}
              baseSearch={{ 'fromEntryId':entryId }}
              isTable={false}
              lineItemTemplate={(row: any) => (
                  <div key={row.id} className="col-md-4">
                    <Card
                        onClick={() => onNavigate(row.toEntryId)}
                        cardId={row.id}
                        title={row.toEntryTitle}
                        description={row.toEntryDescription}
                        imageUrl={`${c.API_ENDPOINTS.ASSET_FILE}/${row.toEntryAssetId}`}
                    />
                  </div>
              )}
          />
        </div>
    );
  };

  const renderParentEdges = () => {
    const config = {
      columns: [
        { key: 'fromEntryTitle', label: 'Parent Title', mobileFriendly: true },
        { key: 'fromEntryDescription', label: 'Parent Description', mobileFriendly: true },
      ],
      actions: [],
      hideButton: true,
      pageSizes : [6, 10, 20, 30],
    };

    return (
        <div className="row">
          <DataTable
            paging={entryEdges?.paging}
            data={entryEdges?.data || []}
            dependencies={1}
            config={config}
            isLoading={isLoadingEntryEdges}
            fetchFunction={fetchEntryEdges}
            fetchParams={isUsingShortcodes && { 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode }}
            baseSearch={{ 'toEntryId': entryId }}
            isTable={false}
            lineItemTemplate={(row: any) => (
                <div key={row.id} className="col-md-4 h-100">
                  <Card
                      onClick={() => onNavigate(row.fromEntryId)}
                      cardId={row.id}
                      title={row.fromEntryTitle}
                      description={row.fromEntryDescription}
                      imageUrl={`${c.API_ENDPOINTS.ASSET_FILE}/${row.fromEntryAssetId}`}
                  />
                </div>
            )}
        />
        </div>
    );
  };


  const hasAssets = () => {
    const assets = entryFields?.data?.filter(ef => ef.fieldType === 'carousel').map(a => a.value.split(',').map(u=> `${c.API_ENDPOINTS.ASSET_FILE}/${u}`));
    return (assets && assets?.length > 0);
  };

  const assetsPre = entryFields?.data?.filter(ef => ef.fieldType === 'carousel' && ef.value != '' && ef.entryId === activeEntry?.id).map(a => a.value.split(',').map(u=> `${c.API_ENDPOINTS.ASSET_FILE}/${u}`));
  const assets = assetsPre?.flat();

  if (assets){
    assets?.unshift(`${c.API_ENDPOINTS.ASSET_FILE}/${activeEntry?.assetId}`);
  }

  // Items for the force graph
  const childEdges = entryEdges?.data?.filter(ee => ee.fromEntryId === activeEntry?.id);
  const parentEdges = entryEdges?.data?.filter(ee => ee.toEntryId === activeEntry?.id);
  const isDataIncomplete = (
    activeEntry === null ||
      entryFields === null ||
      assets === undefined ||
      isFetching ||
      isFetchingEntry
  );

  const renderRelationships = () => {
    const tabs = [
      {
        label: 'Parent Connections',
        icon: assetGallery.connectionsImg,
        content: renderParentEdges(),
      },
      {
        label: 'Child Connections',
        icon: assetGallery.connectionsImg,
        content: renderChildEdges(),
      },
    ];

    return (
        <div className="row align-items-start">
          <div className="col-xl-4 col-lg-12">
            <ForceGraph
                parentItems={parentEdges}
                childItems={childEdges}
                activeEntry={activeEntry}/>
          </div>
          <div className="col-xl-8 col-lg-12 offwhite-container">
            <Tabs tabs={tabs} />
          </div>
        </div>
    );
  };

  useEffect(() => {
    if (entryFailToLoad){
      history.push(c.APP_ROUTES.ERROR_404);
    }
  }, [entryFailToLoad]);

  const toEdit = () => {
    history.push(formatUrl(c.APP_ROUTES.FORM_RENDERER_EDIT, activeEntry?.id));
  };

  async function reverseGeocode(latitude: number, longitude: number) {
    try {
      const language = 'en';
      const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`, {
        headers: {
          'Accept-Language': language,
        },
      });
      const data = await response.json();

      if (data.address) {
        const street = data.address.road || data.address.pedestrian || data.address.footway || data.address.path;
        const city = data.address.city || data.address.town || data.address.village || data.address.hamlet;
        const country = data.address.country;

        // Create the formatted address
        return [street, city, country].filter(Boolean).join(', ');
      }
    } catch (error) {
      setGeocodeError('Reverse geocoding error: ' + error);
    }

    return 'Unknown Location';
  }

  useEffect(() => {
    if (entryId && activeEntry && !groupShortcode && !clientShortcode) {
      dispatch(fetchEntryFieldHistory(entryId));
      dispatch(fetchEntryTimeline(entryId));
    } else if (entryId && activeEntry && groupShortcode && clientShortcode) {
      dispatch(fetchEntryFieldHistoryByShortcodes(groupShortcode, clientShortcode, entryId));
      dispatch(fetchEntryTimelineByShortcodes(groupShortcode, clientShortcode, entryId));
    }
  }, [entryFields]);


  const handleUpdateGeoLocation = () => {
    setShowPleaseWait(true);
    navigator.geolocation.getCurrentPosition(async (position) => {
      const { latitude, longitude } = position.coords;
      const location = await reverseGeocode(latitude, longitude);
      const combinedCoords = `${latitude}~${longitude}~4~${location}`;
      if (mapField) {
        const sortedMapField = [...mapField];
        sortedMapField.sort((a, b) => {
          const dateA = new Date(a.created).getTime();
          const dateB = new Date(b.created).getTime();
          return dateA - dateB;
        });
        if (sortedMapField.length > 0) {
          const oldestMapField = sortedMapField[0];

          // @ts-ignore
          oldestMapField.value = combinedCoords;

          if (activeEntry) {
            if (isUsingShortcodes){
              await dispatch(updateEntryWithFields(activeEntry?.id, activeEntry?.title, activeEntry?.description, activeEntry?.assetId,
                activeEntry?.active, [oldestMapField], [], [], [], undefined,
                { fetchParams: { 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode } }));
              await dispatch(fetchEntryFields({ searchField: 'entryId', searchWord: activeEntry?.id,
                fetchParams: { 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode } }));
            } else {
              await dispatch(updateEntryWithFields(activeEntry?.id, activeEntry?.title, activeEntry?.description, activeEntry?.assetId, activeEntry?.active, [oldestMapField], [], [], []));
              await dispatch(fetchEntryFields({ searchField: 'entryId', searchWord: activeEntry?.id }));
            }
            setShowPleaseWait(false);
          }
        }
      }
      setShowGeoLocationModal(false);
    }, (error) => {
      // Handle geolocation error
      setGeolocError('Geolocation error: ' + error.message);
    });
  };

  const onDismiss = () => {
    setShowGeoLocationModal(false);
  };

  const onShowDuplicate = () => {
    setShowDuplicateModal(true);
  };

  const reserveThisEntry = () => {
    setShowReservedModal(true);
    window.scrollTo({ top: 0, behavior: 'auto' });

  };

  const onReservedModalSubmit = () => {
    if (loggedIn && activeEntry){
      dispatch(updateEntryStatus(entryId, 'Reserved', activeEntry.formId, true));
    } else {
      localStorage.setItem('returnUrl', formatUrl(c.APP_ROUTES.ENTRY_RENDERER, entryId) );
      history.push(c.APP_ROUTES.USER_LOGIN);
    }
    setShowReservedModal(false);
  };

  const onDismissReservedModal = () => {
    setShowReservedModal(false);
  };


  const onHideDuplicate = () => {
    setShowDuplicateModal(false);
  };

  const handleDuplicateEntry = () => {
    if (activeEntry){
      dispatch(duplicateEntryWithFields(activeEntry?.id, numberOfDuplicates, startingNumber));
      history.push(c.APP_ROUTES.ENTRY);
    }
  };

  const toFullSizeAsset = (assetId: string | undefined) => {
    const fullSizeAssetURL = `${c.API_ENDPOINTS.ASSET_FILE}/${assetId}`;
    setSelectedImage(fullSizeAssetURL);
    setShowLightbox(true);
  };

  const onEdit = () => {
    if (loggedIn && !isDataIncomplete){
      toEdit();
    } else if (!loggedIn){
      localStorage.setItem('returnUrl', formatUrl(c.APP_ROUTES.FORM_RENDERER_EDIT, entryId));
      history.push(c.APP_ROUTES.USER_LOGIN);
    }
  };



  return (
      <View
          title={loggedIn && !isDataIncomplete ? (activeEntry?.title || undefined) : undefined}
          onEdit={(!isUsingShortcodes && userRole && userRole?.accessLevel < 3000) && loggedIn && !isDataIncomplete ? toEdit : undefined}
          isLoading={isDataIncomplete}
      >
      <SEO image={`${c.API_ENDPOINTS.ASSET_FILE}/${activeEntry?.assetId}`} title={activeEntry?.title} description={activeEntry?.description} type={'Product'}/>
              <div>
                {showLightbox && (
                    <Lightbox imageUrl={selectedImage} onClose={() => setShowLightbox(false)} />
                )}
                <div className="entry-category__title">Main Information</div>
                <div className="entry-top">
                    <div className="row">
                      <div className='col-xl-6 col-md-12'>
                        <div className="entry-top__position">
                        <div className="entry-top__frame">
                          <img className="entry-top__image" src={`${c.API_ENDPOINTS.ASSET_FILE}/${activeEntry?.assetId}`} alt={'primary-asset'} onError={(event) => event.currentTarget.src = noImage} />
                          {<img className="entry-top__image__full-size" src={assetGallery.zoomImg} alt='fullsize' onClick={() => toFullSizeAsset(activeEntry?.assetId)} onError={(event) => event.currentTarget.src = noImage} />}
                          {activeEntry && activeConfig?.enableQR == true && (
                              <div className="entry-top__qr-code" ref={qrCodeRef}>
                                <QRCode
                                    value={generateQRCodeValue(activeEntry)}
                                />
                                <div onClick={() => downloadQRCode(qrCodeRef)} className='entry-top__qr-code__download'>
                                  <img src={assetGallery.downloadImg} alt='qr-download'/>
                                </div>
                              </div>
                          )}
                        </div>
                      </div>
                      </div>
                      <div className="col-xl-6 col-md-12 d-flex flex-column justify-content-center mt-4">
                          <div className="entry-category__label">
                            Title
                            {' '}
                            {currentRole?.accessLevel === 0 && <Button title={'Duplicate Entry'} onClick={onShowDuplicate}/>}
                          </div>
                          <div className="entry-category__sub-label">
                            {activeEntry?.title}
                          </div>

                          <div className="entry-category__label">
                            Description
                          </div>
                          <div className="entry-category__sub-label">
                            {activeEntry?.description}
                          </div>
                        <div className="entry-category__sub-label">
                          {(activeConfig?.reservedStatus ) ?   <Button title={activeEntry?.status === 'Reserved' ? 'Reserved' : 'Reserve this entry'} disabled={activeEntry?.status === 'Reserved'} onClick={reserveThisEntry}/> : null}
                          <Modal show={showReservedModal} title={'Are you sure you want to reserve this item?'}
                                 onHide={onDismissReservedModal}>
                                  <div>
                                    You are about to reserve an item on the platform. This action will take the entry off the market by setting its status to reserved under your account. If you proceed, you will not be able to reverse this action unless an administrator intervenes, and no other user will be able to reserve this item. Once you confirm your reservation, you will receive an email with the reservation details and next steps.
                                  </div>
                              <div className="mt-3 form-renderer-button-container">
                                <Button title="Cancel"  onClick={() => onDismissReservedModal()}/>
                                <Button title="Confirm" onClick={onReservedModalSubmit}/>
                              </div>
                          </Modal>

                        </div>
                        {entryTags && <div>
                          <div className="entry-category__label">
                          Tags
                          </div>
                          {entryTags?.data.map((tag, index) => (
                              <Pill
                                  key={index}
                                  text={tag.tagValue}
                                  type="white"
                                  icon="tag"
                              />
                          ))}
                        </div>}
                          {(activeConfig?.enableQR == true && QRView) &&
                            <div className={'row'}>
                              <span>Set Status:</span>
                              <Button title="Processing" disabled={isFetchingEntry || isPosting} onClick={onProcessingButton}/>
                              <Button title="Shipped" disabled={isFetchingEntry || isPosting} onClick={onShippedButton}/>
                              <Button title="Received" disabled={isFetchingEntry || isPosting} onClick={onReceivedButton}/>
                              <Button title="Canceled" disabled={isFetchingEntry || isPosting} onClick={onCanceledButton}/>
                            </div>}
                      </div>
                    </div>
                </div>
                {hasAssets() &&
                    renderLarge()
                }
                <div className='mb-3'>
                {renderCategories()}
                </div>
                {(entryTimeline && entryTimeline?.data.length > 0) && <div className="col-12">
                  <div className='entry-category__title'>Tracking & Tracing</div>
                  <div className='entry-category__container'>
                    <Timeline entryId={activeEntry?.id} clientShortCode={clientShortcode} groupShortCode={groupShortcode} />
                  </div>
                </div>}
                {hasEntryEdges && <div className="col-12">
                  <div className="entry-category__title">Connections</div>
                  <div className="entry-category__container">
                    {activeEntry && renderRelationships()}
                  </div>
                </div>}
                <div className="my-3"/>
              <div className="col-12">
                  <div className="entry-category__title">Solutions</div>
                  <div className="entry-category__container">
                    <ImpactOverview/>
                  </div>
                </div>
                {renderLocationInForm()}
                <LegalDisclaimer />
              </div>
                <Modal show={showGeoLocationModal && !isDataIncomplete} title="QR Code Scanned" onHide={onDismiss}>
                  {!showPleaseWait && <span>You have just scanned a QR code! Would you like to update the geolocation details or edit the information of this item?</span>}
                  <div className="row mt-2">
                    <div className="col-6 d-flex justify-content-start">
                      <Button title="Update Geolocation" onClick={handleUpdateGeoLocation} disabled={showPleaseWait} />
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                      <Button title="Edit Information" onClick={onEdit} disabled={showPleaseWait} />
                    </div>
                  </div>
                  {showPleaseWait && !geolocError && !geocodeError && <span>Please wait, updating geolocation...</span>}
                  {geolocError && <span>{geolocError}</span>}
                  {geocodeError && <span>{geocodeError}</span>}
                </Modal>
            <Modal show={showDuplicateModal} title={'Duplicate Entry'} onHide={onHideDuplicate} cta={'Duplicate'} onCta={handleDuplicateEntry} buttonDisabled={numberOfDuplicates <= 0 || !activeEntry}>
              <Input name="numberOfDuplicates"
                     id="numberOfDuplicates"
                     label="Number of Duplicates"
                     required
                     disabled={!activeEntry}
                     value={numberOfDuplicates}
                     onChange={setNumberOfDuplicates}
                     onBlur={setNumberOfDuplicates}
                     placeholder="Insert number of duplicates"
              />
              <Input name="startingNumber"
                     id="startingNumber"
                     label="Starting Number"
                     disabled={!activeEntry}
                     value={startingNumber}
                     onChange={setStartingNumber}
                     onBlur={setStartingNumber}
                     placeholder="Select the first duplicant's number or leave empty"
              />
            </Modal>
      </View>
  );
}

export default ViewRenderer;
