import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getFields, isFetchingFields, isPostingField, postingFieldValidationErrors } from '../../../selectors/Field';
import {
  activateField,
  addFieldToMultipleClients,
  fetchFields,
  storeField,
} from '../../../actions/Field';
import { Field } from '../../../types/Field';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';
import Input from '../../../components/Input';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import CheckBox from '../../../components/CheckBox';
import DropDown from '../../../components/DropDown';
import { getFieldTypes } from '../../../selectors/FieldType';
import { fetchClients } from '../../../actions/Client';
import { fetchFieldTypes } from '../../../actions/FieldType';
import { fetchFieldTypeOptions } from '../../../actions/FieldTypeOption';
import { getClients } from '../../../selectors/Client';
import { getFieldTypeOptions } from '../../../selectors/FieldTypeOption';
import MultiSelectWithPills from '../../../components/DropDownMultiselect';
import Button from '../../../components/Button';
import { getLoggedInRole } from '../../../selectors/Auth';

const FieldDataTable = () => {

  const dispatch = useDispatch();
  const isPosting = useSelector(isPostingField);
  const isFetching = useSelector(isFetchingFields);
  const fields = useSelector(getFields);
  const isLoading = useSelector(isFetchingFields);
  const fieldTypes = useSelector(getFieldTypes);
  const clients = useSelector(getClients);
  const loggedInRole = useSelector(getLoggedInRole);
  const fieldTypeOptions = useSelector(getFieldTypeOptions);
  const [toggle, setToggle] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);
  const [field, setActiveField] = useState<Field | null>();
  const [refetch, setRefetch] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [title, setTitle] = useState('');
  const [fieldTypeId, setFieldTypeId] = useState('');
  const [categoryToMatch, setCategoryToMatch] = useState('');
  const [formToMatch, setFormToMatch] = useState('');
  const [selectedClients, setSelectedClients] = useState<{ label: string; value: string; }[]>([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [fieldTypeOptionId, setFieldTypeOptionId] = useState('');
  const [fieldPropertyValue, setFieldPropertyValue] = useState('');
  const [createCategory, setCreateCategory] = useState(false);
  const postingValidationErrors = useSelector(postingFieldValidationErrors);
  const [itemsClients, setItemsClients] = useState<{ label: string; value: string; }[]>([]);


  const formConfig = [
    { field: 'title', validators: [isRequired] },
    { field: 'fieldTypeId', validators: [isRequired] },
    { field: 'categoryToMatch', validators: [isRequired] },
    { field: 'formToMatch', validators: [isRequired] },
  ];


  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
    setToggleModal(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.FIELD_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.FIELD_UPDATE, row.id));
  };

  const onArchive = (row: Field) => {
    setActiveField(row);
    setToggle(true);
  };

  const onUnArchive = (row: Field) => {
    setActiveField(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (field?.id) {
      if (unArchiveToggle){
        await activateField(field?.id);
      } else {
        await storeField(field?.id);
      }
      setRefetch(!refetch);
    }
    setActiveField(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);


  const onAddFieldToClients = async () => {
    const selectedClientValues = selectedClients.map(client => client.value);
    if (triggerValidation()){
      dispatch(addFieldToMultipleClients(title, fieldTypeId, categoryToMatch,
        formToMatch, selectedClientValues, createCategory, fieldTypeOptionId, fieldPropertyValue));
    }
    setRefetch(!refetch);
    setToggleModal(false);
  };

  useEffect(() => {
    dispatch(fetchClients());
    dispatch(fetchFieldTypes());
    dispatch(fetchFieldTypeOptions());
  }, [dispatch]);

  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'categoryTitle', label: 'Category', mobileFriendly: true },
      { key: 'fieldTypeTitle', label: 'Field Type', mobileFriendly: true },
      { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: false },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: 'Archive', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive }],
    hideButton: true,
  };

  const itemsFieldTypes = fieldTypes?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsFieldTypeOptions = fieldTypeOptions?.data.map(r => ({ label: r.key, value: r.id })) || [];

  const onFieldTypeSelect = (obj: any) => {
    setFieldTypeId(obj.value);
  };

  const onClientsSelect = (obj: any) => {
    setSelectedClient(obj.value);
    setItemsClients(current => current.filter(t => t.label !== obj.label));
    if (selectedClients && selectedClients.length > 0) {
      setSelectedClients([
        ...selectedClients,
        { value: obj.value, label: obj.label },
      ]);
    } else {
      setSelectedClients([
        { value: obj.value, label: obj.label },
      ]);
    }
  };
  const onClientsUnselect = (id: any, email: any) => {
    setItemsClients([...itemsClients, { label: email, value: id },
    ] );
    setSelectedClients([
      ...selectedClients.filter(e => e.value != id),
    ]);
    setSelectedClient('');
  };

  const onFieldTypeOptionSelect = (obj: any) => {
    setFieldTypeOptionId(obj.value);
  };

  useEffect(() => {
    if (clients) {
      setItemsClients(clients.data.map(client => ({ label: client.title, value: client.id })));
    }
  }, [clients]);
  
  return (
      <View title="Fields" onAddNew={onCreate}>
        {loggedInRole?.accessLevel === 0 && <Button title={'Add Field To Clients'} onClick={() => setToggleModal(true)}/>}
        <DataTable fetchFunction={fetchFields} paging={fields?.paging}
                 isLoading={isLoading}  data={fields?.data || []} config={config} dependencies={refetch} />
        <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Entry' : 'Archive Entry'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
          {unArchiveToggle ? 'Are you sure you want to unarchive this entry?' : 'Are you sure you want to archive this entry?'}
        </Modal>
        <Modal show={toggleModal} title={'Add Field to Multiple Clients'} cta={'Create'} onCta={onAddFieldToClients} buttonDisabled={!isFormValid()} onHide={onDismiss}>
          <Input v={v} err={err}
                 name="title"
                 id="title"
                 label="Title"
                 required
                 value={title}
                 onChange={setTitle}
                 disabled={isPosting || isFetching}
                 onBlur={setTitle}
                 placeholder="Insert a title"
          />
          <Input v={v} err={err}
                 name="categoryToMatch"
                 id="categoryToMatch"
                 label="Category To Match"
                 required
                 value={categoryToMatch}
                 onChange={setCategoryToMatch}
                 disabled={isPosting || isFetching}
                 onBlur={setCategoryToMatch}
                 placeholder="Insert the category under which the field is to be added"
          />
          <Input v={v} err={err}
                 name="formToMatch"
                 id="formToMatch"
                 label="Form To Match"
                 required
                 value={formToMatch}
                 onChange={setFormToMatch}
                 disabled={isPosting || isFetching}
                 onBlur={setFormToMatch}
                 placeholder="Insert the form under which the field is to be added"
          />
          <DropDown v={v} err={err}
                    id="fieldTypeId"
                    placeholder="Please select a Field Type"
                    required
                    label="Field Type"
                    items={itemsFieldTypes}
                    disabled={isPosting || isFetching}
                    value={fieldTypeId}
                    onSelect={onFieldTypeSelect}
                    type="default"
          />
          <MultiSelectWithPills
              v={v} err={err}
              id="selectedClientIds"
              placeholder="Please select clients"
              label="Clients"
              items={itemsClients}
              value={selectedClient}
              selectedItems={(selectedClients ?? []).map(e => ({ id: e.value, label: e.label }))}
              onSelect={onClientsSelect}
              onUnselect={onClientsUnselect}
              disabled={isPosting || isFetching}
          />
          <CheckBox label="If missing, create category" checked={createCategory} onChange={setCreateCategory}/>

          <Input v={v} err={err}
                 name="fieldPropertyValue"
                 id="fieldPropertyValue"
                 label="Field Property Value"
                 value={fieldPropertyValue}
                 onChange={setFieldPropertyValue}
                 disabled={isPosting || isFetching}
                 onBlur={setFieldPropertyValue}
                 placeholder="Insert the field property value"
          />

          <DropDown v={v} err={err}
                    id="fieldTypeOptionId"
                    placeholder="Please select a Field Type Option"
                    label="Field Type Option"
                    items={itemsFieldTypeOptions}
                    disabled={isPosting || isFetching}
                    value={fieldTypeOptionId}
                    onSelect={onFieldTypeOptionSelect}
                    type="default"
          />

        </Modal>
    </View>
  );
};

export default FieldDataTable;

