import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import CheckBox from '../../../components/CheckBox';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createConfiguration, fetchActiveConfiguration,  updateConfiguration } from '../../../actions/Configuration';
import {
  getActiveConfiguration,
  isPostingConfiguration,
  postingConfigurationError,
  postingConfigurationFailed,
  postingConfigurationValidationErrors,
  isFetchingConfigurations,
} from '../../../selectors/Configuration';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { getGroups } from '../../../selectors/Group';
import { getClients } from '../../../selectors/Client';
import { fetchGroups } from '../../../actions/Group';
import { fetchClients } from '../../../actions/Client';
import Button from '../../../components/Button';
import { getRoleClients } from '../../../selectors/RoleClient';
import { fetchRoleClients } from '../../../actions/RoleClient';
import TextArea from '../../../components/TextArea';

const ConfigurationForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const groups = useSelector(getGroups);
  const clients = useSelector(getClients);
  const roles = useSelector(getRoleClients);
  const [itemsRoles, setItemsRoles] = useState(roles?.data.map(r => ({ label: r.roleTitle, value: r.roleId })) || []);
  const [itemsClients, setItemsClients] = useState(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const activeConfiguration = useSelector(getActiveConfiguration);
  const isPosting = useSelector(isPostingConfiguration);
  const isFetching = useSelector(isFetchingConfigurations);
  const postingFailed = useSelector(postingConfigurationFailed);
  const postingError = useSelector(postingConfigurationError);
  const postingValidationErrors = useSelector(postingConfigurationValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.CONFIGURATION_UPDATE);

  const validateMaxUploadSize = (value: any) => {
    const maxUploadSizeMB = value / (1024 * 1024);
    if (maxUploadSizeMB > 50) {
      return 'Maximum upload size cannot exceed 50 MB'; //maximum allowed value from php.ini
    }
    if (maxUploadSizeMB < 10) {
      return 'Minimum upload size must be at least 10 MB';
    }
    return false;
  };

  const formConfig = [
    { field: 'groupId', validators: [isRequired] },
    { field: 'clientId', validators: [isRequired] },
    { field: 'primaryLogo', validators: []  },
    { field: 'secondaryLogo', validators: []  },
    { field: 'favicon', validators: []  },
    { field: 'defaultRole', validators: []  },
    { field: 'welcomeContent', validators: []  },
    { field: 'allowedFileTypes', validators: []  },
    { field: 'maxUploadSize', validators: [validateMaxUploadSize]  },
    { field: 'customRedirectUrl', validators: []  },
    { field: 'webCommitmentIntegration', validators: []  },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);


  const [groupId, setGroupId] = useState('');
  const [clientId, setClientId] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('#f5f5f5');
  const [foregroundColor, setForegroundColor] = useState('#ffffff');
  const [primaryActionColor, setPrimaryActionColor] = useState('#1f4b86');
  const [secondaryActionColor, setSecondaryActionColor] = useState('#3b7fc7');
  const [successColor, setSuccessColor] = useState('#388924');
  const [warningColor, setWarningColor] = useState('#ffb824');
  const [dropdownColor, setDropdownColor] = useState('#d2d1d6');
  const [primaryTextColor, setPrimaryTextColor] = useState('#2f71be');
  const [secondaryTextColor, setSecondaryTextColor] = useState('#212126');
  const [primaryLogo, setPrimaryLogo] = useState('');
  const [secondaryLogo, setSecondaryLogo] = useState('');
  const [favicon, setFavicon] = useState('');
  const [defaultRole, setDefaultRole] = useState('');
  const [welcomeContent, setWelcomeContent] = useState('');
  const [allowedFileTypes, setAllowedFileTypes] = useState('');
  const [maxUploadSize, setMaxUploadSize] = useState(0);
  const [customRedirectUrl, setCustomRedirectUrl] = useState('');
  const [webCommitmentIntegration, setWebCommitmentIntegration] = useState(false);
  const [enableAI, setEnableAI] = useState(false);
  const [executeAIWizardOnUpdate, setExecuteAIWizardOnUpdate] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [enableQR, setEnableQR] = useState(false);
  const [enableAuctionPayments, setEnableAuctionPayments] = useState(false);
  const [reservedStatus, setReservedStatus] = useState(false);
  const [notifyOnNewEntry, setNotifyOnNewEntry] = useState(false);

  const [allowAnonymousViewFetch, setAllowAnonymousViewFetch] = useState(false);
  const [allowAnonymousEntryFetch, setAllowAnonymousEntryFetch] = useState(false);
  const [active, setActive] = useState(true);
  const [disabledClient, setDisabledClients] = useState(true);
  const [disabledRole, setDisabledRoles] = useState(true);

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchClients());
    dispatch(fetchRoleClients());
    if (editMode) {
      dispatch(fetchActiveConfiguration(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    setItemsClients(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  }, [clients]);

  useEffect(() => {
    setItemsRoles(roles?.data.map(r => ({ label: r.roleTitle, value: r.roleId })) || []);
  }, [roles]);

  useEffect(() => {
    if (activeConfiguration && editMode) {
      setGroupId(activeConfiguration?.groupId);
      setClientId(activeConfiguration?.clientId);
      setBackgroundColor(activeConfiguration?.backgroundColor);
      setForegroundColor(activeConfiguration?.foregroundColor);
      setPrimaryActionColor(activeConfiguration?.primaryActionColor);
      setSecondaryActionColor(activeConfiguration?.secondaryActionColor);
      setSuccessColor(activeConfiguration?.successColor);
      setWarningColor(activeConfiguration?.warningColor);
      setDropdownColor(activeConfiguration?.dropdownColor);
      setPrimaryTextColor(activeConfiguration?.primaryTextColor);
      setSecondaryTextColor(activeConfiguration?.secondaryTextColor);
      setPrimaryLogo(activeConfiguration?.primaryLogo);
      setSecondaryLogo(activeConfiguration?.secondaryLogo);
      setFavicon(activeConfiguration?.favicon);
      setMaxUploadSize(activeConfiguration.maxUploadSize || 0);
      if (activeConfiguration?.defaultRole){
        setDefaultRole(activeConfiguration?.defaultRole);
      } else {
        setDefaultRole('');
      }
      if (activeConfiguration?.welcomeContent){
        setWelcomeContent(activeConfiguration?.welcomeContent);
      } else {
        setWelcomeContent('Default');
      }
      if (activeConfiguration?.allowedFileTypes){
        setAllowedFileTypes(activeConfiguration?.allowedFileTypes);
      } else {
        setAllowedFileTypes('');
      }
      if (activeConfiguration?.customRedirectUrl){
        setCustomRedirectUrl(activeConfiguration?.customRedirectUrl);
      } else {
        setCustomRedirectUrl('');
      }
      // @ts-ignore
      setWebCommitmentIntegration(activeConfiguration?.webCommitmentIntegration === 1 || activeConfiguration?.webCommitmentIntegration === true);
      // @ts-ignore
      setEnableAI(activeConfiguration?.enableAI === 1 || activeConfiguration?.enableAI === true);
      // @ts-ignore
      setEnableAuctionPayments(activeConfiguration?.enableAuctionPayments === 1 || activeConfiguration?.enableAuctionPayments === true);
      // @ts-ignore
      setExecuteAIWizardOnUpdate(activeConfiguration?.executeAIWizardOnUpdate === 1 || activeConfiguration?.executeAIWizardOnUpdate === true);
      // @ts-ignore
      setIsPremium(activeConfiguration?.isPremium === 1 || activeConfiguration?.isPremium === true);
      // @ts-ignore
      setEnableQR(activeConfiguration?.enableQR === 1 || activeConfiguration?.enableQR === true);
      // @ts-ignore
      setReservedStatus(activeConfiguration?.reservedStatus === 1 || activeConfiguration?.reservedStatus === true);
      // @ts-ignore
      setNotifyOnNewEntry(activeConfiguration?.notifyOnNewEntry === 1 || activeConfiguration?.notifyOnNewEntry === true);
      // @ts-ignore
      setAllowAnonymousViewFetch(activeConfiguration?.allowAnonymousViewFetch === 1 || activeConfiguration?.allowAnonymousViewFetch === true);
      // @ts-ignore
      setAllowAnonymousEntryFetch(activeConfiguration?.allowAnonymousEntryFetch === 1 || activeConfiguration?.allowAnonymousEntryFetch === true);
      // @ts-ignore
      setActive(activeConfiguration?.active === 1 || activeConfiguration?.active === true);
    }
  }, [activeConfiguration]);


  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.CONFIGURATION);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createConfiguration(groupId, clientId, backgroundColor,
        foregroundColor,
        primaryActionColor,
        secondaryActionColor,
        successColor,
        warningColor,
        dropdownColor,
        primaryTextColor,
        secondaryTextColor,
        primaryLogo,
        secondaryLogo,
        favicon, defaultRole, welcomeContent, allowedFileTypes, maxUploadSize,
        customRedirectUrl, webCommitmentIntegration, enableAI, enableAuctionPayments, executeAIWizardOnUpdate, isPremium, enableQR, reservedStatus,
        notifyOnNewEntry, allowAnonymousViewFetch, allowAnonymousEntryFetch, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateConfiguration( activeId, groupId, clientId, backgroundColor,
        foregroundColor,
        primaryActionColor,
        secondaryActionColor,
        successColor,
        warningColor,
        dropdownColor,
        primaryTextColor,
        secondaryTextColor,
        primaryLogo,
        secondaryLogo,
        favicon, defaultRole, welcomeContent, allowedFileTypes, maxUploadSize,
        customRedirectUrl, webCommitmentIntegration, enableAI, enableAuctionPayments, executeAIWizardOnUpdate, isPremium, enableQR, reservedStatus,
        notifyOnNewEntry, allowAnonymousViewFetch, allowAnonymousEntryFetch, active));
    }
  };

  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];


  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
    setItemsClients(clients?.data.filter(data => data.groupId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledClients(false);
  };

  const onClientSelect = (obj: any) => {
    setClientId(obj.value);
    setItemsRoles(roles?.data.filter(data => data.clientId.includes(obj.value)).map(r => ({ label: r.roleTitle, value: r.roleId })) || []);
    setDisabledRoles(false);
  };
  
  const onRoleSelect = (obj: any) => {
    setDefaultRole(obj.value);
  };


  return <View>
        <h4>{editMode ? 'Edit' : 'Create'} Configuration</h4>
        {postingError && (
            <Alert className="mt-3" variant="danger">
                {postingError}
            </Alert>
        )}
        <form>

            <DropDown v={v} err={err}
                      id="groupId"
                      placeholder="Please select a Group"
                      required label="Group"
                      items={itemsGroups}
                      value={groupId}
                      disabled={isPosting || isFetching}
                      onSelect={onGroupSelect}
                      type="default"
            />
            <DropDown v={v} err={err}
                      id="clientId"
                      placeholder="Please select a Client"
                      required label="Client"
                      items={itemsClients}
                      value={clientId}
                      disabled={isPosting || isFetching || disabledClient}
                      onSelect={onClientSelect}
                      type="default"
            />
            <DropDown v={v} err={err}
                      id="defaultRole"
                      placeholder="Please select a default Role"
                      required label="Role"
                      items={itemsRoles}
                      value={defaultRole}
                      disabled={isPosting || isFetching || disabledRole}
                      onSelect={onRoleSelect}
                      type="default"
            />

            <Input v={v} err={err}
                   name="primaryLogo"
                   id="primaryLogo"
                   label="Primary Logo"
                   required
                   value={primaryLogo}
                   onChange={setPrimaryLogo}
                   disabled={isPosting || isFetching}
                   onBlur={setPrimaryLogo}
                   placeholder="Insert a title"
            />

            <Input v={v} err={err}
                   name="secondaryLogo"
                   id="secondaryLogo"
                   label="Secondary Logo"
                   required
                   value={secondaryLogo}
                   onChange={setSecondaryLogo}
                   disabled={isPosting || isFetching}
                   onBlur={setSecondaryLogo}
                   placeholder="Insert a title"
            />

            <Input v={v} err={err}
                   name="favicon"
                   id="favicon"
                   label="Favicon"
                   required
                   value={favicon}
                   onChange={setFavicon}
                   disabled={isPosting || isFetching}
                   onBlur={setFavicon}
                   placeholder="Insert a title"
            />

            <TextArea v={v} err={err}
                   name="welcomeContent"
                   id="welcomeContent"
                   label="Welcome Page Content (HTML)"
                   value={welcomeContent}
                   onChange={setWelcomeContent}
                   disabled={isPosting || isFetching}
                   onBlur={setWelcomeContent}
                   placeholder="Insert html content here"
            />

            <Input v={v} err={err}
                   name="allowedFileTypes"
                   id="allowedFileTypes"
                   label="List of allowed file types"
                   value={allowedFileTypes}
                   onChange={setAllowedFileTypes}
                   disabled={isPosting || isFetching}
                   onBlur={setAllowedFileTypes}
                   placeholder="Define allowed file types (.png, .pdf etc.)"
            />
            <Input v={v} err={err}
                   name="customRedirectUrl"
                   id="customRedirectUrl"
                   label="Custom Redirect Url"
                   value={customRedirectUrl}
                   onChange={setCustomRedirectUrl}
                   disabled={isPosting || isFetching}
                   onBlur={setCustomRedirectUrl}
                   placeholder="Insert a custom redirect URL (for QR codes)"
            />
            <Input v={v} err={err}
                   name="maxUploadSize"
                   id="maxUploadSize"
                   type="number"
                   label="Maximum allowed file size"
                   value={maxUploadSize}
                   onChange={setMaxUploadSize}
                   disabled={isPosting || isFetching}
                   onBlur={setMaxUploadSize}
                   placeholder="Please provide a number (measured in bytes)"
            />
            ({maxUploadSize / (1024 * 1024)} MB)
            <div className="row my-5">
                <div className="col-md-4">
                    <div className="form-group">
                        <CheckBox label="Is Active?" checked={active} onChange={setActive} />
                    </div>
                    <div className="form-group">
                        <CheckBox label="Notify Client Admins on new Entries" checked={notifyOnNewEntry} onChange={setNotifyOnNewEntry} />
                    </div>
                    <div className="form-group">
                        <CheckBox label="Premium" checked={isPremium} onChange={setIsPremium} />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <CheckBox label="WCom Integration" checked={webCommitmentIntegration} onChange={setWebCommitmentIntegration} />
                    </div>
                    <div className="form-group">
                        <CheckBox label="Enable AI" checked={enableAI} onChange={value => {
                          setEnableAI(value);
                          if (!value) {
                            setExecuteAIWizardOnUpdate(false); // Set executeAIWizardOnUpdate to false if enableAI is false
                          }
                        }} />
                    </div>
                    {enableAI && (
                        <div className="form-group">
                            <CheckBox label="Execute AI Wizard on Entry Update" checked={executeAIWizardOnUpdate} onChange={setExecuteAIWizardOnUpdate} />
                        </div>
                    )}
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <CheckBox label="Enable QR" checked={enableQR} onChange={setEnableQR}/>
                    </div>
                    <div className="form-group">
                        <CheckBox label="Enable Auction Payments" checked={enableAuctionPayments} onChange={setEnableAuctionPayments} />
                    </div>
                    <div className="form-group">
                        <CheckBox label="Enable Reserved Status" checked={reservedStatus} onChange={setReservedStatus}/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                            onClick={() => editMode ? onSave() : onSubmit()}
                            title={editMode ? 'SAVE' : 'CREATE'}/>
                </div>
            </div>
        </form>
    </View>;
};

export default ConfigurationForm;