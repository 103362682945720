import React, { useEffect } from 'react';
import View from '../../components/View';
import { useDispatch, useSelector } from 'react-redux';
import { fetchActiveClient } from '../../actions/Client';
import { useHistory, useRouteMatch } from 'react-router-dom';
import c from '../../helpers/constants';
import './ClientProfile.scss';
import { getActiveClient } from '../../selectors/Client'; // Import your CSS file
import { FaEnvelope, FaPhone } from 'react-icons/fa';
import Button from '../../components/Button/Button';
import { getLoggedInRole } from '../../selectors/Auth';
import { fetchRoleClients } from '../../actions/RoleClient';
import { getRoleClients } from '../../selectors/RoleClient';
import { formatUrl } from '../../helpers/functions';
import useShortcodes from '../../hooks/ShortcodeHook';
import noImage from '../../assets/images/no-preview.png';

const ClientProfile = () => {
  const {
    isUsingShortcodes,
    clientShortcode,
    groupShortcode,
  } = useShortcodes(c.APP_ROUTES.CLIENT_PROFILE_SHORTCODE, c.APP_ROUTES.CLIENT_PROFILE);
  const dispatch = useDispatch();
  const matchUpdate = isUsingShortcodes ? useRouteMatch(c.APP_ROUTES.CLIENT_PROFILE_SHORTCODE) : useRouteMatch(c.APP_ROUTES.CLIENT_PROFILE);
  // @ts-ignore
  const clientId = matchUpdate?.params.id;
  const history = useHistory();

  const activeClient = useSelector(getActiveClient);
  const activeRole = useSelector(getLoggedInRole);
  const roleClients = useSelector(getRoleClients);
  const canEdit =
        roleClients?.data &&
        activeRole?.id &&
        activeClient?.id &&
        roleClients.data.some(roleClient =>
          roleClient.roleId === activeRole.id && roleClient.clientId === activeClient.id,
        ) &&
        (activeRole.accessLevel === 1000 || activeRole.accessLevel === 0);

  useEffect(() => {
    if (isUsingShortcodes){
      dispatch(fetchActiveClient(clientId, { fetchParams: { 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode } }));
    } else {
      dispatch(fetchActiveClient(clientId));
    }
    if (activeRole){
      dispatch(fetchRoleClients({ searchField: 'roleId', searchWord: activeRole?.id }));
    }
  }, [dispatch, activeRole]);

  const onUpdate = () => {
    if (activeClient){
      history.push(formatUrl(c.APP_ROUTES.CLIENT_UPDATE, activeClient.id));
    }
  };

  return (
    <View>
      <div className="page">
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="client-section">
              <div className="client-section--content-position">
              <div className="client-section__logo--position">
                <div className="client-section__logo--frame">
                  {activeClient?.primaryLogo && <img src={`${c.API_ENDPOINTS.ASSET_FILE}/${activeClient.primaryLogo}`} alt="Client Logo" className="client-section__logo" onError={(event) => event.currentTarget.src = noImage} />}
                </div>
              </div>
              <div className="client-section__box--grey mb-5">
                  <div className="client-section__items--position mb-4">
                    <h2>{activeClient?.title}</h2>
                    <div className="">
                      {activeClient?.phone && (
                        <a href={`tel:${activeClient.phone}`}>
                          <FaPhone size={32} className="client-section__icon"/>
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="client-section__items--position-second mb-4">
                    <h3>Location</h3>
                    <div className="">
                      {activeClient?.email && (
                        <a href={`mailto:${activeClient.email}`}>
                          <FaEnvelope size={32} className="client-section__icon"/>
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="mb-4">
                    <span className="fw-bold">Full Description: </span>
                    {activeClient?.description && (
                      <span className="">{activeClient.description}</span>
                    )}
                  </div>
                  <div className="">
                    {activeClient?.website && (
                      <a className="client-section__visit-website--styling fw-bold" href={activeClient.website}>
                        Visit our website
                      </a>
                    )}
                  </div>
                </div>
                  {canEdit && <div className="client-section__button--position">
                      <Button title={'Edit'} onClick={onUpdate} disabled={!activeClient}/>
                  </div>}
              </div>
              </div>
          </div>
        </div>
      </div>
    </View>
  );
};

export default ClientProfile;
