import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../../actions/Auth';
import {
  isPostingUser,
  postingUserError,
  postingUserFailed,
  postingUserValidationErrors,
  isFetchingUsers,
} from '../../../selectors/User';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { fetchLoggedInUser } from '../../../actions/Auth';
import HeadlessView from '../../../components/HeadlessView';
import { formatUrl } from '../../../helpers/functions';
import useShortcodes from '../../../hooks/ShortcodeHook';
import { getActiveConfiguration } from '../../../selectors/Configuration';

const UserForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isPosting = useSelector(isPostingUser);
  const isFetching = useSelector(isFetchingUsers);
  const postingFailed = useSelector(postingUserFailed);
  const postingError = useSelector(postingUserError);
  const postingValidationErrors = useSelector(postingUserValidationErrors);
  const activeConfig = useSelector(getActiveConfiguration);

  const {
    groupShortcode,
    clientShortcode,
    isUsingShortcodes,
  } = useShortcodes(c.APP_ROUTES.USER_LOGIN_SHORTCODE, c.APP_ROUTES.USER_LOGIN);



  const formConfig = [
    { field: 'email', validators: [isRequired] },
    { field: 'password', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');



  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      dispatch(fetchLoggedInUser());
      if (localStorage.getItem('returnUrl')){
        history.push(localStorage.getItem('returnUrl'));
        localStorage.setItem('returnUrl', '');
        // history.push(c.APP_ROUTES.PROFILE);
      } else {
        history.push(c.APP_ROUTES.PROFILE);
      }
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(loginUser(email, password));
    }
  };

  const onRegister = () => {
    if ((groupShortcode && clientShortcode )){
      history.push(formatUrl(c.APP_ROUTES.USER_REGISTER_SHORTCODE, groupShortcode, clientShortcode));
    } else {
      history.push(c.APP_ROUTES.USER_REGISTER);
    }
  };
   

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  const toLandingPage = () => {
    history.push(c.APP_ROUTES.LANDING_PAGE);
  };


  return <HeadlessView title="Log in to EXCESS MATERIALS Exchange" clientLogo={isUsingShortcodes ? activeConfig?.primaryLogo : undefined}>
                    {postingError && (
                        <Alert className="mt-lg-3" variant="danger">
                            {postingError}
                        </Alert>
                    )}
      <form>
          <Input v={v} err={err}
                 name="email"
                 id="email"
                 label="Email Address"
                 required
                 value={email}
                 onChange={setEmail}
                 disabled={isPosting || isFetching}
                 onBlur={setEmail}
                 onKeyDown={onKeyDown} // add this line
                 placeholder="Insert a email"
                 autoFocus={true}
          />
          <Input v={v} err={err}
                 required
                 name="password"
                 id="password"
                 type="password"
                 label="Password"
                 value={password}
                 disabled={isPosting || isFetching}
                 onChange={setPassword}
                 onBlur={setPassword}
                 onKeyDown={onKeyDown} // add this line
                 placeholder="Insert a password"
          />

          <div className="pb-lg-3 pb-1">
              <a href="resetPassword">Forgot password?</a>
          </div>
          <div className="pb-lg-3 pb-1">
              Don&apos;t have an account? <div className="userform__link" onClick={onRegister}>Register here.</div>
          </div>
          <div className="pb-lg-3 pb-1">
              <div className="userform__link" onClick={toLandingPage}>No account? No problem! Click here to explore our platform.</div>
          </div>
          <div className="my-lg-1 my-1">
              By using the EME platform you consent to our <a target="_blank"
                                                              href="/ExcessMaterialsExchange-Terms-Conditions.pdf">Terms
              & Conditions</a>.
          </div>
          <div className="row">
              <div className="col-md-4 mt-lg-3 mt-1">
                  <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                          onClick={onSubmit}
                          title={'LOGIN'}/>
              </div>
          </div>
      </form>
  </HeadlessView>;
};

export default UserForm;



