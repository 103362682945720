import { ApiDataModel, FormError } from './Common';

export const FETCH_AUCTION_ENTRIES_REQUEST = 'FETCH_AUCTION_ENTRIES_REQUEST';
export const FETCH_AUCTION_ENTRIES_RESPONSE = 'FETCH_AUCTION_ENTRIES_RESPONSE';
export const FETCH_AUCTION_ENTRIES_FAILURE = 'FETCH_AUCTION_ENTRIES_FAILURE';

export const FETCH_AUCTION_ENTRY_REQUEST = 'FETCH_AUCTION_ENTRY_REQUEST';
export const FETCH_AUCTION_ENTRY_RESPONSE = 'FETCH_AUCTION_ENTRY_RESPONSE';
export const FETCH_AUCTION_ENTRY_FAILURE = 'FETCH_AUCTION_ENTRY_FAILURE';

export const POST_AUCTION_ENTRY_REQUEST = 'POST_AUCTION_ENTRY_REQUEST';
export const POST_AUCTION_ENTRY_RESPONSE = 'POST_AUCTION_ENTRY_RESPONSE';
export const POST_AUCTION_ENTRY_FAILURE = 'POST_AUCTION_ENTRY_FAILURE';

export interface AuctionEntry {
  id: string;
  auctionId: string;
  entryId: string;
  winnerUserId: string;
  transactionId: string;
  startingBid: string;
  startDateTime: string;
  endDateTime: string;
  bidIncrement: string;
  entryTitle: string;
  entryDescription: string;
  entryAsset: string;
  highestBidAmount: string;
  currentlyWinningUserId: string;
}

export interface AuctionEntryDataModel extends Omit<ApiDataModel, 'data'> {
  data: AuctionEntry[];
}

export interface AuctionEntryState {
  auctionEntries: AuctionEntryDataModel | null,
  activeAuctionEntry: AuctionEntry | null,
  isFetchingAuctionEntries: boolean;
  fetchingAuctionEntriesFailed: boolean;
  postingAuctionEntryFailed: boolean;
  isPostingAuctionEntry: boolean;
  postingAuctionEntryValidationErrors: FormError[] | null;
  postingAuctionEntryError: string | null;
}

interface FetchAuctionEntriesRequestAction {
  type: typeof FETCH_AUCTION_ENTRIES_REQUEST;
  payload: null;
}

interface FetchAuctionEntriesResponseAction {
  type: typeof FETCH_AUCTION_ENTRIES_RESPONSE;
  payload: AuctionEntryDataModel;
}

interface FetchAuctionEntriesFailureAction {
  type: typeof FETCH_AUCTION_ENTRIES_FAILURE;
  payload: null;
}

interface FetchAuctionEntryRequestAction {
  type: typeof FETCH_AUCTION_ENTRY_REQUEST;
  payload: null;
}

interface FetchAuctionEntryResponseAction {
  type: typeof FETCH_AUCTION_ENTRY_RESPONSE;
  payload: AuctionEntry;
}

interface FetchAuctionEntryFailureAction {
  type: typeof FETCH_AUCTION_ENTRY_FAILURE;
  payload: null;
}

interface PostAuctionEntryRequestAction {
  type: typeof POST_AUCTION_ENTRY_REQUEST;
  payload: null;
}

interface PostAuctionEntryResponseAction {
  type: typeof POST_AUCTION_ENTRY_RESPONSE;
  payload: null;
}

interface PostAuctionEntryFailureAction {
  type: typeof POST_AUCTION_ENTRY_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type AuctionEntryActionTypes =
    | FetchAuctionEntriesRequestAction
    | FetchAuctionEntriesResponseAction
    | FetchAuctionEntriesFailureAction
    | PostAuctionEntryRequestAction
    | PostAuctionEntryResponseAction
    | PostAuctionEntryFailureAction
    | FetchAuctionEntryRequestAction
    | FetchAuctionEntryResponseAction
    | FetchAuctionEntryFailureAction;
