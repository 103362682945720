import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getAuction(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.AUCTION}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params: {
        'providedUuid': id,
        'publicType': 'auction',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getAuctions(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.AUCTION,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getAuctionsPlusPublic(
  dataTableParams?: DataTableParamsModel,
) {
    

  return (
    axios({
      url: c.API_ENDPOINTS.AUCTIONS_PUBLIC,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params: {
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postAuction( 
  groupId: string,
  clientId: string,
  title: string, 
  description: string, 
  assetId: string, 
  startingBid: string,
  startDateTime: string,
  endDateTime: string,
  bidIncrement: string,
  visibility: string,
  currency: string,
) {
  return (
    axios({
      url: c.API_ENDPOINTS.AUCTION,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        title,
        description,
        assetId,
        startingBid,
        startDateTime,
        endDateTime,
        bidIncrement,
        visibility,
        currency,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postAuctionWithItems(
  auctionData: Object,
  selectedItems: Object,
) {
  return (
    axios({
      url: c.API_ENDPOINTS.AUCTION_WITH_ITEMS,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        auctionData,
        selectedItems,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putAuction(
  id: string,
  groupId:string,
  clientId: string,
  title: string, 
  description: string,
  assetId: string, 
  startingBid: string, 
  startDateTime: string,
  endDateTime: string,
  bidIncrement: string,
  visibility: string,
  currency: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.AUCTION}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        id,
        groupId,
        clientId,
        title,
        description,
        assetId,
        startingBid,
        startDateTime,
        endDateTime,
        bidIncrement,
        visibility,
        currency,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveAuction(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.AUCTION}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function unarchiveAuction(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.AUCTION}/${id}/unarchive`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

