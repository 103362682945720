import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '../../components/Button';
import { createEntriesFromXLSX } from '../../actions/Entry';
import { useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';

interface XLSXDropzoneProps {
  onFileRead?: (data: Record<string, string>[]) => void;
  onClear?: () => void;
  formId?: string;
  toggleRefetch?: () => void;
}

interface ParsedRow {
  [key: string]: string;
}

const XLSXDropzone: React.FC<XLSXDropzoneProps> = ({ onFileRead, onClear, formId, toggleRefetch }) => {
  const [parsedData, setParsedData] = useState<Record<string, string>[] | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const processParsedData = (data: ParsedRow[]) => {
    if (data.length < 1) {
      setErrors(['The file is empty or contains no data.']);
      return;
    }

    const headers = Object.keys(data[0]).map(header => header.trim().toLowerCase());
    const hasTitle = headers.some(header => header.startsWith('title'));
    const hasDescription = headers.some(header => header.startsWith('description'));

    if (!hasTitle || !hasDescription) {
      setErrors(['The file must contain both "Title" and "Description" columns.']);
      return;
    }

    const formIdMismatchRows: ParsedRow[] = [];
    const missingFieldsRows: ParsedRow[] = [];
    const specificErrors: string[] = [];

    const validData = data.filter((row, index) => {
      const titleKey = Object.keys(row).find(key => key.toLowerCase().startsWith('title'));
      const descriptionKey = Object.keys(row).find(key => key.toLowerCase().startsWith('description'));

      let rowErrors: string[] = [];

      if (!titleKey || !row[titleKey]?.trim()) rowErrors.push('Title');
      if (!descriptionKey || !row[descriptionKey]?.trim()) rowErrors.push('Description');

      if (rowErrors.length > 0) {
        specificErrors.push(`Row ${index + 2} is missing the following field(s): ${rowErrors.join(', ')}.`);
        missingFieldsRows.push(row);
        return false;
      }

      const rowFormId = titleKey?.split('~')[1]?.trim();
      if (formId && rowFormId !== formId) {
        formIdMismatchRows.push(row);
        return false;
      }

      return true;
    });

    if (missingFieldsRows.length > 0) {
      specificErrors.push('Some rows are missing required fields (Title or Description).');
    }

    if (formIdMismatchRows.length > 0) {
      specificErrors.push('The provided file does not match the current view.');
    }

    if (validData.length === 0 && specificErrors.length === 0) {
      specificErrors.push('The file contains no valid data to import. A Title and Description are required.');
    }

    if (specificErrors.length > 0) {
      setErrors(specificErrors);
      if (onFileRead) onFileRead([]);
    } else {
      setParsedData(validData);
      if (onFileRead) onFileRead(validData);
    }
  };


  const parseFile = (file: File) => {
    setErrors([]);
    setSuccessMessage('');
    setParsedData(null);

    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    if (fileExtension === 'xlsx') {
      // Parse XLSX file
      const reader = new FileReader();
      reader.onload = (event) => {
        const binaryStr = event.target?.result;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const data: ParsedRow[] = XLSX.utils
          .sheet_to_json<ParsedRow>(sheet, { defval: '' }) // Ensure empty cells are treated as empty strings
          .filter((row: ParsedRow) => Object.values(row).some(value => value.trim() !== '')); // Remove empty rows
        processParsedData(data);
      };
      reader.onerror = () => setErrors(['Error reading the XLSX file.']);
      reader.readAsBinaryString(file);
    } else {
      setErrors(['Unsupported file type. Please upload a XLSX file.']);
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      setErrors(['Please upload a valid XLSX file.']);
      return;
    }
    parseFile(acceptedFiles[0]);
  }, []);

  const handleCreateEntries = async () => {
    if (parsedData) {
      setLoading(true);
      setSuccessMessage('');
      try {
        await dispatch(createEntriesFromXLSX(parsedData));
        if (toggleRefetch) toggleRefetch();
        setSuccessMessage(`${parsedData.length} items have been successfully created.`);
        setShowSuccessMessage(true);
        setParsedData(null);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClear = () => {
    setParsedData(null);
    if (onClear) onClear();
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] },
    maxFiles: 1,
  });

  const style: React.CSSProperties = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    ...(isFocused ? { borderColor: '#2196f3' } : {}),
    ...(isDragAccept ? { borderColor: '#00e676' } : {}),
    ...(isDragReject ? { borderColor: '#ff1744' } : {}),
  };

  return (
      <div>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {errors.length === 0 && <p>Drag & drop a XLSX file here, or click to select</p>}
          {errors.length > 0 && <div style={{ color: 'red' }}>{errors.map((error, i) => <p key={i}>{error}</p>)}</div>}
        </div>
        {showSuccessMessage && <p style={{ color: 'green', fontWeight: 'bold' }}>{successMessage}</p>}
        {parsedData && (
            <div style={{ marginTop: '16px' }}>
              {loading ? <p style={{ color: '#2196f3' }}>Uploading entries...</p> : (
                  <>
                    <p>You are about to create <strong>{parsedData.length}</strong> items.</p>
                    <Button title="Confirm" onClick={handleCreateEntries} disabled={loading} />
                    <Button title="Clear" onClick={handleClear} disabled={loading} />
                  </>
              )}
            </div>
        )}
      </div>
  );
};

export default XLSXDropzone;
