import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getEntries, isFetchingEntries } from '../../../selectors/Entry';
import { activateEntry, fetchEntries, storeEntry } from '../../../actions/Entry';
import { Entry } from '../../../types/Entry';
import DataTable from '../../../components/DataTable';
import { formatUrl, removeIds } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const EntryDataTable = () => {

  const entries = useSelector(getEntries);
  const isLoading = useSelector(isFetchingEntries);
  const [toggle, setToggle] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);
  const [entry, setActiveEntry] = useState<Entry | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.ENTRY_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.ENTRY_UPDATE, row.id));
  };

  const onArchive = (row: Entry) => {
    setActiveEntry(row);
    setToggle(true);
  };

  const onUnArchive = (row: Entry) => {
    setActiveEntry(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (entry?.id) {
      if (unArchiveToggle){
        await activateEntry(entry?.id);
      } else {
        await storeEntry(entry?.id);
      }
      setRefetch(!refetch);
    }
    setActiveEntry(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'title', label: 'Entry Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: false },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: 'Archive', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive }],
    hideButton: true,
  };

  return (
      <View title="Entries" onAddNew={onCreate}>
        { config &&
          <DataTable fetchFunction={fetchEntries}  paging={entries?.paging}
                      exclusionList={removeIds(entries?.data[0])}
                     isLoading={isLoading} data={entries?.data || []} config={config} dependencies={refetch}/>
        }
        <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Entry' : 'Archive Entry'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
          {unArchiveToggle ? 'Are you sure you want to unarchive this entry?' : 'Are you sure you want to archive this entry?'}
        </Modal>
    </View>
  );
};

export default EntryDataTable;

