import React, { useEffect, useState } from 'react';
import './Return.scss';
import Loading from '../../components/Loading/Loading';
import { fetchActiveTransaction } from '../../actions/Transaction';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTransaction } from '../../selectors/Transaction';
import View from '../../components/View/View';
import c, { InvoiceType, TERMS_CONDITIONS_FILE, TransactionStatus } from '../../helpers/constants';
import Button from '../../components/Button';
import { getActiveClient as selectedClient } from '../../helpers/functions';
import { fetchActiveClient } from '../../actions/Client';
import { getActiveClient } from '../../selectors/Client';
import { fetchLoggedInUser } from '../../actions/Auth';
import { useHistory } from 'react-router-dom';
import Receipt from './Receipt';


const PaymentStatusPage = () => {
  const activeTransaction = useSelector(getActiveTransaction);
  const history = useHistory();
  const dispatch = useDispatch();
  const client = selectedClient();
  const clientData = useSelector(getActiveClient);
  const [loading, setLoading] = useState(true);

  const transactionId = new URLSearchParams(window.location.search).get('transaction_id');
  const onUpgrade = () => history.push(c.APP_ROUTES.SUBSCRIPTION);


  useEffect(() => {
    const timer = setTimeout(() => {
      console.log('Timeout after 3 seconds');
      if (loading) {
        try {
          dispatch(fetchActiveTransaction(transactionId ?? ''));
          setLoading(false);
        } catch (error) {
          console.error('Error fetching payment status:', error);
          setLoading(false);
        }
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [loading]);

  useEffect(() => {
    dispatch(fetchLoggedInUser());
  }, [activeTransaction]);

  useEffect(() => {
    if (client.id && !clientData) {
      dispatch(fetchActiveClient(client.id));
    }
  }, [client]);
  

  const renderMessage = () => {
    if (!activeTransaction?.status) return null;

    if (activeTransaction.planTitle === 'Free') {
      return (
                <div className="row justify-content-center align-items-center">
                    <h1 className="col-12">You selected the free tier!</h1>
                    <p className="col-12">Enjoy exploring our free tier. Upgrade anytime to unlock more features.</p>
                </div>
      );
    }

    switch (activeTransaction.status) {
      case TransactionStatus.CREATED:
      case TransactionStatus.PENDING:
      case TransactionStatus.PLANNED:
      case TransactionStatus.RESERVED:
        return (
                    <div>
                        <p>Your payment is being processed. You will receive an email when your subscription is activated.</p>
            </div>
        );
      case TransactionStatus.FAILED:
      case TransactionStatus.EXPIRED:
      case TransactionStatus.CANCELLED:
        return (
            <div>
              <h1>Payment Failed</h1>
                <p>Something went wrong with your payment. Please try again. If the issue persists, contact an administrator.</p>
                <Button onClick={onUpgrade} title="Try again" />
            </div>
        );
      case TransactionStatus.COMPLETED:
        return (
            <div>
                <Receipt
                    clientData={clientData}
                    activeTransaction={activeTransaction}
                    itemId={activeTransaction?.planId ?? ''}
                    itemName={activeTransaction?.planTitle ?? ''}
                    itemDescription={activeTransaction?.planDescription ?? ''}
                    invoiceType={InvoiceType.SUBSCRIPTION}
                    itemDetails={activeTransaction?.planDetails ?? ''}
                    termsAndConditions={`${c.APP_ROUTES.CORE}/${TERMS_CONDITIONS_FILE}`}
                />
            </div>
        );
      default:
        return (
            <div>
              <h1>An error has occurred</h1>
              <p>Please contact support for more information.</p>
              <p>Error: {transactionId}</p>
            </div>
        );
    }
  };

  return (
      <View>
        {(loading || !activeTransaction?.status) ? (
            <div className="loading-container">
              <Loading text={'Please wait!'}/>
            </div>
        ) : (
            <div className="return-container">
              {renderMessage()}
            </div>
        )}
      </View>
  );
};

export default PaymentStatusPage;
